import store from '@/store/store';
import { appInstance } from '@/main.js';
export default {
  namespaced: true,
  state() {
    return {
      selOptions: [],
      favoritesActive: false,
      searchParams: [],
      searchLoading: false,
      response: [],
      responseLength: 0,
      responsePages: 0,
      responeCurrentPage: 1,
      imageProcessing: {
        imageQueue: [],
        loadedImages: [],
        isProcessing: false,
      },
      hoverState: {
        active: false,
        name: '',
      },
      clickState: {
        active: false,
        data: '',
        image: '',
      },
    };
  },
  mutations: {
    // Select Options
    SET_SELOPTIONS(state, options) {
      state.selOptions = options;
    },
    SET_RESPONSE(state, response) {
      state.response = response;
    },
    APPEND_RESPONSE(state, appendix) {
      state.response.push(...appendix);
    },
    SET_FAVORITESACTIVE(state, payload) {
      state.favoritesActive = payload;
    },
    SET_SEARCHPARAM(state, param) {
      state.searchParams.push(param);
    },
    SET_FILTEREDSEARCHPARAM(state, params) {
      state.searchParams = params;
    },
    SET_HOVERSTATE(state, payload) {
      state.hoverState.active = payload.active;
      state.hoverState.name = payload.name;
    },
    SET_CLICKSTATE(state, payload) {
      state.clickState.active = payload.active;
      state.clickState.data = payload.data;
      state.clickState.image = payload.image;
    },
    SET_SEARCHLOADING(state, payload) {
      state.searchLoading = payload;
    },
    SET_PROFILE_FAVORITE(state, payload) {
      state.clickState.data.profile_favorite = payload;
    },
    SET_PROFILE_IGNORED(state, payload) {
      state.clickState.data.profile_ignored = payload;
    },
    // Image Proccessing
    ADD_IMAGEQUEUE(state, payload) {
      state.imageProcessing.imageQueue.push(payload);
    },
    CLEAR_IMAGEQUEUE(state) {
      state.imageProcessing.imageQueue = [];
    },
    ADD_LOADEDIMAGE(state, payload) {
      state.imageProcessing.loadedImages.push(payload);
    },
    SET_IMAGEPROCESSING(state, payload) {
      state.imageProcessing.isProcessing = payload;
    },
    SET_SEARCHRESPONSELENGTH(state, total) {
      state.responseLength = total;
    },
    SET_SEARCHRESPONSEPAGES(state, total) {
      state.responsePages = total;
    },
    SET_SEARCHRESPONSECURRENTPAGE(state, current_page) {
      state.responeCurrentPage = current_page;
    },
  },
  getters: {
    GET_SELOPTIONS: (state) => {
      return state.selOptions;
    },
    SELOPTIONSLENGTH: (state) => {
      return state.selOptions.length;
    },
    SEARCHRESPONSE: (state) => {
      return state.response;
    },
    SEARCHRESPONSELENGTH: (state) => {
      return state.responseLength;
    },
    SEARCHRESPONSEPAGES: (state) => {
      return state.responsePages;
    },
    SEARCHRESPONECURRENTPAGE: (state) => {
      return state.responeCurrentPage;
    },
    GET_SEARCHPARAMS: (state) => {
      return state.searchParams;
    },
    GET_FAVORITESACTIVE: (state) => {
      return state.favoritesActive;
    },
    GET_HOVERSTATE_ACTIVE: (state) => {
      return state.hoverState.active;
    },
    GET_HOVERSTATE: (state) => {
      return state.hoverState;
    },
    GET_PROFILE_FAVORITE: (state) => {
      if (state.clickState.data !== '') {
        return state.clickState.data.profile_favorite;
      }
      return false;
    },
    GET_PROFILE_IGNORED: (state) => {
      if (state.clickState.data !== '') {
        return state.clickState.data.profile_ignored;
      }
      return false;
    },
    GET_CLICKSTATE_ACTIVE: (state) => {
      return state.clickState.active;
    },
    GET_CLICKSTATE: (state) => {
      return state.clickState;
    },
    GET_SEARCHLOADING: (state) => {
      return state.searchLoading;
    },

    // Image Proccessing
    GET_IMAGEPROCESSING: (state) => {
      return state.imageProcessing.isProcessing;
    },
    GET_IMAGEQUEUE: (state) => {
      return state.imageProcessing.imageQueue;
    },
    GET_LOADEDQUEUE: (state) => {
      return state.imageProcessing.loadedImages;
    },
    SHIFT_IMAGEQUEUE: (state) => {
      return state.imageProcessing.imageQueue.shift();
    },
  },
  actions: {
    async fetchOptions(context, reference) {
      var data = { ref: reference };
      if (reference == 'X3') data['params'] = context.getters.GET_SEARCHPARAMS;

      let response = await this.$API.post('/search/options', data);
      if (response.status === 200) {
        if (reference == 'X3') {
          context.commit('SET_FAVORITESACTIVE', true);
          var respdata = response.data;

          // Set Selection List
          context.commit('SET_SELOPTIONS', respdata['favorites_sel']);

          // Set Search Filter to show only Favorites
          context.commit('SET_RESPONSE', respdata['favorites_profiles']);
          const respLength = respdata['favorites_profiles'].length;
          context.commit('SET_SEARCHRESPONSELENGTH', respLength);
          context.commit('CLEAR_IMAGEQUEUE');
          appInstance.config.globalProperties.emitter.emit('init_search_action');
          if (respLength === 0) {
            context.dispatch(
              'modal/showSubmitModal',
              {
                status: 'info',
                text: 'Mit dieser Filterung gibt es keine Treffer! Probiere deine Filter auszutauschen.',
                time: 8000,
              },
              { root: true }
            );
          }

          return;
        }
        context.commit('SET_SELOPTIONS', response.data);
        if (context.getters.GET_FAVORITESACTIVE) {
          context.commit('SET_FAVORITESACTIVE', false);
          context.dispatch('search_action');
        }
      }
    },

    removeobj(context) {
      context.state.response.pop();
    },

    addSearchParam(context, param) {
      // Open profileclick for x3
      if (param.ref === 'X3') {
        // profileClickActive
        context.dispatch('profileClickActive', param.profile_id);
        return;
      }

      // Check if param is present
      var searchParams = context.getters.GET_SEARCHPARAMS;

      const ispresent = searchParams.some((element) => {
        return element.id == param.id && element.ref == param.ref;
      });

      if (ispresent === true) {
        return;
      }

      context.commit('SET_SEARCHPARAM', param);
      context.dispatch('search_action');
    },

    removeSearchParam(context, param) {
      var searchParams = context.getters.GET_SEARCHPARAMS;

      const result = searchParams.filter((element) => {
        // Attributes only have name, no id
        if (element.ref === 'X3') {
          return element.name !== param.name && element.ref !== param.ref;
        }
        return element.id !== param.id || element.ref !== param.ref;
      });

      context.commit('SET_FILTEREDSEARCHPARAM', result);

      // Check if Favorites are active
      if (context.getters.GET_FAVORITESACTIVE) {
        context.dispatch('fetchOptions', 'X3');
        return;
      }
      context.dispatch('search_action');
    },

    async search_action(context) {
      // Close Hover first
      context.commit('SET_SEARCHLOADING', true);
      context.commit('SET_HOVERSTATE', {
        active: false,
        name: '',
      });

      var per_page = 400;

      let response = await this.$API.post('/search/action', {
        params: context.getters.GET_SEARCHPARAMS,
        page: 1,
        per_page: per_page,
        search_column: 'profile_id',
        search_value: '',
      });
      if (response.status === 200) {
        context.commit('SET_RESPONSE', response.data.results);

        context.commit('SET_SEARCHLOADING', false);

        var total = response.data.total;

        context.commit('SET_SEARCHRESPONSELENGTH', total);
        context.commit('SET_SEARCHRESPONSEPAGES', response.data.pages);
        context.commit('CLEAR_IMAGEQUEUE');
        appInstance.config.globalProperties.emitter.emit('init_search_action');

        if (response.data.total === 0) {
          context.commit('SET_HOVERSTATE', {
            active: true,
            name: 'Keine Treffer!',
          });

          context.dispatch(
            'modal/showSubmitModal',
            {
              status: 'info',
              text: 'Mit dieser Filterung gibt es keine Treffer! Probiere deine Filter auszutauschen.',
              time: 8000,
            },
            { root: true }
          );
        }

        // response.data.forEach((profile) => {
        //   context.dispatch(
        //     'addToQueue',
        //     process.env.VUE_APP_BASEURL +
        //       '/image/search/' +
        //       profile['imagesrc_small'] +
        //       '?mode=tiny' +
        //       '&' +
        //       'auth=true'
        //   );
        // });
      }
    },

    async search_action_paginate(context, page) {
      // var current_page = context.getters.SEARCHRESPONECURRENTPAGE
      if (context.getters.GET_SEARCHLOADING) return;
      var total_pages = context.getters.SEARCHRESPONSEPAGES;
      if (page > total_pages) return 'END';

      context.commit('SET_SEARCHLOADING', true);
      context.commit('SET_HOVERSTATE', {
        active: false,
        name: '',
      });

      let response = await this.$API.post('/search/action', {
        params: context.getters.GET_SEARCHPARAMS,
        page: page,
        per_page: 400,
        search_column: 'profile_id',
        search_value: '',
      });

      if (response.status === 200) {
        context.commit('SET_SEARCHRESPONSECURRENTPAGE', page);
        context.commit('SET_SEARCHLOADING', false);
        return response.data.results;
      }
      context.commit('SET_SEARCHLOADING', false);
      return 'ERROR';
    },
    profileHoverData(context, payload) {
      var hoverState = {
        active: false,
        name: '',
      };

      // Security
      if (!store.getters['auth/PaymentStatus']) {
        context.commit('SET_HOVERSTATE', hoverState);
        return;
      }

      var ModalsClosed = store.getters['modal/ModalStatus'];
      if (ModalsClosed === false) {
        context.commit('SET_HOVERSTATE', hoverState);
        return;
      }

      hoverState.active = payload.active;
      hoverState.name = payload.name;
      context.commit('SET_HOVERSTATE', hoverState);

      // Load fancy data from the backend
    },

    async profileClickActive(context, profile_id) {
      /*
        Input Arguments:
        profile_id -- Integer

        Requirements:
        Need a valid payment to fetch data

        Logic:
        Fetch Data with the given profile_id,
        mutate clickstate data object with response

        Side Effects:
        Opens ProfileClickModal
      */

      //Validate click first
      //Close all other Popups on Mobile
      if (window.innerWidth < 800 && store.getters['modal/MobileStatus']) {
        context.dispatch('modal/hideAllModals', null, { root: true });
        // this.hideAllModals();
        // return;
      }

      context.dispatch(
        'search/profileHoverData',
        {
          active: false,
          name: '',
        },
        { root: true }
      );

      //   this.profileHoverData({
      //     active: false,
      //     name: '',
      //   });

      //Show Login Form if not logged-in
      if (!store.getters['auth/LoginStatus']) {
        context.dispatch('modal/showLoginModal', null, { root: true });
        // this.showLoginModal();
        return;
      }

      var clickstate = {
        active: false,
        data: '',
      };

      //   if (!store.getters['auth/PaymentStatus']) {
      //     context.commit('SET_CLICKSTATE', clickstate);
      //     return;
      //   }

      let response = await this.$API.get('/profile/' + String(profile_id));
      if (response.status === 200) {
        if (response.data === 'PROFILE NOT PUBLIC') {
          context.dispatch(
            'modal/showSubmitModal',
            {
              status: 'info',
              text: 'Das Profil ist nicht öffentlich!',
              time: 4000,
            },
            { root: true }
          );
          return;
        }

        clickstate.data = response.data;
        clickstate.active = true;
        document.querySelector('body').classList.add('overflow-hidden');
      }
      context.commit('SET_CLICKSTATE', clickstate);
    },

    profileClickHide(context) {
      var clickstate = {
        active: false,
        data: '',
        image: '',
      };
      context.commit('SET_CLICKSTATE', clickstate);
      document.querySelector('body').classList.remove('overflow-hidden');
    },
    async toggleProfileFavorite(context, profile_id) {
      try {
        let response = await this.$API.post('/favorite/toggle', {
          profile_id: profile_id,
        });
        if (response.status === 200) {
          context.commit('SET_PROFILE_FAVORITE', response.data);
        }
      } catch (error) {
        return Promise.reject(error);
      }
    },

    // WIP
    async toggleProfileIgnore(context, profile_id) {
      try {
        const ignored = context.getters.GET_PROFILE_IGNORED;
        if (!ignored) {
          await context.dispatch(
            'modal/initSubmitConfirmModal',
            {
              active: true,
              text: 'Wenn du den Benutzer ignorierst, kannst du keine Nachrichten von ihm empfangen. Möchtest du fortfahren?',
            },
            { root: true }
          );
          context.dispatch('modal/showSubmitConfirmModal', false, { root: true });
        }
        let profileIgnored = await context.dispatch('chat/ignoreProfile', profile_id, {
          root: true,
        });
        context.commit('SET_PROFILE_IGNORED', profileIgnored);
      } catch (error) {
        context.dispatch('modal/showSubmitConfirmModal', false, { root: true });
      }
    },

    // Image Processing

    addToQueue(context, url) {
      context.commit('ADD_IMAGEQUEUE', url);
      if (!context.getters.GET_IMAGEPROCESSING) {
        context.dispatch('processQueue');
      }
    },

    processQueue(context) {
      var imageQueue = context.getters.GET_IMAGEQUEUE;
      var queueLength = imageQueue.length;

      if (queueLength === 0) {
        context.commit('SET_IMAGEPROCESSING', false);
        return;
      }

      context.commit('SET_IMAGEPROCESSING', true);
      var url = context.getters.SHIFT_IMAGEQUEUE;
      const img = new Image();

      img.onload = () => {
        context.commit('ADD_LOADEDIMAGE', url);
        context.dispatch('processQueue');
      };

      img.onerror = () => {
        context.commit('ADD_LOADEDIMAGE', url);
        context.dispatch('processQueue');
      };

      img.src = url;
    },

    // Fetch Search-Options
  },
};
