<template>
  <div class="btn-navigation">
    <div class="btn-container">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {};
  },
};
</script>

<style scoped>
.btn-navigation {
  display: flex;
  justify-content: center;
  width: 100%;
}
.btn-container {
  padding: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}

@media only screen and (max-width: 1000px) {
  .btn-container {
    flex-direction: column;
    gap: 1.5rem;
  }
}
</style>
