<template>
  <!-- Main modal -->
  <div
    id="UserModal"
    tabindex="-1"
    aria-hidden="true"
    class="dark hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-modal md:h-full"
  >
    <div class="relative p-4 w-full max-w-2xl h-full md:h-auto">
      <!-- Modal content -->
      <div class="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
        <!-- Modal header -->
        <div
          class="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600"
        >
          <h3 class="text-lg font-semibold text-gray-900 dark:text-white">Benutzer</h3>
          <button
            id="closeUserModal"
            type="button"
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
            data-modal-toggle="updateUserModal"
          >
            <svg
              aria-hidden="true"
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <span class="sr-only">Close modal</span>
          </button>
        </div>
        <!-- Modal body -->
        <form action="#">
          <div class="p-5 grid gap-4 mb-4 sm:grid-cols-2">
            <!-- Image -->
            <div class="sm:col-span-2 flex justify-center mb-6">
              <VLazyImage
                v-if="USERMODAL.data.imagesrc_click"
                :src="
                  'https://devel.kremer-marketing.de' +
                  '/image/search/' +
                  USERMODAL.data.imagesrc_click +
                  '?resolution=high'
                "
                class="h-auto max-w-md rounded-lg bg-gray-700"
              />
              <VLazyImage
                v-if="!USERMODAL.data.imagesrc_click"
                :src="'https://uxwing.com/wp-content/themes/uxwing/download/peoples-avatars/no-profile-picture-icon.png'"
                class="h-auto max-w-sm rounded-lg bg-gray-700"
              />

              <!-- <img
                class=""
                :src="
                  'https://devel.kremer-marketing.de' +
                  '/image/search/' +
                  USERMODAL.data.imagesrc_click +
                  '?resolution=high'
                "
                alt="image description"
              /> -->
            </div>

            <!-- Buttons -->
            <div class="sm:col-span-2 flex gap-2 justify-center mb-6">
              <button
                type="button"
                class="text-red-600 inline-flex items-center hover:text-white border border-red-600 hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900"
              >
                <svg
                  class="mr-1 -ml-1 w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                sperren (24h)
              </button>
              <button
                v-if="USERMODAL.data.imagesrc_click"
                @click="deleteImage"
                type="button"
                class="text-red-600 inline-flex items-center hover:text-white border border-red-600 hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900"
              >
                <svg
                  class="mr-1 -ml-1 w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                Foto löschen
              </button>
            </div>

            <!-- Preamble -->

            <div>
              <label
                for="name"
                class="block mb-2 text-base font-semibold text-gray-900 dark:text-white"
                >Benutzername</label
              >
              <div class="text-base font-medium text-white">
                {{ USERMODAL.data.profile_nickname }}
              </div>
            </div>
            <div>
              <label
                for="name"
                class="block mb-2 text-base font-semibold text-gray-900 dark:text-white"
                >E-Mail</label
              >
              <div class="text-base font-medium text-white">{{ USERMODAL.data.appuser_email }}</div>
            </div>
            <div>
              <label
                for="name"
                class="block mb-2 text-base font-semibold text-gray-900 dark:text-white"
                >Mitglied seit:</label
              >
              <div class="text-base font-medium text-white">
                {{ USERMODAL.data.fmt_appuser_createtime }}
              </div>
            </div>
            <div>
              <label
                for="name"
                class="block mb-2 text-base font-semibold text-gray-900 dark:text-white"
                >Profil öffentlich</label
              >
              <div class="text-base font-medium text-white">
                <svg
                  v-if="USERMODAL.data.profile_public"
                  class="w-6 h-6 text-green-700"
                  aria-hidden="true"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="4.0"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.5 12.75l6 6 9-13.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
                <svg
                  v-if="!USERMODAL.data.profile_public"
                  class="w-6 h-6 text-red-600"
                  aria-hidden="true"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="4.0"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 18L18 6M6 6l12 12"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
            </div>
          </div>

          <!-- Section 1 -->
          <div
            id="accordion-color"
            data-accordion="collapse"
            data-active-classes="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white"
          >
            <h2 id="accordion-color-heading-1">
              <button
                type="button"
                class="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-b-0 border-gray-200 rounded-t-xl focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800 dark:border-gray-700 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
                data-accordion-target="#accordion-color-body-1"
                aria-expanded="false"
                aria-controls="accordion-color-body-1"
              >
                <span>Basic</span>
                <svg
                  data-accordion-icon
                  class="w-6 h-6 rotate-180 shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </button>
            </h2>
            <div
              id="accordion-color-body-1"
              class="hidden"
              aria-labelledby="accordion-color-heading-1"
            >
              <div class="p-5 grid gap-4 mb-4 sm:grid-cols-2">
                <div>
                  <label
                    for="name"
                    class="block mb-2 text-base font-semibold text-gray-900 dark:text-white"
                    >Vorname</label
                  >
                  <div class="text-base font-medium text-white">
                    {{ USERMODAL.data.profile_firstname }}
                  </div>
                </div>
                <div>
                  <label
                    for="name"
                    class="block mb-2 text-base font-semibold text-gray-900 dark:text-white"
                    >Nachname</label
                  >
                  <div class="text-base font-medium text-white">
                    {{ USERMODAL.data.profile_lastname }}
                  </div>
                </div>
                <div>
                  <label
                    for="name"
                    class="block mb-2 text-base font-semibold text-gray-900 dark:text-white"
                    >Bundesland</label
                  >
                  <div class="text-base font-medium text-white">
                    {{ USERMODAL.data.profile_provinz_name }}
                  </div>
                </div>
                <div>
                  <label
                    for="name"
                    class="block mb-2 text-base font-semibold text-gray-900 dark:text-white"
                    >Stadt</label
                  >
                  <div class="text-base font-medium text-white">
                    {{ USERMODAL.data.profile_city }}
                  </div>
                </div>
                <div class="sm:col-span-2">
                  <label
                    for="description"
                    class="block mb-2 text-base font-semibold text-gray-900 dark:text-white"
                    >Description</label
                  >
                  <div class="text-base font-medium text-white">
                    {{ USERMODAL.data.profile_aboutme }}
                  </div>
                </div>
              </div>
            </div>
            <h2 id="accordion-color-heading-2">
              <button
                type="button"
                class="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-b-0 border-gray-200 focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800 dark:border-gray-700 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
                data-accordion-target="#accordion-color-body-2"
                aria-expanded="false"
                aria-controls="accordion-color-body-2"
              >
                <span>Social-Media</span>
                <svg
                  data-accordion-icon
                  class="w-6 h-6 shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </button>
            </h2>

            <!-- Section 2 -->

            <div
              id="accordion-color-body-2"
              class="hidden"
              aria-labelledby="accordion-color-heading-2"
            >
              <div class="p-5 grid gap-4 mb-4 sm:grid-cols-2">
                <div>
                  <label
                    for="name"
                    class="block mb-2 text-base font-semibold text-gray-900 dark:text-white"
                    >Instagram</label
                  >
                  <div class="text-base font-medium text-white">
                    {{ USERMODAL.data.profile_instagram }}
                  </div>
                </div>
                <div>
                  <label
                    for="name"
                    class="block mb-2 text-base font-semibold text-gray-900 dark:text-white"
                    >Facebook</label
                  >
                  <div class="text-base font-medium text-white">
                    {{ USERMODAL.data.profile_facebook }}
                  </div>
                </div>
                <div>
                  <label
                    for="name"
                    class="block mb-2 text-base font-semibold text-gray-900 dark:text-white"
                    >Twitch</label
                  >
                  <div class="text-base font-medium text-white">
                    {{ USERMODAL.data.profile_twitch }}
                  </div>
                </div>
                <div>
                  <label
                    for="name"
                    class="block mb-2 text-base font-semibold text-gray-900 dark:text-white"
                    >Twitter</label
                  >
                  <div class="text-base font-medium text-white">
                    {{ USERMODAL.data.profile_twitter }}
                  </div>
                </div>
                <div>
                  <label
                    for="name"
                    class="block mb-2 text-base font-semibold text-gray-900 dark:text-white"
                    >Youtube</label
                  >
                  <div class="text-base font-medium text-white">
                    {{ USERMODAL.data.profile_youtube }}
                  </div>
                </div>
                <div>
                  <label
                    for="name"
                    class="block mb-2 text-base font-semibold text-gray-900 dark:text-white"
                    >Tik Tok</label
                  >
                  <div class="text-base font-medium text-white">
                    {{ USERMODAL.data.profile_tiktok }}
                  </div>
                </div>
                <div>
                  <label
                    for="name"
                    class="block mb-2 text-base font-semibold text-gray-900 dark:text-white"
                    >LinkedIn</label
                  >
                  <div class="text-base font-medium text-white">
                    {{ USERMODAL.data.profile_linkedin }}
                  </div>
                </div>
                <div>
                  <label
                    for="name"
                    class="block mb-2 text-base font-semibold text-gray-900 dark:text-white"
                    >Xing</label
                  >
                  <div class="text-base font-medium text-white">
                    {{ USERMODAL.data.profile_xing }}
                  </div>
                </div>
                <div>
                  <label
                    for="name"
                    class="block mb-2 text-base font-semibold text-gray-900 dark:text-white"
                    >Pinterest</label
                  >
                  <div class="text-base font-medium text-white">
                    {{ USERMODAL.data.profile_pinterest }}
                  </div>
                </div>
              </div>
            </div>

            <!-- Section 3 -->

            <h2 id="accordion-color-heading-3">
              <button
                type="button"
                class="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-gray-200 focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800 dark:border-gray-700 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
                data-accordion-target="#accordion-color-body-3"
                aria-expanded="false"
                aria-controls="accordion-color-body-3"
              >
                <span>Dienstleistungen</span>
                <svg
                  data-accordion-icon
                  class="w-6 h-6 shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </button>
            </h2>
            <div
              id="accordion-color-body-3"
              class="hidden"
              aria-labelledby="accordion-color-heading-3"
            >
              <div class="p-5 grid gap-4 mb-4 sm:grid-cols-2">
                <!-- List -->

                <ul
                  v-for="category in USERMODAL.data.profile_categories"
                  :key="category.category_id"
                  class="w-48 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                >
                  <li
                    class="w-full px-4 py-2 border-b-4 border-white font-bold rounded-t-lg dark:border-gray-600"
                  >
                    {{ category.category_name }}
                  </li>

                  <li
                    v-for="sibling in category.child_categories"
                    :key="sibling.category_id"
                    class="w-full px-4 py-2 border-b border-gray-200 dark:border-gray-600"
                  >
                    {{ sibling.category_name }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import VLazyImage from "v-lazy-image";
import { mapActions } from "vuex";
import { initAccordions } from "flowbite";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      basic: false,
      SocialMedia: false,
      services: false,
    };
  },
  components: {
    VLazyImage,
  },
  computed: {
    ...mapGetters("dashboard", ["USERMODAL"]),
  },
  methods: {
    ...mapActions("dashboard", ["openUserModal"]),
    async deleteImage() {
      var response = await this.$API.post(
        "/admin/user/image/delete/" + String(this.USERMODAL.data.appuser_id)
      );
      if (response.status === 200) {
        this.openUserModal(this.USERMODAL.data.appuser_id);
      }
    },
  },
  mounted() {
    initAccordions();
  },
};
</script>

<style></style>
