<template>
  <!-- Main modal -->
  <div
    id="ReportModal"
    tabindex="-1"
    aria-hidden="true"
    class="dark fixed left-0 right-0 top-0 z-50 hidden h-modal w-full items-center justify-center overflow-y-auto overflow-x-hidden md:inset-0 md:h-full"
  >
    <div class="relative h-full w-full max-w-2xl p-4 md:h-auto">
      <!-- Modal content -->
      <div class="relative rounded-lg bg-white p-4 shadow dark:bg-gray-800 sm:p-5">
        <!-- Modal header -->
        <div
          class="mb-4 flex items-center justify-between rounded-t border-b pb-4 dark:border-gray-600 sm:mb-5"
        >
          <h3 class="text-lg font-semibold text-gray-900 dark:text-white">Meldung</h3>
          <button
            id="closeReportModal"
            type="button"
            class="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
            data-modal-toggle="updateReportModal"
          >
            <svg
              aria-hidden="true"
              class="h-5 w-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <span class="sr-only">Close modal</span>
          </button>
        </div>
        <!-- Modal body -->
        <form action="#">
          <div class="mb-4 grid gap-4 p-5 sm:grid-cols-2">
            <!-- Buttons -->
            <div class="mb-6 flex justify-center gap-2 sm:col-span-2">
              <button
                type="button"
                class="focus:outline-none inline-flex items-center rounded-lg border border-red-600 px-5 py-2.5 text-center text-sm font-medium text-red-600 hover:bg-red-600 hover:text-white focus:ring-4 focus:ring-red-300 dark:border-red-500 dark:text-red-500 dark:hover:bg-red-600 dark:hover:text-white dark:focus:ring-red-900"
              >
                <svg
                  class="-ml-1 mr-1 h-5 w-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                sperren (24h)
              </button>
            </div>

            <!-- Preamble -->

            <div class="sm:col-span-2">
              <label
                for="name"
                class="mb-2 block text-base font-semibold text-gray-900 dark:text-white"
              >
                Gemeldet von:
              </label>
              <div
                class="flex items-center whitespace-nowrap px-6 py-4 text-gray-900 dark:text-white"
              >
                <VLazyImage
                  v-if="REPORTMODAL.data.from_imagesrc_small"
                  class="h-10 w-10 rounded-full"
                  :src="
                    'https://devel.kremer-marketing.de' +
                    '/image/search/' +
                    REPORTMODAL.data.from_imagesrc_small +
                    '?mode=tiny'
                  "
                  alt=""
                />
                <VLazyImage
                  v-else
                  class="h-10 w-10 rounded-full"
                  :src="'https://uxwing.com/wp-content/themes/uxwing/download/peoples-avatars/no-profile-picture-icon.png'"
                  alt=""
                />
                <div class="pl-3">
                  <div class="text-base font-semibold">
                    {{ REPORTMODAL.data.from_profile_nickname }}
                  </div>
                  <div class="font-normal text-gray-500">
                    {{ REPORTMODAL.data.from_appuser_email }}
                  </div>
                </div>

                <button
                  @click="openUserModal(REPORTMODAL.data.from_appuser_id)"
                  id="editUserModal"
                  type="button"
                  class="focus:outline-none ml-4 inline-flex items-center rounded-lg bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  <svg
                    aria-hidden="true"
                    class="h-5 w-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';
import VLazyImage from 'v-lazy-image';
export default {
  data() {
    return {
      basic: false,
      SocialMedia: false,
      services: false,
    };
  },
  components: {
    VLazyImage,
  },
  computed: {
    ...mapGetters('dashboard', ['REPORTMODAL']),
  },
  methods: {
    ...mapActions('dashboard', ['openReportModal', 'openUserModal']),
  },
  mounted() {},
};
</script>

<style></style>
