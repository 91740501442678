<template>
  <a :href="this.href" target="_blank">
    <em :class="[this.icon, { color: this.color }]"></em>
  </a>
</template>
<script>
export default {
  props: ["href", "icon", "color"],
  computed: {},
};
</script>
<style scoped>
.fab,
.fas,
.far {
  font-size: clamp(23px, 1.9vw, 40px);
  padding: 0 1rem 0 0;
  color: white;
  cursor: pointer;
}
.color {
  color: v-bind(color);
}
.topicon {
  font-size: clamp(20px, 1.6vw, 40px);
  padding: 0 1rem 0 0;
}
.fab::before,
.fas::before {
  cursor: pointer;
}
.fab:hover,
.fas:hover,
.far:hover {
  transform: scale(1.1);
  color: #ed1c24;
}
</style>
