<template>
  <Transition>
    <div v-if="ChatInboxStatus" class="chatinbox__participants">
      <p class="head">Chat</p>
      <div class="redline"></div>
      <div class="bg">
        <!-- Inboxes -->
        <div class="button-wrapper">
          <div class="status-offline">
            <span v-if="SocketStatus" class="material-icons-outlined">wifi</span>
            <span v-if="!SocketStatus" class="material-icons-outlined">wifi_off</span>
          </div>
          <span v-if="!showIgnored" class="info-text">Postfach</span>
          <span v-if="showIgnored" class="info-text">Ignoriert</span>
          <div class="ignored">
            <span
              v-if="!showIgnored"
              @click="toggleIgnored"
              class="material-icons-outlined"
            >
              person_off
            </span>
            <span
              v-if="showIgnored"
              @click="toggleIgnored"
              class="ui-icon material-icons-outlined"
            >
              arrow_back
            </span>
          </div>
        </div>
        <div class="greyline"></div>
        <div class="searchbar">
          <input
            class="input"
            v-model="search"
            type="text"
            id="fname"
            name="fname"
            placeholder="Nach Personen suchen"
          />
        </div>
        <div v-if="!showIgnored" class="inbox-wrapper">
          <Inbox
            v-for="inbox in filteredInboxes"
            :key="inbox.inbox_id"
            @showMessages="openChat($event)"
            @showProfile="openProfile($event)"
            :nickname="inbox.profile_nickname"
            :lastMsg="inbox.message_msg"
            :image="inbox.imagesrc_small"
            :unseen="inbox.inboxparts_unseen"
            :profile_id="inbox.profile_id"
            :inbox="inbox"
          />
        </div>
        <div v-if="showIgnored" class="inbox-wrapper">
          <IgnoredInbox
            v-for="inbox in filteredIgnoredInboxes"
            :key="inbox.inbox_id"
            @showProfile="openProfile($event)"
            :nickname="inbox.profile_nickname"
            :lastMsg="inbox.message_msg"
            :image="inbox.imagesrc_small"
            :unseen="inbox.inboxparts_unseen"
            :profile_id="inbox.profile_id"
            :inbox="inbox"
          />
          <h1 v-if="filteredIgnoredInboxes.length === 0">Du ignorierst keine Profile!</h1>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { toRaw } from 'vue';
import Inbox from '@/components/Chat/Inbox.vue';
import IgnoredInbox from '@/components/Chat/IgnoredInbox.vue';
export default {
  components: {
    Inbox,
    IgnoredInbox,
  },

  data() {
    return {
      search: '',
      showIgnored: false,
    };
  },
  computed: {
    ...mapGetters('modal', ['ChatInboxStatus']),
    ...mapGetters('auth', ['SocketStatus']),
    ...mapGetters('modal', ['MobileStatus']),
    ...mapGetters({
      inboxes: 'chat/GETINBOXES',
      ignoredInboxes: 'chat/GET_IGNOREDINBOXES',
    }),
    filteredInboxes() {
      return this.inboxes.filter((inbox) =>
        inbox.profile_nickname.toLowerCase().includes(this.search.toLowerCase())
      );
    },
    filteredIgnoredInboxes() {
      return this.ignoredInboxes.filter((inbox) =>
        inbox.profile_nickname.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
  methods: {
    ...mapActions('chat', [
      'sendMsg',
      'startChat',
      'fetchInboxes',
      'fetchIgnoredInboxes',
    ]),
    ...mapActions('search', ['profileHoverData', 'profileClickActive']),
    ...mapActions('modal', [
      'showLoginModal',
      'explicitOptionsModal',
      'hideAllModals',
      'hideChatMessages',
    ]),
    toggleIgnored() {
      if (this.showIgnored) {
        this.showIgnored = false;
      } else {
        this.hideChatMessages();
        this.fetchIgnoredInboxes();
        this.showIgnored = true;
      }
    },
    openChat(data) {
      this.startChat(toRaw(data));
    },
    openProfile(data) {
      this.profileClickActive(data);
    },
  },
  mounted() {},
  watch: {
    ChatInboxStatus(newValue) {
      if (newValue === true) {
        this.fetchInboxes();
      }
    },
  },
};
</script>

<style scoped>
/* dialog transition */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.chatinbox__participants {
  display: flex;
  position: fixed;
  bottom: clamp(10rem, 10vw, 15rem);
  right: 0;
  align-items: center;
  flex-direction: column;
  color: var(--font-color);
  width: 23rem;
  height: 40rem;
  background-color: var(--nav-bg);
  overflow: hidden;
  z-index: 99;
}
.bg {
  display: flex;
  align-items: center;
  border-radius: 1rem;
  flex-direction: column;
  color: var(--font-color);
  width: 95%;
  height: 90%;
  background-color: var(--background-index-color);
  padding: 0.3rem 0 0 0;
  margin-top: 0.3rem;
}
.inbox-wrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 90%;
  height: 100%;
}
.head {
  padding: 0.5rem 0;
  font-size: 20px;
}
.redline {
  border-bottom: red 1px solid;
  width: 95%;
}
.greyline {
  border-bottom: #707070 1px solid;
  width: 100%;
}
.searchbar {
  display: flex;
  justify-content: center;
  width: 90%;
  height: 2rem;
  background-color: var(--settings-input);
  border-radius: 10px;
  margin: 1rem;
  padding: 0.4rem 0 0.4rem 0;
}
.input {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  width: 95%;
  background-color: transparent;
  border: none;
  color: var(--font-color);
}
.input::placeholder,
.inputmessage::placeholder {
  color: var(--font-color);
}
input:focus,
.inputmessage:focus {
  outline: none;
}

.button-wrapper {
  font-size: 28px;
  display: flex;
  width: 90%;
  height: 2rem;
  margin: 0.5rem;
  padding: 0.4rem 0 0.4rem 0;
}

/* .status-offline {
  margin: 0 0 0 1vw;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 25%;
  min-height: 100%;
} */

.info-text {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  font-size: 20px;
  min-height: 100%;
}

.ignored,
.status-offline {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
  min-height: 100%;
}

.ignored span {
  font-size: 30px;
}
.ignored > .ui-icon {
  cursor: pointer;
}

.ignored > .ui-icon:hover {
  color: #ed1c24;
}

.ignored:hover {
  background-color: #ed1c24;
}
@media only screen and (max-width: 1330px) {
  .chatinbox__participants {
    /* bottom: 9rem; */
    left: 50%;
    top: 46%;
    width: 100%;
    transform: translate(-50%, -50%);
  }
}
@media only screen and (max-width: 500px) {
  .chatinbox__participants {
    width: 100%;
    height: 35rem;
  }

  .bg {
    width: 95%;
    height: 80%;
    padding: 0.3rem 0 0 0;
  }
  .test {
    width: 90%;
  }
  .head {
    padding: 0.5rem 0;
    font-size: 20px;
  }
  .searchbar {
    width: 90%;
    height: 3rem;
    /* margin: 0 0 0.5rem 0; */
    margin: 1rem;
  }
  .input {
    font-size: 15px;
    width: 95%;
  }
}
</style>
