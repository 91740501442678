<template>
  <form @submit.prevent="CategorieSubmit">
    <CategoryGroup
      v-for="category in form"
      :key="category.category_id"
      :categoryObj="category"
      v-model="category.child_categories"
    ></CategoryGroup>
  </form>
</template>

<script>
import CategoryGroup from '@/components/Form/CategoryGroup.vue';
import { mapActions } from 'vuex';
export default {
  components: {
    CategoryGroup,
  },
  props: ['CategorieForm', 'active'],
  data() {
    return {
      form: {},
    };
  },
  methods: {
    ...mapActions('modal', ['showSubmitModal']),
    ...mapActions('search', ['search_action']),
    ...mapActions('profile', ['toggleProfilePublic', 'fetchProfilePublic']),
    async CategorieSubmit() {
      var CategoryObjs = [];

      // Only need category objs with picked sibling
      for (const key in this.form) {
        if (this.form[key]['child_categories'].length > 0) {
          CategoryObjs.push(this.form[key]);
        }
      }
      try {
        await this.$API
          .post('/profile/categories', {
            form: CategoryObjs,
          })
          .then(() => {
            this.fetchProfilePublic();
          });

        this.showSubmitModal({
          status: 'success',
          time: 3000,
          text: '',
        });

        // Handle responses
      } catch (error) {
        this.showSubmitModal({
          status: 'error',
          time: 5000,
          text: 'Der Dienst ist aktuell nicht verfügbar. Bitte versuche es später nochmal',
        });
      }
    },
  },
  mounted() {
    this.form = Object.assign({}, this.CategorieForm);
    this.emitter.on('saveForm', (currentTab) => {
      if (currentTab === 'Categories') {
        this.CategorieSubmit();
      }
    });
  },
  beforeUnmount() {
    this.emitter.off('saveForm');
  },
  watch: {},
};
</script>

<style scoped>
.left,
.right {
  display: flex;
  align-items: center;
  height: auto;
}
.left {
  width: 20%;
}
.right {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 70%;
  height: 100%;
}

@media only screen and (max-width: 1000px) {
  .left {
    width: 80%;
  }
  .right {
    width: 80%;
  }
  .select {
    width: 100%;
  }
  .info {
    margin: 0;
  }
}
</style>
