<template>
  <div class="container">
    <!-- btn -->
    <div class="btn-container">
      <i @click="close" class="fas fa-chevron-left"></i>
      <h1 class="progress">01/04</h1>
      <i @click="next" class="fas fa-chevron-right"></i>
    </div>

    <!-- info -->
    <div class="info-container">
      <i @click="info" class="fas fa-info-circle"></i>
    </div>
    <!-- crop zone -->
    <Transition>
      <div class="img-container">
        <img class="crop-image" ref="image" :src="ImagecropSrc" alt="No Image detected" />
      </div>
    </Transition>

    <!-- preview -->
    <div class="preview-container">
      <h1 class="preview-text">Vorschau:</h1>
      <div class="preview-content-wrapper">
        <div class="preview-static preview"></div>
        <div class="preview-static"></div>
        <div class="preview-static"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Cropper from 'cropperjs';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { destroyCropper } from '@/utils/imageCropper';
import dimension from '@/data/profileSize.js';
import 'cropperjs/dist/cropper.css';
export default {
  data() {
    return {
      cropper: '',
      destination: '',
      servererror: '',
      image: {},
      sizeobj: dimension,
      totalWidth: 0,
    };
  },
  mounted() {
    this.totalWidth = window.outerWidth;
    if (!this.ImagecropSrc) {
      this.$router.push({
        name: 'Home',
        params: {
          notification: JSON.stringify({
            status: 'error',
            time: 3000,
            text: 'Der Dienst ist aktuell nicht verfügbar. Bitte versuche es später nochmal',
          }),
        },
      });
      return;
    }

    this.cropper = new Cropper(this.$refs.image, {
      responsive: true,
      restore: true,
      preview: '.preview',
      checkCrossOrigin: true,
      checkOrientation: true,
      modal: false,
      guides: true,
      center: true,
      highlight: true,
      background: false,
      autoCrop: true,
      movable: true,
      rotatable: true,
      scalable: false,
      zoomable: false,
      zoomOnTouch: false,
      zoomOnWheel: false,
      cropBoxMovable: true,
      cropBoxResizable: true,
      toggleDragModeOnDblclick: true,
      aspectRatio: 1 / 1,
      crop: () => {},
      ready: () => {},
    });
  },
  computed: {
    ...mapGetters('modal', ['LoadingStatus']),
    ...mapGetters('profile', ['ImagecropSrc']),
    previewBoxHeight: function () {
      const clamp = (num, min, max) => Math.min(Math.max(num, min), max);
      // Calculates the Size of one Profile, respects responsivity and margin
      return (
        clamp(
          this.totalWidth * this.sizeobj.small.profileLarge.height.num,
          this.sizeobj.small.profileLarge.height.min,
          this.sizeobj.small.profileLarge.height.max
        ).toString() + 'px'
      );
    },
    previewBoxWidth: function () {
      const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

      // Calculates the Size of one Profile, respects responsivity and margin
      return (
        clamp(
          this.totalWidth * this.sizeobj.small.width.num,
          this.sizeobj.small.width.min,
          this.sizeobj.small.width.max
        ).toString() + 'px'
      );
    },
  },
  methods: {
    ...mapActions('profile', [
      'uploadProfileImage',
      'getProfileImage',
      'deleteProfileImage',
    ]),
    ...mapActions('modal', [
      'showSubmitModal',
      'showLoadingModal',
      'hideLoadingModal',
      'hideImagecropModal',
      'setImagecropSource',
    ]),
    ...mapGetters('profile', ['GET_PROFILEIMAGE']),
    ...mapMutations('profile', ['SET_IMAGECROPRES']),

    next() {
      const res = this.cropper.getData();
      const key = 'small';
      this.SET_IMAGECROPRES({ key, res });
      destroyCropper(this.cropper);
      this.$router.push({ name: 'ImageUpload-02' });
    },
    close() {
      destroyCropper(this.cropper);
      window.location.replace(process.env.VUE_APP_BASEURL_FRONTEND);
    },
    info() {
      this.showSubmitModal({
        status: 'info',
        time: 20000,
        text: 'Damit dein Profilbild immer korrekt dargestellt wird kannst du hier die perfekte Darstellung definieren',
      });
    },
  },
};
</script>

<style scoped>
/* dialog transition */
.v-enter-active {
  transition: opacity 1.3s ease;
}
.v-leave-active {
  transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.root {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1vh;
}

.container {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 10% 5% 50% 28%;
  grid-template-areas:
    'button'
    'info'
    'cropper'
    'preview';
  align-items: center;
  justify-content: center;
  padding-top: 2vh;
  padding-bottom: 2vh;
  width: 100%;
  height: 90vh;
  gap: 1%;
}

.progress,
.preview-text {
  display: flex;
  justify-content: center;
  font-family: 'Montserrat', sans-serif;
  color: var(--font-color);
  font-size: clamp(18px, 1.5rem, 50px);
  margin: 0.5rem 0;
}

.btn-container {
  grid-area: button;
  width: 70vw;
  justify-content: space-between;
  display: flex;
  margin-bottom: 4vh;
  margin-top: 2vh;
}
.btn-container > i {
  font-size: 4rem;
  color: #ed1c24;
}

.info-container {
  grid-area: info;
  width: 70vw;
  justify-content: center;
  display: flex;
  margin-bottom: 4vh;
  margin-top: 2vh;
}

.info-container > i {
  font-size: 3rem;
  color: #ed1c24;
}

.img-container {
  grid-area: cropper;
  width: 70vw;
  height: 100%;
}

.img-container > img {
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

.preview-container {
  grid-area: preview;
  height: 100%;
}

.preview-content-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 70vw;
}

.preview-static {
  height: v-bind(previewBoxHeight);
  width: v-bind(previewBoxWidth);
  margin: 0 4px 14px 4px;
  border-radius: 10px;
  animation: skeleton-loading 2s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: var(--box-color);
  }
  100% {
    background-color: var(--textbox-color);
  }
}

.preview {
  overflow: hidden;
  background: transparent;
}

.cropper-view-box {
  outline: 10px solid #ed1c24 !important;
  outline-color: #ed1c24 !important;
}

.cropper-point {
  background-color: #ed1c24 !important;
}
@media only screen and (max-width: 500px) {
  .btn-container > i {
    font-size: 2rem;
  }
  /* .img-container {
    width: 50vw;
    height: 30vh;
  } */
}
</style>
