<template>
  <div class="input-element">
    <!-- <vSelect
      v-model="selectedOption"
      @option:selecting="select($event)"
      class="selection"
      :options="filteredOptions"
      label="name"
      :clearable="false"
    ></vSelect> -->

    <label
      for="countries"
      class="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
    ></label>
    <select
      id="countries"
      v-model="value"
      @change="select"
      class="font-chooser block w-full rounded-lg p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:placeholder-gray-400"
    >
      <!-- <option :value="selectedOption.id" selected>{{ selectedOption.name }}</option> -->
      <option value="">{{ selectedValue }}</option>
      <option v-for="(option, index) in options" :value="option.id" :key="index">
        {{ option.name }}
      </option>
      <!-- <option value="CA">Canada</option>
  <option value="FR">France</option>
  <option value="DE">Germany</option> -->
    </select>
  </div>
</template>

<script>
// import 'vue-select/dist/vue-select.css';
// import vSelect from 'vue-select';
export default {
  props: ['title', 'titletext', 'icon', 'selected', 'selviewUrl'],
  components: {
    // vSelect,
  },
  data() {
    return {
      options: [],
      value: '',
    };
  },
  computed: {
    selectedValue: function () {
      const sel = this.options.find((option) => option.id === this.selected);
      if (sel instanceof Object) {
        return sel.name;
      }
      return 'Bitte auswählen...';
    },
    filteredOptions() {
      var availableOptions = this.options;
      var selectedOption = this.selected;
      return availableOptions.filter(function (option) {
        return option.id != selectedOption;
      });
    },
  },
  methods: {
    select() {
      this.$emit('selection', this.value);
      this.$emit('update:modelValue', this.value);
    },
  },
  async beforeMount() {
    try {
      var response = await this.$API.get(this.selviewUrl);
      this.options = response.data;
    } catch (error) {
      this.options = [];
    }
  },
  mounted() {
    // Avoid Inline Styles
    // document.querySelector('.vs__dropdown-toggle').style.width = '100%';
    // document.querySelector('.vs__dropdown-toggle').style.border = 'none';
    //
  },
};
</script>

<style scoped>
.font-chooser {
  font-family: 'Lato', sans-serif;
  background-color: var(--settings-input);
  font-family: inherit;
  font-weight: inherit;
  color: var(--font-color);
  font-size: clamp(12px, 1.1rem, 50px);
}

.input-element {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 1000px) {
  .input-element {
    width: 100%;
    margin-top: 1rem;
  }
  .select {
    width: 100%;
  }
  .input-container {
    width: 80%;
  }
}
</style>
