<template>
  <div>
    <div>
      <FormField
        @inputError="this.EmailForm.appuser_email.error = $event"
        title="Neue Email"
        titletext="Diese E-Mail-Adresse ist nur für dich sichtbar"
        v-model="this.EmailForm.appuser_email.value"
        :value="this.EmailForm.appuser_email.value"
        :error="this.EmailForm.appuser_email.error"
      />

      <FormField
        @inputError="EmailForm.appuser_password.error = $event"
        title="Aktuelles Passwort"
        type="password"
        titletext="Diese E-Mail-Adresse ist nur für dich sichtbar"
        v-model="EmailForm.appuser_password.value"
        :value="EmailForm.appuser_password.value"
        :error="EmailForm.appuser_password.error"
      />
      <FormButtonContainer>
        <FormSubmit @click="EmailResetSubmit" id="submit-email" :submitText="'E-Mail ändern'" />
      </FormButtonContainer>
    </div>

    <div>
      <FormField
        @inputError="PasswordForm.appuser_password.error = $event"
        title="Aktuelles Passwort"
        type="password"
        v-model="PasswordForm.appuser_password.value"
        :value="PasswordForm.appuser_password.value"
        :error="PasswordForm.appuser_password.error"
      />

      <FormField
        @inputError="PasswordForm.appuser_newpassword.error = $event"
        title="Neues Passwort"
        type="password"
        v-model="PasswordForm.appuser_newpassword.value"
        :value="PasswordForm.appuser_newpassword.value"
        :error="PasswordForm.appuser_newpassword.error"
      />

      <FormField
        @inputError="PasswordForm.appuser_newpassword_confirm.error = $event"
        title="Passwort wiederholen"
        type="password"
        v-model="PasswordForm.appuser_newpassword_confirm.value"
        :value="PasswordForm.appuser_newpassword_confirm.value"
        :error="PasswordForm.appuser_newpassword_confirm.error"
      />
      <FormButtonContainer>
        <FormSubmit @click="PasswordResetSubmit" :submitText="'Passwort ändern'" />
      </FormButtonContainer>
    </div>
  </div>
</template>

<script>
import FormSubmit from "@/components/Form/FormSubmit.vue";
import FormField from "@/components/Form/FormField.vue";
import FormButtonContainer from "@/components/Form/FormButtonContainer.vue";
import { mapActions } from "vuex";
export default {
  components: {
    FormSubmit,
    FormField,
    FormButtonContainer,
  },
  props: ["PrivateEmailForm", "PrivatePasswordForm"],
  data() {
    return {
      validations: {
        appuser_email: {},
        appuser_password: {},
      },
      servererror: "",
      origForm: {
        PrivateEmailForm: "",
        PrivatePasswordForm: "",
      },
    };
  },
  computed: {
    EmailForm: function () {
      return this.PrivateEmailForm;
    },
    PasswordForm: function () {
      return this.PrivatePasswordForm;
    },
    EmailFormHasErrors: function () {
      const formFields = [
        this.EmailForm.appuser_email.error,
        this.EmailForm.appuser_password.error,
      ];
      return formFields.some((element) => element === true);
    },
    PasswordFormHasErrors: function () {
      const formFields = [
        this.PasswordForm.appuser_password.error,
        this.PasswordForm.appuser_newpassword.error,
        this.PasswordForm.appuser_newpassword_confirm.error,
      ];
      return formFields.some((element) => element === true);
    },
  },
  methods: {
    ...mapActions("modal", ["showSubmitModal"]),
    // Submit
    async EmailResetSubmit() {
      // Check if form has validation errors
      this.emitter.emit("checkAll");
      if (this.EmailFormHasErrors === true) {
        this.showSubmitModal({
          status: "error",
          time: 3000,
          text: "Prüfe deine Eingabe!",
        });
        return;
      }

      //Check if Password has Value
      if (this.EmailForm.appuser_password.value === "") {
        this.EmailForm.appuser_password.error = true;
        this.showSubmitModal({
          status: "error",
          time: 3000,
          text: "Bitte gebe dein aktuelles Passwort ein",
        });
        return;
      }
      //Check if new Email was entered
      if (
        this.EmailForm.appuser_email.value === this.origForm.PrivateEmailForm.appuser_email.value
      ) {
        this.EmailForm.appuser_email.error = true;
        this.showSubmitModal({
          status: "error",
          time: 3000,
          text: "Bitte gib eine neue Email ein",
        });
        return;
      }

      try {
        this.EmailForm.appuser_email.value = this.EmailForm.appuser_email.value
          .toLowerCase()
          .trim();
        await this.$API.post("/email/reset", {
          appuser_email: this.EmailForm.appuser_email.value,
          appuser_password: this.EmailForm.appuser_password.value,
        });
        this.showSubmitModal({
          status: "success",
          time: 5000,
          text: "Bitte bestätige deine neue E-Mail Adresse!",
        });

        // Handle responses
      } catch (error) {
        switch (error.response.data.message) {
          case "WRONG_CREDENTIALS":
            this.EmailForm.appuser_password.error = true;
            this.servererror = "Bitte Überprüfe dein Passwort";
            break;
          case "EMAIL_ALREADY_EXISTS":
            this.EmailForm.appuser_email.error = true;
            this.servererror = "Die E-Mail Adresse wird bereits benutzt!";
            break;
          case "SAME_EMAIL":
            this.EmailForm.appuser_email.error = true;
            this.servererror = "Bitte gib eine neue Email ein";
            break;
          default:
            this.servererror =
              "Der Dienst ist aktuell nicht verfügbar. Bitte versuche es später nochmal";
            break;
        }

        // Show Error Message
        this.showSubmitModal({
          status: "error",
          time: 5000,
          text: this.servererror,
        });
      }
    },

    async PasswordResetSubmit() {
      // Check if form has validation errors
      this.emitter.emit("checkAll");
      if (this.PasswordFormHasErrors === true) {
        this.showSubmitModal({
          status: "error",
          time: 3000,
          text: "Prüfe deine Eingabe!",
        });
        return;
      }
      //Check if Password Inputs have Values

      if (this.PasswordForm.appuser_password.value === "") {
        this.PasswordForm.appuser_password.error = true;
        this.showSubmitModal({
          status: "error",
          time: 3000,
          text: "Bitte gebe dein aktuelles Passwort ein",
        });
        return;
      }

      if (this.PasswordForm.appuser_newpassword.value === "") {
        this.PasswordForm.appuser_newpassword.error = true;
        this.showSubmitModal({
          status: "error",
          time: 3000,
          text: "Bitte gebe ein neues Passwort ein",
        });
        return;
      }

      if (this.PasswordForm.appuser_newpassword_confirm.value === "") {
        this.PasswordForm.appuser_newpassword_confirm.error = true;
        this.showSubmitModal({
          status: "error",
          time: 3000,
          text: "Bitte wiederhole das neue Passwort",
        });
        return;
      }

      // Check if Password are equal
      if (
        this.PasswordForm.appuser_newpassword.value !==
        this.PasswordForm.appuser_newpassword_confirm.value
      ) {
        this.PasswordForm.appuser_newpassword_confirm.error = true;
        this.PasswordForm.appuser_newpassword.error = true;
        this.showSubmitModal({
          status: "error",
          time: 3000,
          text: "Passwörter stimmen nicht überein",
        });
        return;
      }

      var submitObj = {
        appuser_password: this.PasswordForm.appuser_password.value,
        appuser_newpassword: this.PasswordForm.appuser_newpassword.value,
      };

      // Submit
      try {
        await this.$API.post("/password/reset", submitObj);
        this.showSubmitModal({
          status: "success",
          time: 3000,
          text: "Passwort geändert!",
        });

        // Handle responses
      } catch (error) {
        switch (error.response.data.message) {
          case "WRONG_CREDENTIALS":
            this.PasswordForm.appuser_password.error = true;
            this.servererror = "Falsches Passwort";
            break;

          default:
            this.servererror =
              "Der Dienst ist aktuell nicht verfügbar. Bitte versuche es später nochmal";
            break;
        }
        // Show Error Message
        this.showSubmitModal({
          status: "error",
          time: 5000,
          text: this.servererror,
        });
      }
    },
  },
  mounted() {
    this.origForm.PrivateEmailForm = JSON.parse(JSON.stringify(this.PrivateEmailForm));
    this.origForm.PrivatePasswordForm = JSON.parse(JSON.stringify(this.PrivatePasswordForm));
  },
  watch: {},
};
</script>

<style scoped>
#submit-email {
  border-bottom: solid 1px grey;
}
.right {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 70%;
  height: 100%;
}
.leftsecruity {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  width: 20%;
  flex-direction: column;
}
.set {
  margin: 1rem 0;
  color: var(--font-color);
}
.secruityfield {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1rem 0;
  border-bottom: solid 1px grey;
}
.inputfield {
  align-items: flex-start;
}
.input {
  width: 30rem;
  height: 2.5rem;
  background-color: var(--settings-input);
  border-radius: 5px;
  border: none;
  cursor: text;
  margin: 1rem 0 0 0;
  padding: 0 1rem;
}
.closewrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.delinput,
.delinput1,
.delinput2 {
  margin: 1rem 0 0 1rem;
  cursor: pointer;
  color: var(--font-color);
}
.delinput1 {
  margin: 6.7rem 0 0 30rem;
}
.delinput2 {
  margin: 0 0 7.2rem 30rem;
}
.delinput:hover,
.delinput1:hover,
.delinput2:hover {
  color: #ed1c24;
}
.info {
  font-weight: 300;
  font-size: clamp(12px, 1rem, 50px);
  margin: 0 0 0 1rem;
  color: var(--font-color);
}
@media only screen and (max-width: 1000px) {
  .secruityfield {
    flex-direction: column;
  }
  .leftsecruity {
    width: 80%;
  }
  .right {
    width: 80%;
  }
  .set {
    margin: 0;
  }
  .input {
    width: 90%;
  }
  .info {
    margin: 0;
  }
}
</style>
