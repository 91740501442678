<template>
  <UserModal />
  <ReportModal />
  <SidebarToggle />
  <ReportDrawer />

  <div class="flex min-h-screen overflow-hidden bg-gray-900 pt-4">
    <Sidebar />

    <div class="relative h-full w-full overflow-y-auto bg-gray-900 lg:ml-64">
      <div class="px-4 pt-6">
        <main>
          <Home v-if="NAVIGATION.dashboard" />
          <User v-if="NAVIGATION.user" />
          <Report v-if="NAVIGATION.report" />
          <Affliate v-if="NAVIGATION.affliate" />
          <X1 v-if="NAVIGATION.x1" />
          <X2 v-if="NAVIGATION.x2" />
          <Apperror v-if="NAVIGATION.apperror" />
        </main>
      </div>
    </div>
  </div>
</template>

<script>
// import {
//   //   initAccordions,
//   //   initCarousels,
//   //   initCollapses,
//   //   initDials,
//   //   initDismisses,
//   initDrawers,
//   //   initDropdowns,
//   //   initModals,
//   //   initPopovers,
//   //   initTabs,
//   //   initTooltips,
// } from "flowbite";

import '@/assets/css/tailwind.css';
import { mapGetters } from 'vuex';
import Sidebar from '@/components/Dashboard/Sidebar.vue';
import SidebarToggle from '@/components/Dashboard/SidebarToggle.vue';
import UserModal from '@/components/Dashboard/UserModal.vue';
import ReportModal from '@/components/Dashboard/ReportModal.vue';
// import PopupModal from "@/components/Dashboard/PopupModal.vue"
import User from '@/components/Dashboard/user.vue';
import Home from '@/components/Dashboard/home.vue';
import Report from '@/components/Dashboard/Report.vue';
import Affliate from '@/components/Dashboard/Affliate.vue';
import ReportDrawer from '@/components/Dashboard/ReportDrawer.vue';
import X1 from '@/components/Dashboard/X1.vue';
import X2 from '@/components/Dashboard/X2.vue';
import Apperror from '@/components/Dashboard/apperror.vue';
export default {
  components: {
    Sidebar,
    // PopupModal,
    SidebarToggle,
    ReportDrawer,
    UserModal,
    ReportModal,
    User,
    Home,
    Report,
    Affliate,
    Apperror,
    X1,
    X2,
  },

  data() {
    return {};
  },
  computed: {
    ...mapGetters('dashboard', ['NAVIGATION']),
  },
  methods: {},
  mounted() {
    // initAccordions();
    // initCarousels();
    // initCollapses();
    // initDials();
    // initDismisses();
    // initDrawers();
    // initDropdowns();
    // initModals();
    // initPopovers();
    // initTabs();
    // initTooltips();
  },
};
</script>

<style></style>
