<template>
  <div
    class="min-h-screen flex-col items-center justify-between rounded-lg border border-gray-700 bg-gray-800 p-4 shadow-sm sm:p-6"
  >
    <!-- Header -->
    <div class="mb-1 w-full">
      <div class="mb-4">
        <h1 class="text-xl font-semibold text-white sm:text-2xl">Meldungen</h1>
      </div>
    </div>
    <!-- Header -->

    <div class="dark w-full">
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
        <!-- Search -->
        <div class="flex items-center justify-start p-4 pb-4">
          <label for="table-search" class="sr-only">Search</label>
          <div class="relative">
            <div
              class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
            >
              <svg
                class="h-5 w-5 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div>
            <input
              v-model="current_search.value"
              @input="handleSearch"
              type="text"
              id="table-search-users"
              class="block w-80 rounded-lg border border-gray-300 bg-gray-50 p-2 pl-10 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
              placeholder="Search for users"
            />
          </div>
          <SearchDropdown
            @changeSearch="this.current_search = $event"
            :avaliable_options="search_options"
            :current_search="current_search"
          />
        </div>
        <!-- Search -->
        <div class="w-full p-10">
          <div class="flex items-center justify-center p-2 pb-4">
            <Pagination
              @paginate="this.getData($event)"
              :total_pages="total_pages"
              :current_page="current_page"
            />
          </div>
          <!-- Table -->
          <table class="w-fulltext-sm w-full text-left text-gray-500 dark:text-gray-400">
            <thead
              class="whitespace-nowrap bg-gray-50 p-4 text-base font-medium text-gray-900 dark:bg-gray-700 dark:text-white"
            >
              <tr>
                <th scope="col" class="p-4"></th>
                <th scope="col" class="px-6 py-3">Von</th>
                <th scope="col" class="p-4"></th>
                <th scope="col" class="px-6 py-3">Benutzer</th>
                <th scope="col" class="px-6 py-3">Grund</th>
                <th scope="col" class="px-6 py-3">Erledigt</th>
                <th scope="col" class="px-6 py-3"></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="report in data"
                :key="report.rep_id"
                class="border-b bg-white hover:bg-gray-50 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-600"
              >
                <!-- From appuser -->

                <td class="w-4 p-4"></td>
                <th
                  scope="row"
                  class="flex items-center whitespace-nowrap px-6 py-4 text-gray-900 dark:text-white"
                >
                  <VLazyImage
                    v-if="report.from_imagesrc_small"
                    class="h-10 w-10 rounded-full"
                    :src="
                      'https://devel.kremer-marketing.de' +
                      '/image/search/' +
                      report.from_imagesrc_small +
                      '?mode=tiny'
                    "
                    alt=""
                  />
                  <VLazyImage
                    v-else
                    class="h-10 w-10 rounded-full"
                    :src="'https://uxwing.com/wp-content/themes/uxwing/download/peoples-avatars/no-profile-picture-icon.png'"
                    alt=""
                  />
                  <div class="pl-3">
                    <div class="text-base font-semibold">
                      {{ report.from_profile_nickname }}
                    </div>
                    <div class="font-normal text-gray-500">
                      {{ report.from_appuser_email }}
                    </div>
                  </div>
                </th>

                <!-- reported appuser -->
                <td class="w-4 p-4"></td>

                <th
                  scope="row"
                  class="flex items-center whitespace-nowrap px-6 py-4 text-gray-900 dark:text-white"
                >
                  <VLazyImage
                    v-if="report.reported_imagesrc_small"
                    class="h-10 w-10 rounded-full"
                    :src="
                      'https://devel.kremer-marketing.de' +
                      '/image/search/' +
                      report.reported_imagesrc_small +
                      '?mode=tiny'
                    "
                    alt=""
                  />
                  <VLazyImage
                    v-else
                    class="h-10 w-10 rounded-full"
                    :src="'https://uxwing.com/wp-content/themes/uxwing/download/peoples-avatars/no-profile-picture-icon.png'"
                    alt=""
                  />
                  <div class="pl-3">
                    <div class="text-base font-semibold">
                      {{ report.reported_profile_nickname }}
                    </div>
                    <div class="font-normal text-gray-500">
                      {{ report.reported_appuser_email }}
                    </div>
                  </div>
                </th>

                <!-- Reason -->

                <td class="px-6 py-4">
                  <div class="text-base font-semibold">{{ report.repreason_reason }}</div>
                </td>

                <!-- Done -->

                <td class="px-6 py-4">
                  <svg
                    v-if="report.rep_done"
                    aria-hidden="true"
                    class="h-8 w-8 text-green-700"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="1.5"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.5 12.75l6 6 9-13.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>

                  <svg
                    v-if="!report.rep_done"
                    class="h-8 w-8 text-red-700"
                    aria-hidden="true"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="1.5"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 18L18 6M6 6l12 12"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </td>

                <td class="">
                  <button
                    @click="openReportDrawer(report.rep_id)"
                    type="button"
                    class="focus:outline-none inline-flex items-center rounded-lg bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    <svg
                      aria-hidden="true"
                      class="h-5 w-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/Dashboard/Ui/Pagination.vue';
import VLazyImage from 'v-lazy-image';
import SearchDropdown from '@/components/Dashboard/Ui/SearchDropdown.vue';
import { mapActions } from 'vuex';
export default {
  components: {
    Pagination,
    VLazyImage,
    SearchDropdown,
  },

  data() {
    return {
      data: '',
      total_pages: 0,
      current_page: 1,
      per_page: 20,
      total_count: 0,
      search_options: [
        {
          column: 'reported_profile_nickname',
          label: 'Benutzername',
        },
        {
          column: 'reported_appuser_email',
          label: 'E-Mail',
        },
        {
          column: 'from_profile_nickname',
          label: 'Gemeldet von: Benutzername',
        },
        {
          column: 'from_appuser_email',
          label: 'Gemeldet von: E-Mail',
        },
      ],
      current_search: {
        value: '',
        column: 'reported_profile_nickname',
        label: 'Benutzername',
      },
    };
  },
  computed: {},
  methods: {
    ...mapActions('dashboard', ['openReportDrawer']),
    async getData(page = 1) {
      this.current_page = page;
      var response = await this.$API.post('/admin/report/all', {
        page: this.current_page,
        per_page: this.per_page,
        search_column: this.current_search.column,
        search_value: this.current_search.value,
      });
      if (response.status === 200) {
        this.data = response.data.results;
        (this.total_pages = response.data.pages),
          (this.total_count = response.data.total);
      }
    },
    async handleSearch() {
      if (
        this.current_search.value.length > 3 ||
        this.current_search.value.length === 0
      ) {
        await this.getData();
        return;
      }
    },
  },
  async mounted() {
    this.getData();
    this.emitter.on('LoadReports', this.getData);
  },
  beforeUnmount() {
    this.emitter.off('LoadReports');
  },
};
</script>

<style></style>
