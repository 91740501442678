<template>
  <div class="inbox">
    <VLazyImage
      @click="showProfile"
      class="inbox__picture"
      :src="this.url + this.image"
      :src-placeholder="this.url + this.image + '?resolution=low'"
      alt="Kein Bild"
    />
    <div @click="showMessages" :class="{ unseen: this.unseen }" class="inbox__textbox">
      <p class="inbox__textbox__name"></p>
      {{ nickname }}
      <p class="inbox__textbox__lastmsg"></p>
      {{ reducedMsg }}
    </div>
    <!-- <div @click="showProfile" class="button-wrapper">
      <SocialIcon :icon="'fas fa-user'" />
    </div> -->
  </div>
</template>

<script>
import VLazyImage from 'v-lazy-image';
// import SocialIcon from "@/components/Ui/SocialIcon.vue";
import { mapActions, mapGetters } from 'vuex';
export default {
  components: {
    VLazyImage,
    // SocialIcon,
  },
  computed: {
    ...mapGetters('auth', ['SocketStatus']),
    ...mapGetters('modal', ['MobileStatus']),
    reducedMsg() {
      if (this.lastMsg.length > 15) {
        var msg = this.lastMsg.substring(0, 18) + '.....';
        return msg;
      }
      return this.lastMsg;
    },
  },
  data() {
    return {
      url: process.env.VUE_APP_BASEURL + '/image/search/',
    };
  },
  props: ['nickname', 'lastMsg', 'image', 'unseen', 'inbox'],
  methods: {
    ...mapActions('chat', ['sendMsg', 'startChat', 'fetchInboxes']),
    ...mapActions('search', ['profileHoverData', 'profileClickActive']),
    ...mapActions('modal', ['showLoginModal', 'explicitOptionsModal', 'hideAllModals']),
    clickInboxImg() {
      //Validate click first

      //Close all other Popups on Mobile
      if (window.innerWidth < 800 && this.MobileStatus) {
        return;
      }

      // Show hover information is the fallback
      this.profileClickActive(this.profile_id);
    },
    showMessages() {
      this.$emit('showMessages', this.inbox);
    },
    showProfile() {
      this.$emit('showProfile', this.inbox.profile_id);
    },
  },
};
</script>

<style>
.inbox {
  display: flex;
  width: 100%;
  height: 5rem;
  min-height: 5rem;
  margin: 0.2rem 0;
  cursor: pointer;
  align-items: center;
  border-radius: 10px;
}
.inbox:hover {
  background-color: #ed1c24;
}

.inbox:active {
  transform: translateY(4px);
}
.inbox__picture {
  margin: 0 0 0 1vw;
  width: 3.5rem;
  height: 3.5rem;
  cursor: pointer;
  border-radius: 10px;
}
.inbox__textbox {
  width: 100%;
  margin: 0 0 0 1vw;
  overflow: hidden;
  cursor: pointer;
}
.inbox__textbox.unseen {
  font-weight: bolder;
}
.inbox__textbox__name {
  padding: 0 0 0.2rem 0;
  font-size: 18px;
}
.inbox__textbox__lastmsg {
  font-size: 13px;
}
.button-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  width: 20%;
}
.button-wrapper:first-child {
  margin-top: 0.2rem;
}

@media only screen and (max-width: 800px) {
  .inbox__textbox {
    margin: 0 0 0 1rem;
  }
}
</style>
