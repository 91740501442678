// import socket from "@/socket";
import store from '@/store/store';
export default {
  namespaced: true,
  state() {
    return {
      Inboxes: [],
      ActiveInbox: '',
      Messages: [],
      IgnoredInboxes: [],
    };
  },
  mutations: {
    SET_INBOXES(state, inboxes) {
      state.Inboxes = inboxes;
    },
    ADD_INBOX(state, inbox) {
      state.Inboxes.push(inbox);
    },
    SET_ACTIVEINBOX(state, inbox) {
      state.ActiveInbox = inbox;
    },
    SET_INBOXREAD(state, inbox_id) {
      var inboxes = state.Inboxes;
      var index = inboxes.findIndex((object) => {
        return object.inbox_id === inbox_id;
      });
      if (index !== -1) {
        inboxes[index].inboxparts_unseen = false;
      }
    },
    SET_IGNOREDINBOXES(state, payload) {
      state.IgnoredInboxes = payload;
    },
    SET_INBOXLASTMSG(state, payload) {
      let inboxes = state.Inboxes;
      let inbox_id = payload.message_inbox_id;
      let msg = payload.message_msg;

      let index = inboxes.findIndex((object) => {
        return object.inbox_id === inbox_id;
      });
      if (index !== -1) {
        inboxes[index].message_msg = msg;
      }
    },
    SET_MESSAGES(state, messages) {
      state.Messages = messages;
    },
    SET_MESSAGES_UNSHIFT(state, messages) {
      state.Messages = [...messages, ...state.Messages];
    },
    ADD_MESSAGE(state, message) {
      state.Messages.push(message);
    },
  },
  getters: {
    GETINBOXES: (state) => {
      return state.Inboxes;
    },
    GET_MESSAGES: (state) => {
      return state.Messages;
    },
    GET_ACTIVEINBOX: (state) => {
      return state.ActiveInbox;
    },
    GET_UNSEEN: (state) => {
      // Check if one modal is still open
      const unseen = (element) => element.inboxparts_unseen === true;
      return state.Inboxes.some(unseen);
    },
    GET_IGNOREDINBOXES: (state) => {
      return state.IgnoredInboxes;
    },
  },
  actions: {
    async sendMsg(context, payload) {
      let response = await this.$API.post('/chat/messages/send', payload);
      if (response.status === 200) {
        let msgObj = response.data[0];
        context.commit('ADD_MESSAGE', msgObj);
        context.commit('SET_INBOXLASTMSG', msgObj);
      }
    },
    handleMsg(context, payload) {
      // If the inbox is still open we dont won´t to fetch all messages again
      // Add the message to the state and set the inbox as read
      var screenWidth = window.innerWidth;
      var chatStatus = store.getters['modal/ChatInboxStatus'];
      var chatMessageStatus = store.getters['modal/ChatMessagesStatus'];

      if (
        payload.message_inbox_id == context.state.ActiveInbox.inbox_id &&
        chatMessageStatus
      ) {
        context.commit('ADD_MESSAGE', payload);
        this.$API.post('/chat/inbox/seen', {
          inbox_id: payload.message_inbox_id,
        });
      }
      context.dispatch('fetchInboxes');
      // Send a toast notification if the chat is not open
      if (screenWidth > 800 && !chatStatus && !chatMessageStatus) {
        context.dispatch(
          'modal/showSubmitModal',
          {
            status: 'info',
            text: 'Neue Nachricht!',
            time: 3000,
            icon: 'fas fa-envelope',
          },
          { root: true }
        );
      }
    },
    async fetchInboxes(context) {
      let response = await this.$API.post('/chat/inbox/all', {
        ignored: false,
      });
      if (response.status === 200) {
        context.commit('SET_INBOXES', response.data);
      }
    },
    async fetchMessages(context, inbox_id) {
      let response = await this.$API.post('/chat/messages/request', {
        inbox_id: inbox_id,
      });
      if (response.status === 200) {
        context.commit('SET_MESSAGES', response.data);
      }
    },

    async fetchMoreMessages(context, inbox_id) {
      var messages = context.state.Messages;
      var ids = [];
      messages.forEach((element) => ids.push(element.message_id));
      let response = await this.$API.post('/chat/messages/request/infinite', {
        inbox_id: inbox_id,
        message_ids: ids,
      });
      if (response.status === 200) {
        context.commit('SET_MESSAGES_UNSHIFT', response.data);
      }
    },
    async fetchIgnoredInboxes(context) {
      let response = await this.$API.post('/chat/inbox/all', {
        ignored: true,
      });
      if (response.status === 200) {
        context.commit('SET_IGNOREDINBOXES', response.data);
      }
    },
    async requestInbox(context, profile_id) {
      // Check if inbox is present locally
      var inboxes = context.state.Inboxes;
      var inbox = inboxes.find((element) => element.profile_id === profile_id);
      if (inbox) {
        context.commit('SET_ACTIVEINBOX', inbox);
        context.dispatch('fetchMessages', inbox.inbox_id);
        context.dispatch('modal/hideAllModals', null, { root: true });
        context.dispatch('modal/showChatInbox', null, { root: true });
        context.dispatch('modal/showChatMessages', null, { root: true });
        return;
      }

      // Else Request new Inbox
      let response = await this.$API.post('/chat/inbox/request', {
        profile_id: profile_id,
      });

      // Create new Inbox
      if (response.status === 200) {
        let new_inbox_id = response.data.inbox_id;
        // Get Details for new Inbox
        let new_inbox_resp = await this.$API.post('/chat/inbox/get', {
          inbox_id: new_inbox_id,
        });
        if (new_inbox_resp.status === 200) {
          let new_inbox = new_inbox_resp.data[0];
          context.commit('ADD_INBOX', new_inbox);
          context.commit('SET_ACTIVEINBOX', new_inbox);
          context.dispatch('fetchMessages', new_inbox.inbox_id);
          context.dispatch('modal/showChatInbox', null, { root: true });
          context.dispatch('modal/showChatMessages', null, { root: true });
        }
      }
    },
    startChat(context, inbox) {
      const activeInbox = store.getters['chat/GET_ACTIVEINBOX'];
      if (inbox.inbox_id === activeInbox.inbox_id) return;
      context.commit('SET_ACTIVEINBOX', inbox);
      context.commit('SET_INBOXREAD', inbox.inbox_id);
      context.dispatch('fetchMessages', inbox.inbox_id);
      context.dispatch('modal/showChatInbox', null, { root: true });
      context.dispatch('modal/showChatMessages', null, { root: true });
    },
    async ignoreProfile(context, profile_id) {
      // Make sure the users have an Inbox
      let response = await this.$API.post('/chat/inbox/request', {
        profile_id: profile_id,
      });
      if (response.status === 200) {
        let new_inbox_id = response.data.inbox_id;
        //Mark inbox as ignored
        let res = await this.$API.post('/chat/inboxparts/ignore', {
          inbox_id: new_inbox_id,
        });
        // Get updatet inbox list
        if (res.status === 200) {
          context.dispatch('fetchInboxes');
          return res.data.inboxparts_ignored;
        } else {
          return false;
        }
      }
    },
  },
};
