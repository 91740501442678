<template>
  <Transition>
    <!-- Wrapper -->
    <div
      v-if="GET_CLICKSTATE_ACTIVE === true"
      @click="closeModal($event)"
      class="wrapper"
    >
      <!-- Center Container responsive -->
      <div class="layout-main">
        <div class="position container">
          <div class="social">
            <div class="leftsocial">
              <!-- Toggle favorites
                 topicon fas fa-star = AUSGEFÜLLT
                 topicon far fa-star = LEER
            -->
              <SocialIcon
                @click="toggleProfileFavorite(this.profile.data.profile_id)"
                v-if="!isFavorite && this.profile.data.profile_id !== this.GET_PROFILE"
                :icon="'topicon far fa-star'"
              />

              <SocialIcon
                @click="toggleProfileFavorite(this.profile.data.profile_id)"
                v-if="isFavorite && this.profile.data.profile_id !== this.GET_PROFILE"
                :icon="'topicon fas fa-star'"
              />

              <!-- Toggle favorites -->
              <SocialIcon
                v-if="this.profile.data.profile_id !== this.GET_PROFILE"
                @click="
                  this.showReportModal({
                    profile_id: this.profile.data.profile_id,
                    profile_nickname: this.profile.data.profile_nickname,
                  })
                "
                :icon="'topicon fas fa-eye'"
              />
            </div>
            <div class="rightsocial">
              <SocialIcon
                v-if="PaymentStatus && this.profile.data.profile_id !== this.GET_PROFILE"
                @click="startChat"
                :icon="'topicon fas fa-comment-alt'"
              />
            </div>
          </div>
          <div class="maindata">
            <div class="name">
              <div class="top">
                <p v-if="profile.data.profile_firstname">
                  {{
                    this.profile.data.profile_firstname +
                    '&nbsp;' +
                    this.profile.data.profile_lastname +
                    '&nbsp;'
                  }}
                </p>
              </div>
              <div class="bot">
                <p v-if="this.profile.data.profile_nickname">
                  {{ this.profile.data.profile_nickname }}
                </p>
                <div class="city">
                  <p v-if="this.profile.data.profile_city">
                    {{ this.profile.data.profile_city + '&nbsp;' }}
                  </p>
                  <p v-if="this.profile.data.provinz_name">
                    {{ this.profile.data.provinz_name }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="link">
            <SocialIcon
              v-if="profile.data.profile_instagram"
              @click="buildUrl(baseUrl.instagram, profile.data.profile_instagram)"
              :icon="'fab fa-instagram'"
            />
            <SocialIcon
              v-if="profile.data.profile_facebook"
              @click="buildUrl(baseUrl.facebook, profile.data.profile_facebook)"
              :icon="'fab fa-facebook-f'"
            />
            <SocialIcon
              v-if="profile.data.profile_twitch"
              @click="buildUrl(baseUrl.twitch, profile.data.profile_twitch)"
              :icon="'fab fa-twitch'"
            />
            <SocialIcon
              v-if="profile.data.profile_twitter"
              @click="buildUrl(baseUrl.twitter, profile.data.profile_twitter)"
              :icon="'fab fa-twitter'"
            />
            <SocialIcon
              v-if="profile.data.profile_youtube"
              @click="buildUrl(baseUrl.youtube, profile.data.profile_youtube)"
              :icon="'fab fa-youtube'"
            />
            <SocialIcon
              v-if="profile.data.profile_tiktok"
              @click="buildUrl(baseUrl.tiktok, profile.data.profile_tiktok)"
              :icon="'fab fa-tiktok'"
            />
            <SocialIcon
              v-if="profile.data.profile_linkedin"
              @click="buildUrl(baseUrl.linkedin, profile.data.profile_linkedin)"
              :icon="'fab fa-linkedin-in'"
            />
            <SocialIcon
              v-if="profile.data.profile_xing"
              @click="buildUrl(baseUrl.xing, profile.data.profile_xing + '/cv')"
              :icon="'fab fa-xing'"
            />
            <SocialIcon
              v-if="profile.data.profile_pinterest"
              @click="buildUrl(baseUrl.pinterest, profile.data.profile_pinterest)"
              :icon="'fab fa-pinterest'"
            />
          </div>

          <div class="aboutme">
            <div class="outcenter">
              <p class="abouthead">About me</p>
              <p>{{ this.profile.data.profile_aboutme }}</p>
            </div>
          </div>

          <div class="service">
            <div
              v-for="rootCategory in this.profile.data.profile_categories"
              :key="rootCategory"
              class="categorie"
            >
              <div class="headline">{{ rootCategory.category_name }}</div>
              <div class="paragraph">
                <div
                  v-for="childCategory in rootCategory.child_categories"
                  :key="childCategory"
                >
                  <p class="paragraph--categorie">{{ childCategory.category_name }}</p>
                </div>
              </div>
            </div>
          </div>

          <!-- Hier liegen die Elemente die du verwenden solltest: -->

          <!-- ToDo: Wir brauchen noch Icons für "Favorit", "Ignorieren", "Melden" -->
          <!-- Dafür einfach ein Icon anlegen: -->
          <!-- BEISPIEL: -->
          <!-- <SocialIcon
                  :icon="'fab XYZABCDEF'"
                /> -->

          <!-- Chat-Icon -->
          <!--
              </div> -->

          <!-- Hier sind die Kategorien: -->
          <!-- <div class="contentcontainer">
              <div
                v-for="rootCategory in this.profile.data.profile_categories"
                :key="rootCategory"
                class="categorie"
              >
                <div class="headline">{{ rootCategory.category_name }}</div>
                <div class="paragraph">
                  <div v-for="childCategory in rootCategory.child_categories" :key="childCategory">
                    <p class="paragraph--categorie">{{ childCategory.category_name }}</p>
                  </div>
                </div>
              </div>
            </div> -->

          <!-- GL HF!!! -->
        </div>
        <VLazyImage
          :src="this.imageUrl(this.profile.data.imagesrc_large)"
          alt=""
          class="position imageclicksmall border"
        />

        <VLazyImage
          :src="this.imageUrl(this.profile.data.imagesrc_click)"
          alt=""
          class="position imageclick border"
        />
        <VLazyImage
          :src="this.imageUrl(this.profile.data.imagesrc_medium)"
          alt=""
          class="position imageclickmid border"
        />

        <div class="position opacity border"></div>
      </div>
      <div class="layout-footer"></div>
    </div>
  </Transition>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import VLazyImage from 'v-lazy-image';
import SocialIcon from '@/components/Ui/SocialIcon.vue';
export default {
  components: {
    VLazyImage,
    SocialIcon,
  },
  data() {
    return {
      isload: false,
      baseUrl: {
        instagram: 'https://www.instagram.com/',
        facebook: 'https://www.facebook.com/',
        twitch: 'https://www.twitch.tv/',
        twitter: 'https://twitter.com/',
        youtube: 'https://www.youtube.com/c/',
        tiktok: 'https://www.tiktok.com/@',
        linkedin: 'https://www.linkedin.com/in/',
        xing: 'https://www.xing.com/profile/',
        pinterest: 'https://www.pinterest.de/',
      },
    };
  },
  computed: {
    ...mapGetters('search', ['GET_CLICKSTATE_ACTIVE', 'GET_CLICKDATA']),
    ...mapGetters('auth', ['PaymentStatus']),
    ...mapGetters('auth', ['GET_PROFILE']),
    ...mapGetters({
      isFavorite: 'search/GET_PROFILE_FAVORITE',
      isIgnored: 'search/GET_PROFILE_IGNORED',
    }),
    ...mapGetters({
      profile: 'search/GET_CLICKSTATE',
    }),
    lowresImg: function () {
      return `url(${this.imageUrl(this.profile.data.imagesrc_click, true)})`;
    },
    highresImg: function () {
      return `url(${this.imageUrl(this.profile.data.imagesrc_click)})`;
    },
  },
  methods: {
    ...mapActions('search', [
      'profileHoverData',
      'profileClickActive',
      'profileClickHide',
      'toggleProfileFavorite',
      'toggleProfileIgnore',
    ]),
    ...mapActions('chat', ['requestInbox', 'ignoreProfile']),
    ...mapActions('modal', ['showReportModal']),
    loadDone() {
      this.isload = true;
    },
    buildUrl(redirectUrl, profilename) {
      var redirectTarget = redirectUrl + profilename.split(' ').join('');
      this.$router.push({
        name: 'ExternalLink',
        params: {
          redirectTarget: redirectTarget,
          fromTarget: 'Settings/Socialmedia',
        },
      });
    },
    imageUrl(src, lowres = false) {
      var baseUrl = process.env.VUE_APP_BASEURL;
      var endpoint = '/image/search/';
      var query = '?resolution=high';

      if (lowres) {
        query = '?resolution=low';
      }

      return baseUrl + endpoint + src + query;
    },
    closeModal(event) {
      if (
        event.target.className === 'wrapper' ||
        event.target.className === 'layout-main'
      ) {
        this.profileClickHide();
      }
    },
    startChat() {
      this.requestInbox(this.profile.data.profile_id);
      this.profileClickHide();
    },
  },
  watch: {
    GET_CLICKSTATE_ACTIVE: function () {
      if (this.GET_CLICKSTATE_ACTIVE === true) {
        this.isload = false;
      }
    },
  },
};
</script>

<style scoped>
/* dialog transition */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}
.v-enter-from,
.v-leave-to {
  opacity: 0;
}

@keyframes skeleton-loading {
  0% {
    background-color: grey;
  }
  100% {
    background-color: var(--textbox-color);
    background-position: right -40px top 0;
  }
}

/* loading animation  */

.v-lazy-image {
  transition: opacity 0.5s, transform 0.5s;
  animation: skeleton-loading 1s linear infinite alternate;
}
.v-lazy-image-loaded {
  background-color: transparent;
}

/* Modal Wrapper */
.wrapper {
  display: grid;
  grid-template-rows: auto clamp(10rem, 10vw, 15rem);
  grid-template-areas:
    'layout-main'
    'layout-footer';
  align-items: center;
  color: white;
  /* width: 99vw; */
  min-height: 100%;
  min-width: 100%;
  background-color: var(--background-opacity-color);
  position: fixed;
  bottom: 0px;
  z-index: 99;
}

/* Seperate the wrapper in two rows,
   to make sure the content is always centered */
.layout-main {
  grid-area: layout-main;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.layout-footer {
  grid-area: layout-footer;
}

.position {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  aspect-ratio: 26 / 17;
  height: auto;
}
.top {
  word-break: break-all;
}

.social {
  display: flex;
  width: 90%;
  height: 15%;
  align-items: center;
}

.leftsocial {
  display: flex;
  width: 50%;
}
.rightsocial {
  display: flex;
  width: 50%;
  justify-content: flex-end;
}
.maindata {
  display: flex;
  width: 90%;
  height: 15%;
  font-size: clamp(12px, 1.6vw, 26px);
}
.name {
  display: flex;
  flex-direction: column;
  width: 90%;
}
.city {
  display: flex;
  justify-content: flex-end;
  width: 90%;
  height: 15%;
}
.link {
  display: flex;
  align-items: center;
  width: 90%;
  height: 15%;
}
.aboutme {
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 30%;
  font-size: clamp(8px, 1.1vw, 19px);
}
.abouthead {
  font-size: clamp(12px, 2vw, 25px);
}
.outcenter {
  display: flex;
  flex-direction: column;
  width: 60%;
  height: 30%;
}
.service {
  display: flex;
  justify-content: space-between;
  width: 90%;
  height: 25%;
}
.categorie {
  display: flex;
  flex-direction: column;
  width: auto;
  margin: 0 5px 0 0;
  font-size: clamp(15px, 1.3vw, 24px);
}
.paragraph {
  font-size: clamp(13px, 1vw, 19px);
}

/* Image */
.border {
  border-style: groove;
  border-width: 0.2em;
  border-color: #ed1c24;
  border-radius: 1.5rem;
}

.opacity {
  aspect-ratio: 26 / 17;
  height: auto;
  z-index: -1;
  background-color: black;
  opacity: 0.7;
  animation: rotate 4s linear infinite;
}
.imageclick {
  /* position: absolute; */
  aspect-ratio: 26 / 17;
  height: auto;
  z-index: -1;
}
.imageclickmid {
  display: none;
  /* position: absolute; */
  aspect-ratio: 3 / 4;
  height: auto;
  z-index: -1;
}
.imageclicksmall {
  display: none;
  position: absolute;
  aspect-ratio: 2 / 3;
  height: auto;
  z-index: -1;
}

/*
Small = 1/1
Medium = 3/4
Large = 2/3
Click =26/17
*/
@media only screen and (max-width: 1320px) {
  .position {
    width: 80%;
    top: 42%;
  }
}

@media only screen and (max-width: 1100px) {
  .position {
    width: 85%;
    top: 40%;
  }
}

@media only screen and (max-width: 700px) {
  .position {
    aspect-ratio: 3 / 4;
    width: 70%;
    top: 40%;
    width: 70%;
  }
  .abouthead {
    font-size: clamp(13px, 3.2vw, 28px);
  }
  .aboutme {
    font-size: clamp(8px, 2.7vw, 24px);
  }
  .maindata {
    font-size: clamp(20px, 3.8vw, 24px);
  }
  .categorie {
    font-size: clamp(13px, 2.5vw, 50px);
  }
  .paragraph {
    font-size: clamp(11px, 2.2vw, 50px);
  }
  .imageclick {
    display: none;
  }
  .imageclickmid {
    display: block;
  }
  .border {
    border-width: 0.2em;
  }
}
@media only screen and (max-width: 560px) {
  .position {
    aspect-ratio: 2 / 3;
    width: 80%;
    top: 50%;
  }
  .layout-main {
    position: relative;
  }
  .service {
    flex-wrap: wrap;
  }
  .abouthead {
    font-size: clamp(13px, 3.7vw, 21px);
  }
  .aboutme {
    font-size: clamp(10px, 2.7vw, 14px);
  }
  .categorie {
    font-size: clamp(13px, 2.7vw, 50px);
  }
  .paragraph {
    font-size: clamp(10px, 2.7vw, 18px);
  }
  .maindata {
    font-size: clamp(12px, 3.8vw, 20px);
  }
  .imageclickmid {
    display: none;
  }
  .imageclicksmall {
    display: block;
  }
  .outcenter {
    width: 100%;
    height: 30%;
  }
}
</style>
