<template>
  <div ref="popup" v-if="ChatMessagesStatus" class="chatmessages">
    <p class="head">Nachrichten</p>
    <div class="redline"></div>
    <div class="bg">
      <div class="chat">
        <div class="contact bar">
          <Transition>
            <VLazyImage
              @click="profileClickActive(activeInbox.profile_id)"
              class="pic"
              :src="this.url + activeInbox.imagesrc_small"
              :src-placeholder="this.url + this.image + '?resolution=low'"
              alt="Kein Bild"
            />
          </Transition>
          <div class="wrapper">
            <div class="name">
              <span>{{ activeInbox.profile_nickname }}</span>
            </div>
            <div class="buttons">
              <span @click="ignore" class="material-icons-outlined">person_off</span>
              <span
                @click="toggleWindow"
                v-if="this.isMobile"
                class="ui-icon material-icons-outlined"
              >
                arrow_back
              </span>
            </div>
          </div>
        </div>
        <div @scroll="fetchOldMessages" class="messages" id="chat">
          <TransitionGroup name="list">
            <div
              v-for="(message, index) in messages"
              :key="message.message_id"
              class="message-container"
            >
              <div class="time">
                {{ message.message_created_fmt }}
              </div>
              <div
                :class="{
                  me: message.message_appuser_id === user,
                  notme: message.message_appuser_id !== user,
                }"
                class="message"
              >
                {{ message.message_msg }}
              </div>
              <p
                v-if="index + 1 == this.messages.length"
                ref="lastmsg"
                class="message__textbox__lastmsg"
              ></p>
            </div>
          </TransitionGroup>
          <!-- <div class="message stark">
            <div class="typing typing-1"></div>
            <div class="typing typing-2"></div>
            <div class="typing typing-3"></div>
          </div> -->
        </div>
        <div class="input">
          <input
            v-model="msg"
            @click="scrollToPopup"
            @focus="scrollToPopup"
            @keypress.enter="
              sendMessage({
                inbox_id: activeInbox.inbox_id,
                msg: msg,
              })
            "
            placeholder="Schreibe deine Nachricht hier"
            type="text"
          />
          <p
            :class="{
              error: messageLength > 500,
            }"
            class="message-length"
          >
            {{ messageLength }} / 500
          </p>
          <i
            @click="
              sendMessage({
                inbox_id: activeInbox.inbox_id,
                msg: msg,
              })
            "
            class="fas fa-paper-plane"
          ></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import VLazyImage from 'v-lazy-image';
export default {
  components: {
    VLazyImage,
  },
  data() {
    return {
      msg: '',
      url: process.env.VUE_APP_BASEURL + '/image/search/',
      scrolltimer: '',
    };
  },
  computed: {
    ...mapGetters('modal', ['ChatMessagesStatus']),
    ...mapGetters({
      messages: 'chat/GET_MESSAGES',
      activeInbox: 'chat/GET_ACTIVEINBOX',
      ChatOnline: 'auth/SocketStatus',
    }),
    ...mapGetters({
      user: 'auth/GET_USER',
    }),
    ...mapGetters('modal', ['isMobile']),
    messagesLength() {
      return this.messages.length;
    },
    messageLength() {
      return this.msg.length;
    },
  },
  methods: {
    ...mapActions('chat', ['sendMsg', 'ignoreProfile', 'fetchMoreMessages']),
    ...mapActions('auth', ['loginRefresh', 'updateLoadingDone', 'SocketLogin']),
    ...mapActions('search', ['toggleProfileIgnore', 'profileClickActive']),
    ...mapActions('modal', [
      'hideChatMessages',
      'hideChatInbox',
      'showChatInbox',
      'initSubmitConfirmModal',
      'showSubmitConfirmModal',
      'showSubmitModal',
      'MobileBackChat',
    ]),
    async ignore() {
      try {
        await this.initSubmitConfirmModal({
          active: true,
          text: 'Wenn du den Benutzer ignorierst, kannst du keine Nachrichten von ihm empfangen. Möchtest du fortfahren?',
        });
        await this.ignoreProfile(this.activeInbox.profile_id);
        this.showSubmitConfirmModal(false);
        this.showSubmitModal({
          status: 'info',
          time: 5000,
          text: this.activeInbox.profile_nickname + ' ' + 'wird nun ignoriert!',
        });
        this.hideChatMessages();
        return;
      } catch (error) {
        this.showSubmitConfirmModal(false);
        if (typeof error == 'boolean') return;
        return Promise.reject(error);
      }
    },
    sendMessage(payload) {
      if (this.msg === '') {
        return;
      }

      if (this.messageLength > 500) {
        this.showSubmitModal({
          status: 'error',
          time: 5000,
          text: 'Deine Nachricht darf maximal 100 Zeichen beinhalten',
        });
        return;
      }
      this.sendMsg(payload);
      this.scroll(200);
      this.msg = '';
    },
    scrollToLastMsg() {
      const el = this.$refs.lastmsg;
      if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
      }
    },
    scrollToPopup() {
      const el = this.$refs.lastmsg;
      if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
      }
    },
    scroll(timer) {
      clearTimeout(this.scrolltimer);
      this.scrolltimer = setTimeout(() => this.scrollToLastMsg(), timer);
    },
    fetchOldMessages(event) {
      if (event.currentTarget.scrollTop === 0) {
        this.fetchMoreMessages(this.activeInbox.inbox_id);
      }
    },
    toggleWindow() {
      this.hideChatMessages();
      this.showChatInbox();
    },
  },
  watch: {
    activeInbox(InboxChanged) {
      if (InboxChanged) {
        // this.scrollToPopup();
        this.scroll(500);
      }
    },
    messagesLength(ChangedLength) {
      if (ChangedLength) {
        // this.scrollToPopup();
        this.scroll(100);
      }
    },
    deep: true,
  },
};
</script>
<style scoped>
/* dialog transition */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}
.v-enter-from {
  opacity: 1;
}
.v-leave-to {
  opacity: 0;
}

.list-move, /* apply transition to moving elements */
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  /* transform: translateX(30px); */
}

.chatmessages {
  display: flex;
  position: fixed;
  bottom: clamp(10rem, 10vw, 15rem);
  right: 23rem;
  align-items: center;
  flex-direction: column;
  color: var(--font-color);
  width: 40rem;
  height: 40rem;
  backdrop-filter: blur(12px);
  background-color: var(--nav-bg);
  z-index: 99;
}
.bg {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  color: var(--font-color);
  width: 95%;
  height: 90%;
  background-color: var(--background-index-color);
  padding: 0.3rem 0 0 0;
  margin-top: 0.3rem;
  border-radius: 1rem;
  /* overflow: scroll; */
}
.head {
  padding: 0.5rem 0;
  font-size: 20px;
}
.redline {
  border-bottom: red 1px solid;
  width: 95%;
}

.center {
  position: absolute;
  top: 50%;
  left: calc(50% + 12rem);
  transform: translate(-50%, -50%);
}

.pic {
  width: 4rem;
  height: 4rem;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  cursor: pointer;
}

.contact {
  position: relative;
  margin-bottom: 1rem;
  padding-left: 5rem;
  height: 4.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.contact .pic {
  position: absolute;
  left: 0;
}

.contact > .wrapper {
  display: flex;
  height: 100%;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  font-size: clamp(20px, 1.6vw, 40px);
}

.buttons span {
  font-size: 30px;
}

.buttons > .button {
  cursor: pointer;
}

.buttons > .button:hover {
  color: #ed1c24;
}

.contact .name {
  font-weight: 500;
  margin-bottom: 0.125rem;
  display: flex;
  align-items: center;
}
.contact .message,
.contact .seen {
  font-size: 0.9rem;
  color: black;
}
.contact .badge {
  box-sizing: border-box;
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  text-align: center;
  font-size: 0.9rem;
  padding-top: 0.125rem;
  border-radius: 1rem;
  top: 0;
  left: 2.5rem;
  background: #333;
  color: white;
}

.contacts {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-6rem, -50%);
  width: 24rem;
  height: 32rem;
  padding: 1rem 2rem 1rem 1rem;
  box-sizing: border-box;
  border-radius: 1rem 0 0 1rem;
  cursor: pointer;
  background: white;
  box-shadow: 0 0 8rem 0 rgba(0, 0, 0, 0.1), 2rem 2rem 4rem -3rem rgba(0, 0, 0, 0.5);
  transition: transform 500ms;
}
.contacts h2 {
  margin: 0.5rem 0 1.5rem 5rem;
}
.contacts .fa-bars {
  position: absolute;
  left: 2.25rem;
  color: black;
  transition: color 200ms;
}
.contacts .fa-bars:hover {
  color: #666;
}
.contacts .contact:last-child {
  margin: 0;
}
.contacts:hover {
  transform: translate(-23rem, -50%);
}

.chat {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 95%;
  margin-top: 0.3rem;
  z-index: 2;
  box-sizing: border-box;
  /* border-radius: 1rem; */
  /* background: var(--background-index-color); */
  /* background-color: var(--chat-bg); */
  /* box-shadow: 0 0 8rem 0 rgba(0, 0, 0, 0.1), 0rem 2rem 4rem -3rem rgba(0, 0, 0, 0.5); */
}
.chat .contact.bar {
  flex-basis: 3.5rem;
  flex-shrink: 0;
  margin: 1rem;
  box-sizing: border-box;
}
.chat .messages {
  padding: 1rem;
  /* background: #f7f7f7; */
  flex-shrink: 2;
  overflow-y: auto;
  box-shadow: inset 0 2rem 2rem -2rem rgba(0, 0, 0, 0.05),
    inset 0 -2rem 2rem -2rem rgba(0, 0, 0, 0.05);
}
.chat .messages .time {
  font-size: 0.8rem;
  background: #eee;
  padding: 0.25rem 1rem;
  border-radius: 2rem;
  color: black;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
}
.chat .messages .message {
  box-sizing: border-box;
  padding: 0.5rem 1rem;
  margin: 1rem;
  background: #fff;
  border-radius: 1.125rem 1.125rem 1.125rem 0;
  min-height: 2.25rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  word-wrap: anywhere;
  max-width: 66%;
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.075), 0rem 1rem 1rem -1rem rgba(0, 0, 0, 0.1);
}
.chat .messages .message.me {
  margin: 1rem 1rem 1rem auto;
  border-radius: 1.125rem 1.125rem 0 1.125rem;
  background: #333;
  color: white;
}

.message-length {
  margin-right: 1rem;
}

.error {
  color: red;
}

.notme {
  color: #333;
}

.chat .messages .message .typing {
  display: inline-block;
  width: 0.8rem;
  height: 0.8rem;
  margin-right: 0rem;
  box-sizing: border-box;
  background: #ccc;
  border-radius: 50%;
}
.chat .messages .message .typing.typing-1 {
  -webkit-animation: typing 3s infinite;
  animation: typing 3s infinite;
}
.chat .messages .message .typing.typing-2 {
  -webkit-animation: typing 3s 250ms infinite;
  animation: typing 3s 250ms infinite;
}
.chat .messages .message .typing.typing-3 {
  -webkit-animation: typing 3s 500ms infinite;
  animation: typing 3s 500ms infinite;
}
.chat .input {
  box-sizing: border-box;
  flex-basis: 4rem;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  padding: 0 0.5rem 0 1.5rem;
}
.chat .input i {
  font-size: 1.5rem;
  margin-right: 1rem;
  color: #666;
  cursor: pointer;
  transition: color 200ms;
}
.chat .input i:hover {
  color: #333;
}
.chat .input input {
  border: none;
  background-image: none;
  background-color: white;
  padding: 0.5rem 1rem;
  margin-right: 1rem;
  border-radius: 1.125rem;
  flex-grow: 2;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1), 0rem 1rem 1rem -1rem rgba(0, 0, 0, 0.2);
  font-family: Red hat Display, sans-serif;
  font-weight: 400;
  letter-spacing: 0.025em;
}
.chat .input input:placeholder {
  color: black;
}

input {
  color: black;
}

@-webkit-keyframes typing {
  0%,
  75%,
  100% {
    transform: translate(0, 0.25rem) scale(0.9);
    opacity: 0.5;
  }
  25% {
    transform: translate(0, -0.25rem) scale(1);
    opacity: 1;
  }
}

@keyframes typing {
  0%,
  75%,
  100% {
    transform: translate(0, 0.25rem) scale(0.9);
    opacity: 0.5;
  }
  25% {
    transform: translate(0, -0.25rem) scale(1);
    opacity: 1;
  }
}

.pic.banner {
  background-image: url('https://vignette.wikia.nocookie.net/marvelcinematicuniverse/images/4/4f/BruceHulk-Endgame-TravelingCapInPast.jpg');
}

@media only screen and (max-width: 1330px) {
  .chatmessages {
    width: 100%;
    left: 50%;
    top: 46%;
    width: 100%;
  }
  .buttons {
    justify-content: space-between;
  }
  .button.fas.fa-ban {
    margin-left: 1rem;
  }
}

@media only screen and (max-width: 1330px) {
  .bg {
    width: 95%;
    height: 90%;
    padding: 0.3rem 0 0 0;
  }
  .chatmessages {
    width: 100%;
    height: 100%;
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    transform: 0;
    position: fixed;
    z-index: 1;
  }
}

@media only screen and (max-width: 500px) {
  .chat .input input {
    margin-right: 0.1rem;
    flex-grow: 0;
    margin-right: 0.5rem;
  }
  .chat .input {
    padding: 0;
  }
  .head {
    padding: 0.5rem 0;
    font-size: 20px;
  }
  .message-length {
    display: none;
  }
  .contact .name {
    margin-right: 0.5rem;
  }

  .fas.fa-paper-plane {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
</style>
