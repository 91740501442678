<template>
  <router-view />
</template>

<script>
export default {
  methods: {
    async leaving() {
      try {
        await this.$API.get('/auth/browserclosed');
      } catch (error) {
        console.log('Browser closed!');
      }
    },
  },
  created() {
    window.addEventListener('beforeunload', this.leaving);
  },
};
</script>
<style>
@font-face {
  font-family: 'Lato', sans-serif;
  src: url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&family=Montserrat:wght@100;200;300;400;500;600;700;800');
  font-display: optional;
}
* {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
}
.overflow-hidden {
  overflow: hidden;
}
@media only screen and (max-width: 1000px) {
  .overflow-hidden {
    overflow: hidden;
    margin-right: 0px;
  }
}
::-webkit-scrollbar-corner {
  background-color: transparent;
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-thumb {
  background: rgb(104, 104, 104);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
html {
  width: 100%;
  height: 100%;
  background-color: var(--background-index-color);
}

/*- Darkmode -*/
:root {
  --background-index-color: #f7f7f8;
  --background-opacity-color: rgba(247, 247, 248, 0.4);
  --background-view-opacity-color: rgba(247, 247, 248, 0.7);
  --background-color: #ededef;
  --box-color: #dedee1;
  --textbox-color: #cccccc;
  --font-color: black;
  --img-color: #b8b8b8;
  --settings-box: #ffffff;
  --settings-input: #ececec;
  --chat-bg: rgba(237, 237, 239, 0.9);
  --nav-bg: rgba(237, 237, 239, 0.9);
}

[data-theme='darkMode'] {
  --background-index-color: #0e0e10;
  --background-opacity-color: rgba(14, 14, 16, 0.4);
  --background-view-opacity-color: rgba(14, 14, 16, 0.7);
  --background-color: #18181b;
  --box-color: #1f1f23;
  --textbox-color: #333235;
  --font-color: white;
  --img-color: #949494;
  --settings-box: #18181b;
  --settings-input: #3a3a3d;
  --chat-bg: rgba(45, 45, 48, 0.685);
  --nav-bg: rgba(24, 24, 27, 0.9);
}
</style>
