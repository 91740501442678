<template>
  <div class="ml-3 mr-3 w-full p-1 xl:ml-10 xl:mr-10">
    <button
      :id="title"
      :data-dropdown-toggle="title + 'Toggle'"
      class="focus:outline-none custom-bg-red custom-font inline-flex w-full items-center justify-between rounded-lg px-4 py-2.5 text-center text-sm font-medium text-white hover:bg-red-800 focus:ring-4 focus:ring-red-800 dark:bg-red-200 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
      type="button"
    >
      {{ this.title }}
      <svg
        class="ml-2 h-4 w-4"
        aria-hidden="true"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M19 9l-7 7-7-7"
        ></path>
      </svg>
    </button>

    <!-- Dropdown menu -->
    <div
      :id="title + 'Toggle'"
      class="custom-bg z-10 hidden w-3/4 divide-y divide-gray-100 rounded-lg shadow dark:divide-gray-600 dark:bg-gray-700"
    >
      <ul
        class="space-y-1 p-3 text-sm text-gray-700 dark:text-gray-200"
        :aria-labelledby="title"
      >
        <li
          class="custom-hover-bg"
          v-for="option in options"
          :key="String(option.category_id + option.checked)"
        >
          <div class="flex rounded p-2">
            <label class="relative inline-flex w-full cursor-pointer items-center">
              <input
                v-model="option.checked"
                :value="option.checked"
                @click.prevent="handleClick(option)"
                type="checkbox"
                class="peer sr-only"
              />
              <div
                class="peer-focus:outline-none peer h-5 w-9 rounded-full after:absolute after:left-[2px] after:top-[2px] after:h-4 after:w-4 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-red-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800"
              ></div>
              <span class="custom-font ml-3 text-sm font-medium dark:text-gray-300">
                {{ option.category_name }}
              </span>
            </label>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { initDropdowns } from 'flowbite';
import { mapActions } from 'vuex';
export default {
  props: ['title', 'options', 'selectedOptions'],

  components: {},
  data() {
    return {
      foo: [],
      checkedOptions: [],
    };
  },
  computed: {},

  methods: {
    ...mapActions('modal', [
      'showSubmitConfirmModal',
      'initSubmitConfirmModal',
      'showSubmitModal',
    ]),
    isChecked(option) {
      return this.selectedOptions.some((o) => o.category_id === option.category_id);
    },
    handleClick(option) {
      const selected = this.isChecked(option);
      if (selected) {
        this.$emit('deselecting', option);
      } else {
        this.$emit('selecting', option);
      }
    },
  },
  mounted() {
    initDropdowns();
  },
};
</script>

<style scoped>
.custom-bg {
  background-color: var(--settings-input);
}

.custom-font {
  font-family: inherit;
  font-weight: inherit;
  color: var(--font-color);
  font-size: clamp(12px, 1.1rem, 50px);
}

.custom-hover-bg:hover {
  background-color: var(--settings-box);
}

.custom-bg-red {
  background-color: #ed1c24;
}
</style>
