import store from '../store/store';

var dimension = {
  //   small: {
  //     width: {
  //       num: 0.03,
  //       min: 30,
  //       max: 500,
  //     },
  //     height: {
  //       num: 0.03,
  //       min: 30,
  //       max: 500,
  //     },
  //   },
  small: {
    width: {
      num: 0.03,
      min: 30,
      max: 500,
    },
    profileSmall: {
      height: {
        num: 0.03,
        min: 30,
        max: 500,
      },
    },
    profileMedium: {
      height: {
        num: 0.03,
        min: 30,
        max: 500,
      },
    },
    profileLarge: {
      height: {
        num: 0.03,
        min: 30,
        max: 500,
      },
    },
  },
  medium: {
    width: {
      num: 0.06,
      min: 50,
      max: 700,
    },
    profileSmall: {
      height: {
        num: 0.06,
        min: 50,
        max: 700,
      },
    },
    profileMedium: {
      height: {
        num: 0.08,
        min: 66,
        max: 934,
      },
    },
    profileLarge: {
      height: {
        num: 0.09,
        min: 75,
        max: 1050,
      },
    },
  },
  large: {
    width: {
      num: 0.09,
      min: 75,
      max: 1050,
    },
    profileSmall: {
      height: {
        num: 0.09,
        min: 75,
        max: 1050,
      },
    },
    profileMedium: {
      height: {
        num: 0.12,
        min: 99,
        max: 1401,
      },
    },
    profileLarge: {
      height: {
        num: 0.135,
        min: 112,
        max: 1575,
      },
    },
  },
  xlarge: {
    width: {
      num: 0.12,
      min: 100,
      max: 1400,
    },
    profileSmall: {
      height: {
        num: 0.12,
        min: 100,
        max: 1400,
      },
    },
    profileMedium: {
      height: {
        num: 0.16,
        min: 132,
        max: 1868,
      },
    },
    profileLarge: {
      height: {
        num: 0.18,
        min: 150,
        max: 2100,
      },
    },
  },
  test: {
    width: {
      num: 0.12,
      min: 100,
      max: 1400,
    },
    profileSmall: {
      height: {
        num: 0.12,
        min: 100,
        max: 1400,
      },
    },
    profileMedium: {
      height: {
        num: 0.16,
        min: 132,
        max: 1868,
      },
    },
    profileLarge: {
      height: {
        num: 0.24,
        min: 200,
        max: 2800,
      },
    },
  },
};
// Helper
export function chooseImageObj(responseLength) {
  if (responseLength <= 30) {
    dimension.xlarge['name'] = 'xlarge';
    return dimension.xlarge;
  }

  if (responseLength <= 60) {
    dimension.large['name'] = 'large';
    return dimension.large;
  }

  if (responseLength <= 90) {
    dimension.medium['name'] = 'medium';
    return dimension.medium;
  }

  // Fallback
  if (store.getters['modal/isMobile']) {
    dimension.medium['name'] = 'medium';
    return dimension.medium;
  }
  dimension.small['name'] = 'small';
  return dimension.small;
}

export function ResponsiveSize({ min, num, max }) {
  // clamp(<min-value>, <preferred-value>, <max-value>)
  // <min-value>: This is the smallest value the element can have.
  //              If the available space is less than the min-value, the element will be scaled down to the min-value.

  // <preferred-value>: This is the preferred value for the element.
  //                    If the available space is between the min-value and the max-value, the element will be scaled to the preferred-value.
  // <max-value>: This is the largest value the element can have.
  //              If the available space is greater than the max-value, the element will be scaled down to the max-value.

  const clamp = (num, min, max) => Math.min(Math.max(num, min), max);
  return {
    value: clamp(window.innerWidth * num, min, max),
    css: clamp(window.innerWidth * num, min, max).toString() + 'px',
  };
}

export function createVerticalGrid(containerWidth, contentWidth, contentMargin) {
  console.table(containerWidth, contentWidth, contentMargin);
  let columns = Math.floor(containerWidth / (contentWidth + contentMargin));
  let columnWidth = Math.floor(containerWidth / columns);

  return {
    columns: columns,
    columnWidth: columnWidth,
    css: columnWidth.toString() + 'px',
  };
}

// create layout

export function createLayout(profileCount, containerWidth, contentMargin) {
  // Choose image obj depending on api response length
  var imageObj = chooseImageObj(profileCount);

  // Calculate responsive WIDTH of one profile box
  var width = ResponsiveSize(imageObj.width);

  // Calculate responsive HEIGHT of one profile box
  // For now we suppport 3 different Aspect Ratios small, medium, large
  var height = {
    small: ResponsiveSize(imageObj.profileSmall.height),
    medium: ResponsiveSize(imageObj.profileMedium.height),
    large: ResponsiveSize(imageObj.profileLarge.height),
  };

  // Create VerticalGrid
  var grid = createVerticalGrid(containerWidth, width.value, contentMargin);

  return {
    imageObj: imageObj,
    width: width,
    height: height,
    grid: grid,
  };
}
