<template>
  <!-- Modal -->
  <BasicModal
    class=""
    :title="'Partner'"
    :view="AffliateModalStatus"
    :width="'65rem'"
    :height="'75vh'"
  >
    <p class="mb-3 text-gray-500 dark:text-gray-400">
      Track work across the enterprise through an open, collaborative platform. Link
      issues across Jira and ingest data from other software development tools, so your IT
      support and operations teams have richer contextual information to rapidly respond
      to requests, incidents, and changes.
    </p>
    <p class="text-gray-500 dark:text-gray-400">
      Deliver great service experiences fast - without the complexity of traditional ITSM
      solutions.Accelerate critical development work, eliminate toil, and deploy changes
      with ease, with a complete audit trail for every change.
    </p>
  </BasicModal>
</template>

<script>
import '@/assets/css/tailwind.css';
import BasicModal from '@/components/Ui/BasicModal.vue';
import { mapActions, mapGetters } from 'vuex';
export default {
  components: {
    BasicModal,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters('modal', ['AffliateModalStatus']),
  },
  methods: {
    ...mapActions('modal', ['hideAllModals']),
  },
  mounted() {},
};
</script>

<style scoped>
.custom {
  border-color: grey;
  background-color: var(--settings-box);
  color: var(--font-color);
}

.payment-pending {
  color: var(--font-color);
}
</style>
