<template>
  <BasicModal
    @currentTab="handleTab"
    @save="handleSave"
    :withNav="true"
    :withSave="withSave"
    :withPreview="withPreview"
    :tabData="tabObj"
    :title="'Einstellungen'"
    :view="OptionsModalStatus"
  >
    <TabWrapper :tabData="tabObj" :formData="formObj"></TabWrapper>
  </BasicModal>
</template>

<script>
//Components
import BasicModal from '@/components/Ui/BasicModal.vue';
import TabWrapper from '@/components/Settings/TabWrapper.vue';

// Helper
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
export default {
  components: {
    BasicModal,
    TabWrapper,
  },
  data() {
    return {
      //tabs
      tabObj: {
        currentTab: {
          component: 'Basic',
          name: 'Allgemein',
        },
        tabs: [
          {
            component: 'Basic',
            name: 'Allgemein',
          },
          {
            component: 'Socialmedia',
            name: 'Socialmedia',
          },
          {
            component: 'Categories',
            name: 'Dienstleistungen',
          },
          {
            component: 'Image',
            name: 'Foto',
          },
          {
            component: 'Private',
            name: 'Account',
          },
        ],
      },

      //form
      formObj: {
        Basic: {},
        Socialmedia: {},
        Categories: {},
        Private: {
          PrivateEmailForm: {},
          PrivatePasswordForm: {},
        },
      },

      //save
      withSave: true,
      withPreview: true,
    };
  },
  computed: {
    ...mapGetters('modal', ['OptionsModalStatus', 'OptionsActiveTab']),
  },
  methods: {
    // Helper Functions
    ...mapGetters('profile', [
      'GET_PROFILEBASIC',
      'GET_PROFILESOCIALMEDIA',
      'GET_PROFILEIMAGE',
      'GET_PROFILEPRIVATEMAIL',
      'GET_PROFILEPRIVATEPASSWORD',
      'GET_PROFILECATEGORIES',
    ]),
    ...mapActions('profile', ['ProfileLoadAction']),
    ...mapActions('modal', ['hideOptionsModal']),
    handleSave: function () {
      this.emitter.emit('saveForm', this.tabObj.currentTab.component);
    },
    handleTab: function (event) {
      this.tabObj.currentTab = event;
      if (event.name === 'Account' || event.name === 'Foto') {
        this.withSave = false;
      } else {
        this.withSave = true;
      }
    },
    createFormObj: function () {
      // Clone Profile Data from Store to build form objects
      this.formObj.Basic = _.cloneDeep(this.GET_PROFILEBASIC());
      this.formObj.Socialmedia = _.cloneDeep(this.GET_PROFILESOCIALMEDIA());
      this.formObj.Private.PrivateEmailForm = _.cloneDeep(this.GET_PROFILEPRIVATEMAIL());
      this.formObj.Private.PrivatePasswordForm = _.cloneDeep(
        this.GET_PROFILEPRIVATEPASSWORD()
      );
      this.formObj.Categories = _.cloneDeep(this.GET_PROFILECATEGORIES());
    },
  },
  beforeMount() {
    this.createFormObj();
  },
  watch: {
    OptionsModalStatus: function () {
      if (this.OptionsModalStatus === true) {
        this.createFormObj();
      } else {
        this.ProfileLoadAction();
      }
    },
  },
};
</script>

<style scoped></style>
