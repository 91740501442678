<template>
  <div class="inbox">
    <VLazyImage
      @click="showProfile"
      class="inbox__picture"
      :src="this.url + this.image"
      :src-placeholder="this.url + this.image + '?resolution=low'"
      alt="Kein Bild"
    />
    <div class="inbox__content">
      <p class="inbox__content__name">{{ nickname }}</p>
      <div class="inbox__content__actions">
        <span @click="deleteIgnore" class="button material-icons-outlined">close</span>
      </div>
    </div>
  </div>
</template>

<script>
import VLazyImage from "v-lazy-image";
// import SocialIcon from "@/components/Ui/SocialIcon.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    VLazyImage,
    // SocialIcon,
  },
  computed: {
    ...mapGetters("auth", ["SocketStatus"]),
    ...mapGetters("modal", ["MobileStatus"]),
  },
  data() {
    return {
      url: process.env.VUE_APP_BASEURL + "/image/search/",
    };
  },
  props: ["nickname", "image", "inbox", "profile_id"],
  methods: {
    ...mapActions("chat", [
      "sendMsg",
      "startChat",
      "fetchInboxes",
      "fetchIgnoredInboxes",
      "ignoreProfile",
    ]),
    ...mapActions("search", ["profileHoverData", "profileClickActive"]),
    ...mapActions("modal", [
      "showLoginModal",
      "explicitOptionsModal",
      "hideAllModals",
      "showSubmitModal",
    ]),
    async deleteIgnore() {
      await this.ignoreProfile(this.profile_id);
      await this.fetchIgnoredInboxes();
      this.showSubmitModal({
        status: "info",
        time: 5000,
        text: this.nickname + " " + "wird nicht mehr ignoriert!",
      });
    },
    showProfile() {
      this.$emit("showProfile", this.inbox.profile_id);
    },
  },
};
</script>

<style>
.inbox {
  display: flex;
  width: 100%;
  height: 5rem;
  min-height: 5rem;
  margin: 0.2rem 0;
  cursor: pointer;
  align-items: center;
  border-radius: 10px;
}
.inbox:hover {
  background-color: #ed1c24;
}
.inbox__picture {
  margin: 0 0 0 1vw;
  width: 3.5rem;
  height: 3.5rem;
  cursor: pointer;
  border-radius: 10px;
}
.inbox__content {
  width: 12rem;
  margin: 0 0 0 1vw;
  display: flex;
  overflow: hidden;
  cursor: pointer;
}
.inbox__content__name {
  width: 75%;
  padding: 0 0 0.2rem 0;
  font-size: 18px;
}
.inbox__content__actions {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
  padding: 0 0 0.2rem 0;
}

.inbox__content__actions > span {
}
</style>
