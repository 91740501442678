import { openModal, initDrawer } from '@/utils/flowbiteHelper.js';
import { appInstance } from '@/main.js';
export default {
  namespaced: true,
  state() {
    return {
      navigation: {
        dashboard: false,
        user: false,
        report: false,
        affliate: false,
        x1: false,
        x2: false,
        apperror: false,
      },
      userModal: {
        data: {},
        loading: false,
      },
      reportModal: {
        data: {},
        el: '',
      },
    };
  },
  mutations: {
    SET_NAVIGATIONACTIVE(state, key) {
      state.navigation[key] = true;
    },
    SET_NAVIGATIONINACTIVE(state, key) {
      state.navigation[key] = false;
    },
    SET_USERMODALDATA(state, data) {
      state.userModal.data = data;
    },
    SET_USERMODALLOADING(state, status) {
      state.userModal.loading = status;
    },
    SET_REPORTMODALDATA(state, data) {
      state.reportModal.data = data;
    },
    SET_REPORTMODALEL(state, el) {
      state.reportModal.el = el;
    },
  },
  getters: {
    NAVIGATION: (state) => {
      return state.navigation;
    },
    USERMODAL: (state) => {
      return state.userModal;
    },
    REPORTMODAL: (state) => {
      return state.reportModal;
    },
  },
  actions: {
    navigateTo(context, target) {
      var navigation = context.getters.NAVIGATION;
      // Set all other targets inactive
      for (const [key, value] of Object.entries(navigation)) {
        context.commit('SET_NAVIGATIONINACTIVE', key);
        console.log(`${key}: ${value}`);
      }
      // Set target active
      context.commit('SET_NAVIGATIONACTIVE', target);
    },
    async openUserModal(context, appuser_id) {
      context.commit('SET_USERMODALLOADING', true);
      openModal('#UserModal');
      let response = await this.$API.get('/admin/user/' + String(appuser_id));
      if (response.status === 200) {
        context.commit('SET_USERMODALDATA', response.data);
        context.commit('SET_USERMODALLOADING', false);
      }
    },

    initReportDrawer(context) {
      const options = {
        placement: 'right',
        backdrop: true,
        bodyScrolling: false,
        edge: false,
        edgeOffset: '',
        backdropClasses:
          'bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-30',
        onHide: () => {
          appInstance.config.globalProperties.emitter.emit('LoadReports');
          console.log('drawer is hidden');
        },
        onShow: () => {
          console.log('drawer is shown');
        },
        onToggle: () => {
          console.log('drawer toggled');
        },
      };
      context.commit('SET_REPORTMODALEL', initDrawer('#report-drawer', options));
    },

    async openReportDrawer(context, rep_id) {
      let response = await this.$API.get('/admin/report/' + String(rep_id));
      if (response.status === 200) {
        context.commit('SET_REPORTMODALDATA', response.data);
        var reportDrawer = context.getters.REPORTMODAL;
        reportDrawer.el.show();
      }
    },

    toggleReportDrawer(context) {
      var reportDrawer = context.getters.REPORTMODAL;
      reportDrawer.el.toggle();
    },
  },
};
