<template>
  <!-- Modal -->
  <BasicModal :title="'Einloggen'" :view="LoginModalStatus" :width="'65rem'">
    <!-- Form -->
    <div class="login-form">
      <div @keyup.enter="send">
        <!-- Email -->
        <FormField
          @inputError="this.form.email.error = $event"
          :id="'email'"
          :icon="'fas fa-envelope'"
          title="E-Mail"
          v-model="form.email.value"
          :value="this.form.email.value"
          :class="{ error: this.form.email.error }"
          :validations="validations.email"
          :error="this.form.email.error"
        />

        <!-- Password -->
        <FormField
          @inputError="this.form.password.error = $event"
          :id="'password'"
          :icon="'fas fa-key'"
          title="Passwort"
          type="password"
          v-model="form.password.value"
          :value="this.form.password.value"
          :class="{ error: this.form.password.error }"
          :validations="validations.password"
          :error="this.form.password.error"
        />

        <!-- Buttons -->
        <FormButtonContainer>
          <FormSubmit @click="send" :disabled="formHasErrors" :submitText="'Einloggen'" />
          <FormSubmit @click="Register" :submitText="'Neues Konto erstellen'" />
          <FormSubmit
            @click="this.PasswordForgot()"
            :submitText="'Passwort vergessen?'"
          />
        </FormButtonContainer>
      </div>
    </div>
  </BasicModal>
</template>

<script>
import FormField from '@/components/Form/FormField.vue';
import FormButtonContainer from '@/components/Form/FormButtonContainer.vue';
import BasicModal from '@/components/Ui/BasicModal.vue';
import FormSubmit from '@/components/Form/FormSubmit.vue';
import { mapActions, mapGetters } from 'vuex';
export default {
  components: {
    FormField,
    FormButtonContainer,
    FormSubmit,
    BasicModal,
  },
  props: {},
  data() {
    return {
      form: {
        email: {
          value: '',
          error: false,
        },
        password: {
          value: '',
          error: false,
        },
      },
      validations: {
        email: {
          email: true,
          required: true,
        },
        password: {
          required: true,
          minLength: {
            value: 3,
            msg: 'Ungültiges Passwort',
          },
        },
      },

      //modal
      servererror: '',
    };
  },
  computed: {
    ...mapGetters('modal', ['LoginModalStatus']),
    formHasErrors: function () {
      const formFields = [this.form.password.error, this.form.email.error];
      return formFields.some((element) => element === true);
    },
  },
  methods: {
    ...mapActions('auth', ['login']),
    ...mapActions('modal', [
      'hideLoginModal',
      'showRegisterModal',
      'showSubmitModal',
      'hideAllModals',
      'showPasswordForgot',
      'showPaymentModal',
    ]),
    ...mapActions('profile', ['ProfileLoadAction']),
    ...mapActions('search', ['search_action']),
    ...mapActions('chat', ['fetchInboxes']),
    PasswordForgot() {
      this.hideAllModals();
      this.showPasswordForgot();
    },
    Register() {
      this.hideAllModals();
      this.showRegisterModal();
    },
    async send() {
      // Check if form has validation errors
      this.emitter.emit('checkAll');
      if (this.formHasErrors === true) {
        this.showSubmitModal({
          status: 'error',
          time: 3000,
          text: 'Prüfe deine Eingabe!',
        });
        return;
      }

      // be kind an trim user email to match always
      let submitdata = {
        email: this.form.email.value.toLowerCase().trim(),
        password: this.form.password.value,
      };

      try {
        // start login action with auth module
        var status = await this.login(submitdata);
        // Payment is pending
        if (status == 202) {
          this.hideAllModals();
          this.showPaymentModal();
          return;
        }
        // Load Action after successfull login
        this.showSubmitModal({
          status: 'success',
          time: 2000,
          text: 'Login erfolgreich!',
        });
        this.ProfileLoadAction();
        this.search_action();
        this.fetchInboxes();
        this.hideAllModals();
        this.hideLoginModal();

        // Handle responses
      } catch (errorMessage) {
        switch (errorMessage) {
          case 'EMAIL_NOT_FOUND':
            this.servererror = 'E-Mail exisitiert nicht';
            break;
          case 'EMAIL_NOT_CONFIRMED':
            this.servererror = 'Bitte bestätige deine E-Mail Adresse';
            break;
          case 'WRONG_CREDENTIALS':
            this.servererror = 'Bitte Überprüfe dein Passwort oder deine E-Mail-Adresse';
            break;
          default:
            this.servererror =
              'Der Dienst ist aktuell nicht verfügbar. Bitte versuche es später nochmal';
            break;
        }
        if (errorMessage === 'PAYMENT REQUIRED') return;

        // Show Error Message
        this.showSubmitModal({
          status: 'error',
          time: 5000,
          text: this.servererror,
        });
      }
    },
  },
};
</script>

<style scoped>
.login-form {
  background-color: var(--settings-box);
  border: solid #707070 1px;
  width: 100%;
}
</style>
