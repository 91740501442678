<template>
  <div
    id="report-drawer"
    class="w-120 fixed z-40 h-screen overflow-y-auto bg-gray-800 p-4 transition-transform"
    aria-labelledby="drawer-js-label"
    tabindex="-1"
    aria-hidden="true"
  >
    <h5
      id="drawer-label"
      class="mb-6 inline-flex items-center text-sm font-semibold uppercase text-gray-400"
    >
      Meldung
    </h5>
    <button
      @click="toggleReportDrawer"
      type="button"
      id="report-drawer-close"
      data-drawer-dismiss="drawer-update-user-default"
      aria-controls="drawer-update-user-default"
      class="absolute right-2.5 top-2.5 inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600"
    >
      <svg
        aria-hidden="true"
        class="h-5 w-5"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
          clip-rule="evenodd"
        ></path>
      </svg>
      <span class="sr-only">Close menu</span>
    </button>
    <form class="">
      <!-- Reported User -->

      <div class="dark flex flex-col items-center pb-10">
        <VLazyImage
          v-if="REPORTMODAL.data.reported_imagesrc_small"
          class="mb-3 h-24 w-24 rounded-full shadow-lg"
          :src="
            'https://devel.kremer-marketing.de' +
            '/image/search/' +
            REPORTMODAL.data.reported_imagesrc_small +
            '?mode=tiny'
          "
          alt=""
        />
        <VLazyImage
          v-else
          class="mb-3 h-24 w-24 rounded-full shadow-lg"
          :src="'https://uxwing.com/wp-content/themes/uxwing/download/peoples-avatars/no-profile-picture-icon.png'"
          alt=""
        />
        <h5 class="mb-1 text-xl font-medium text-gray-900 dark:text-white">
          {{ REPORTMODAL.data.reported_profile_nickname }}
        </h5>
        <span class="text-sm text-gray-500 dark:text-gray-400">
          {{ REPORTMODAL.data.reported_appuser_email }}
        </span>
        <div class="mt-4 flex space-x-3 md:mt-6">
          <button
            @click="openUserModal(REPORTMODAL.data.reported_appuser_id)"
            id="editUserModal"
            type="button"
            class="focus:outline-none ml-4 inline-flex items-center rounded-lg bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            <svg
              aria-hidden="true"
              class="h-5 w-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
      </div>

      <div class="mb-4 grid gap-8 sm:grid-cols-2">
        <!-- Reason -->

        <div class="dark sm:col-span-2">
          <div>
            <label
              for="name"
              class="mb-2 block text-base font-semibold text-gray-900 dark:text-white"
            >
              Grund
            </label>
            <div class="text-base font-medium text-white">
              {{ REPORTMODAL.data.repreason_reason }}
            </div>
          </div>
        </div>

        <!-- Comment -->

        <div class="dark sm:col-span-2">
          <div>
            <label
              for="name"
              class="mb-2 block text-base font-semibold text-gray-900 dark:text-white"
            >
              Kommentar
            </label>
            <div class="text-base font-medium text-white">
              {{ REPORTMODAL.data.rep_comment }}
            </div>
          </div>
        </div>

        <!-- Reported by -->

        <div class="dark sm:col-span-2">
          <label
            for="name"
            class="mb-2 block text-base font-semibold text-gray-900 dark:text-white"
          >
            Gemeldet von:
          </label>
          <div
            class="flex items-center whitespace-nowrap px-2 py-4 text-gray-900 dark:text-white"
          >
            <VLazyImage
              v-if="REPORTMODAL.data.from_imagesrc_small"
              class="h-10 w-10 rounded-full"
              :src="
                'https://devel.kremer-marketing.de' +
                '/image/search/' +
                REPORTMODAL.data.from_imagesrc_small +
                '?mode=tiny'
              "
              alt=""
            />
            <VLazyImage
              v-else
              class="h-10 w-10 rounded-full"
              :src="'https://uxwing.com/wp-content/themes/uxwing/download/peoples-avatars/no-profile-picture-icon.png'"
              alt=""
            />
            <div class="pl-3">
              <div class="text-base font-semibold">
                {{ REPORTMODAL.data.from_profile_nickname }}
              </div>
              <div class="font-normal text-gray-500">
                {{ REPORTMODAL.data.from_appuser_email }}
              </div>
            </div>

            <button
              @click="openUserModal(REPORTMODAL.data.from_appuser_id)"
              id="editUserModal"
              type="button"
              class="focus:outline-none ml-4 inline-flex items-center rounded-lg bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              <svg
                aria-hidden="true"
                class="h-5 w-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
        </div>

        <!-- Buttons -->
        <div class="dark space-y-4 sm:col-span-2">
          <div class="mb-6 flex justify-center gap-2 sm:col-span-2">
            <button
              v-if="!REPORTMODAL.data.rep_done"
              @click="toggleStatus"
              type="button"
              class="focus:outline-none mb-2 mr-2 rounded-lg bg-green-700 px-5 py-2.5 text-sm font-medium text-white hover:bg-green-800 focus:ring-4 focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
            >
              Erledigt
            </button>
            <button
              v-if="REPORTMODAL.data.rep_done"
              @click="toggleStatus"
              type="button"
              class="focus:outline-none mb-2 mr-2 rounded-lg bg-red-700 px-5 py-2.5 text-sm font-medium text-white hover:bg-red-800 focus:ring-4 focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
            >
              Offen
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<style scoped></style>

<script>
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';
import VLazyImage from 'v-lazy-image';
export default {
  components: {
    VLazyImage,
  },

  data() {
    return {};
  },
  computed: {
    ...mapGetters('dashboard', ['REPORTMODAL']),
  },
  methods: {
    ...mapActions('dashboard', [
      'openReportModal',
      'openUserModal',
      'initReportDrawer',
      'toggleReportDrawer',
    ]),
    async toggleStatus() {
      var response = await this.$API.get(
        '/admin/report/status/' + String(this.REPORTMODAL.data.rep_id)
      );
      if (response.status === 200) {
        this.toggleReportDrawer();
      }
    },
  },
  mounted() {
    this.initReportDrawer();
  },
};
</script>
