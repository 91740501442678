<template>
  <div id="main-chart"></div>
</template>
<script>
import ApexCharts from 'apexcharts';
export default {
  data() {
    return {
      chartData: {},
      timer: '',
    };
  },
  methods: {
    async fetchData() {
      var response = await this.$API.get('/admin/dashboard/data/user');
      const data = response.data;
      const labels = data.map((entry) => entry.date);
      const values = data.map((entry) => entry.count);
      if (document.getElementById('main-chart')) {
        const chart = new ApexCharts(
          document.getElementById('main-chart'),
          this.getMainChartOptions(labels, values)
        );
        chart.render();
      }
    },
    getMainChartOptions(labels, values) {
      let mainChartColors = {};
      mainChartColors = {
        borderColor: '#374151',
        labelColor: '#9CA3AF',
        opacityFrom: 0,
        opacityTo: 0.15,
      };

      return {
        chart: {
          height: 420,
          type: 'area',
          fontFamily: 'Inter, sans-serif',
          foreColor: mainChartColors.labelColor,
          toolbar: {
            show: false,
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            enabled: true,
            opacityFrom: mainChartColors.opacityFrom,
            opacityTo: mainChartColors.opacityTo,
          },
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          style: {
            fontSize: '14px',
            fontFamily: 'Inter, sans-serif',
          },
        },
        grid: {
          show: true,
          borderColor: mainChartColors.borderColor,
          strokeDashArray: 1,
          padding: {
            left: 35,
            bottom: 15,
          },
        },
        series: [
          {
            name: 'User',
            data: values,
            color: '#1A56DB',
          },
          // {
          //   name: 'Revenue (previous period)',
          //   data: [6556, 6725, 6424, 6356, 6586, 6756, 6616],
          //   color: '#FDBA8C',
          // },
        ],
        markers: {
          size: 5,
          strokeColors: '#ffffff',
          hover: {
            size: undefined,
            sizeOffset: 3,
          },
        },
        xaxis: {
          categories: labels,
          labels: {
            style: {
              colors: [mainChartColors.labelColor],
              fontSize: '14px',
              fontWeight: 600,
            },
          },
          axisBorder: {
            color: mainChartColors.borderColor,
          },
          axisTicks: {
            color: mainChartColors.borderColor,
          },
          crosshairs: {
            show: true,
            position: 'back',
            stroke: {
              color: mainChartColors.borderColor,
              width: 1,
              dashArray: 10,
            },
          },
        },
        yaxis: {
          forceNiceScale: true,
          decimalsInFloat: 0,
          labels: {
            style: {
              colors: [mainChartColors.labelColor],
              fontSize: '14px',
              fontWeight: 500,
            },
            formatter: function (value) {
              return Math.round(value); // or parseInt(value)
            },
            // formatter: function (value) {
            //   return '$' + value;
            // },
          },
        },
        legend: {
          fontSize: '14px',
          fontWeight: 500,
          fontFamily: 'Inter, sans-serif',
          labels: {
            colors: [mainChartColors.labelColor],
          },
          itemMargin: {
            horizontal: 10,
          },
        },
        responsive: [
          {
            breakpoint: 1024,
            options: {
              xaxis: {
                labels: {
                  show: false,
                },
              },
            },
          },
        ],
      };
    },
  },
  mounted() {
    this.fetchData();
    this.timer = setInterval(() => {
      this.fetchData();
    }, 10000);
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
};
</script>

<style></style>
