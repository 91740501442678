<template>
  <div
    class="min-h-screen flex-col items-center justify-between rounded-lg border border-gray-700 bg-gray-800 p-4 shadow-sm sm:p-6"
  >
    <!-- Header -->
    <div class="mb-1 w-full">
      <div class="mb-4">
        <h1 class="text-xl font-semibold text-white sm:text-2xl">User</h1>
      </div>
    </div>
    <!-- Header -->

    <div class="dark w-full">
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
        <!-- Search -->
        <div class="flex items-center justify-start p-4 pb-4">
          <label for="table-search" class="sr-only">Search</label>
          <div class="relative">
            <div
              class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
            >
              <svg
                class="h-5 w-5 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div>
            <input
              v-model="current_search.value"
              @input="handleSearch"
              type="text"
              id="table-search-users"
              class="block w-80 rounded-lg border border-gray-300 bg-gray-50 p-2 pl-10 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
              placeholder="Suche"
            />
          </div>
          <SearchDropdown
            @changeSearch="this.current_search = $event"
            :avaliable_options="search_options"
            :current_search="current_search"
          />
        </div>
        <!-- Search -->
        <div class="w-full p-10">
          <div class="flex items-center justify-center p-2 pb-4">
            <Pagination
              @paginate="this.getData($event)"
              :total_pages="total_pages"
              :current_page="current_page"
            />
          </div>
        </div>

        <div id="accordion-collapse" data-accordion="collapse">
          <div v-for="(error, index) in data" :key="error.apperrorlog_id">
            <h2
              :id="'accordion-collapse-heading-' + String(index + 1)"
              @click="openAccordion(error.apperrorlog_id)"
              :ref="
                (el) => {
                  this.newAccordionItem(error.apperrorlog_id, 'triggerEl', el);
                }
              "
            >
              <button
                type="button"
                class="flex w-full items-center justify-between rounded-t-xl border border-b-0 border-gray-200 p-5 text-left font-medium text-gray-500 hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-800 dark:focus:ring-gray-800"
                :data-accordion-target="'#accordion-collapse-body-' + String(index + 1)"
                aria-expanded="false"
                :aria-controls="'accordion-collapse-body-' + String(index + 1)"
              >
                <div class="grid w-full grid-cols-2">
                  <div class="flex">
                    <div class="w-3/4">
                      <span class="">{{ error.apperrorlog_createtime }}</span>
                    </div>
                    <div class="w-1/4">
                      <span
                        class="text-lg"
                        :class="{ 'text-red-600': error.apperrorlog_code === '500' }"
                      >
                        {{ error.apperrorlog_code }}
                      </span>
                    </div>
                  </div>

                  <span>{{ reduceErrorName(error.apperrorlog_name) }}</span>
                </div>
                <svg
                  data-accordion-icon
                  class="h-6 w-6 shrink-0"
                  :class="{ 'rotate-180': this.activeAccordion !== error.apperrorlog_id }"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </button>
            </h2>
            <div
              :id="'accordion-collapse-body-' + String(index + 1)"
              class="hidden"
              :aria-labelledby="'accordion-collapse-heading-' + String(index + 1)"
              :ref="
                (el) => {
                  this.newAccordionItem(error.apperrorlog_id, 'targetEl', el);
                }
              "
            >
              <div
                class="border border-b-0 border-gray-200 p-5 dark:border-gray-700 dark:bg-gray-900"
              >
                <p
                  v-html="error.apperrorlog_traceback"
                  class="mb-2 text-gray-500 dark:text-gray-400"
                ></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/Dashboard/Ui/Pagination.vue';
import SearchDropdown from '@/components/Dashboard/Ui/SearchDropdown.vue';
import { initAcordion } from '@/utils/flowbiteHelper.js';
import { mapActions } from 'vuex';
export default {
  components: {
    Pagination,
    SearchDropdown,
  },
  computed: {
    // accordionItems: function () {
    //   var el_list = [];
    //   for (let i = 0; i < this.data.length; i++) {
    //     el_list.push({
    //       id: 'accordion-collapse-heading-' + String(i + 1),
    //       triggerEl: '',
    //       targetEl: '',
    //       active: false,
    //     });
    //   }
    //   return el_list;
    // },
  },

  data() {
    return {
      data: [],
      total_pages: 0,
      current_page: 1,
      per_page: 20,
      total_count: 0,
      search_options: [
        {
          column: 'apperrorlog_code',
          label: 'Statuscode',
        },
      ],
      current_search: {
        value: '',
        column: 'apperrorlog_code',
        label: 'Statuscode',
      },
      accordionItems: [],
      accordion: '',
      activeAccordion: '',
    };
  },
  methods: {
    ...mapActions('dashboard', ['openUserModal']),
    async getData(page = 1) {
      this.current_page = page;
      var response = await this.$API.post('/admin/apperrorlog/all', {
        page: this.current_page,
        per_page: this.per_page,
        search_column: this.current_search.column,
        search_value: this.current_search.value,
      });
      if (response.status === 200) {
        this.data = response.data.results;
        (this.total_pages = response.data.pages),
          (this.total_count = response.data.total);
      }
    },
    async handleSearch() {
      if (
        this.current_search.value.length > 2 ||
        this.current_search.value.length === 0
      ) {
        await this.getData();
        return;
      }
    },
    newAccordionItem(id, key, value) {
      const i = this.accordionItems.findIndex((_element) => _element.id === id);
      if (i > -1) {
        this.accordionItems[i][key] = value;
        return;
      } else {
        this.accordionItems.push({
          id: id,
          triggerEl: '',
          targetEl: '',
          active: false,
        });

        this.newAccordionItem(id, key, value);
      }
    },
    openAccordion(id) {
      if (this.activeAccordion === id) {
        this.activeAccordion = {};
        this.accordion.close(id);
        return;
      }
      this.activeAccordion = id;
      this.accordion.open(id);
    },

    reduceErrorName(txt) {
      if (!txt) return;
      if (txt.length > 80) {
        return txt.substring(0, 80) + '...';
      } else {
        return txt;
      }
    },
  },
  async mounted() {
    this.getData();
    this.$nextTick(() => {
      this.accordion = initAcordion(this.accordionItems);
    });
  },
};
</script>

<style></style>
