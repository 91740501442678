<template>
  <div v-if="UploadProgress !== '0%'" class="progress-wrapper">
    <div class="progress-c">
      <div class="progress-bar" :style="{ width: UploadProgress }"></div>
    </div>
    <div class="logo">
      <img src="@/assets/logo.png" alt="logofooter" class="logofooter" />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="4051.183"
        height="498.308"
        viewBox="0 0 4051.183 498.308"
        class="textfooter"
      >
        <g id="layer1" transform="translate(-5.854 -13.535)">
          <g id="g844" transform="translate(-8.553)">
            <g id="text836">
              <path
                id="path833"
                d="M14.407,17.2H353.329l-188.7,390.829h188.7v97.707H14.407L204.936,114.907H14.407Z"
                fill="var(--font-color)"
              />
              <path
                id="path835"
                d="M420.5,101.472a86.853,86.853,0,0,1,6.717-34.2,85.533,85.533,0,0,1,18.32-27.48A87.686,87.686,0,0,1,473.63,20.864a84.757,84.757,0,0,1,34.808-7.328H679.427a80.611,80.611,0,0,1,34.2,7.328A80.964,80.964,0,0,1,741.1,39.794a80.964,80.964,0,0,1,18.931,27.48,80.61,80.61,0,0,1,7.328,34.2V423.906a84.757,84.757,0,0,1-7.328,34.808A87.685,87.685,0,0,1,741.1,486.8a85.531,85.531,0,0,1-27.48,18.32,86.854,86.854,0,0,1-34.2,6.717H508.438a91.369,91.369,0,0,1-34.808-6.717,81.4,81.4,0,0,1-46.411-46.411,91.369,91.369,0,0,1-6.717-34.808Zm97.707,303.5q0,9.16,9.16,9.16h131.9q10.381,0,10.381-9.16V120.4q0-9.16-10.381-9.16h-131.9q-9.16,0-9.16,9.16Z"
                fill="var(--font-color)"
              />
              <path
                id="path837"
                d="M858.962,101.472a86.854,86.854,0,0,1,6.717-34.2A85.534,85.534,0,0,1,884,39.794,87.686,87.686,0,0,1,912.09,20.864,84.757,84.757,0,0,1,946.9,13.535h170.988a80.611,80.611,0,0,1,34.2,7.328,80.963,80.963,0,0,1,27.48,18.931,80.963,80.963,0,0,1,18.931,27.48,80.612,80.612,0,0,1,7.328,34.2V423.906a84.759,84.759,0,0,1-7.328,34.808,87.684,87.684,0,0,1-18.931,28.091,85.529,85.529,0,0,1-27.48,18.32,86.854,86.854,0,0,1-34.2,6.717H946.9a91.369,91.369,0,0,1-34.808-6.717,81.4,81.4,0,0,1-46.411-46.411,91.369,91.369,0,0,1-6.717-34.808Zm97.707,303.5q0,9.16,9.16,9.16h131.905q10.381,0,10.381-9.16V120.4q0-9.16-10.381-9.16H965.829q-9.16,0-9.16,9.16Z"
                fill="var(--font-color)"
              />
              <path
                id="path839"
                d="M1541.08,319.481,1588.1,17.2h176.484l18.32,488.536H1685.2l-13.435-390.829h-5.5l-87.936,384.722h-74.5l-87.937-384.722h-4.885l-14.045,390.829h-97.707L1318.186,17.2h166.1Z"
                fill="var(--font-color)"
              />
              <path
                id="path841"
                d="M2122.44,339.634H1873.28v-97.1h249.16Z"
                fill="var(--font-color)"
              />
              <path
                id="path843"
                d="M2477.24,408.029h4.275V17.2h97.707V505.736H2414.341l-93.433-390.829h-3.053V505.736h-97.707V17.2H2385.64Z"
                fill="var(--font-color)"
              />
              <path
                id="path845"
                d="M2999.36,114.907H2786.847V205.9h196.025V303.6H2786.847V408.029H2999.36v97.707H2689.14V17.2h310.22Z"
                fill="var(--font-color)"
              />
              <path
                id="path847"
                d="M3151.41,408.639l-14.656,97.1h-97.707l74.5-467.773q1.221-7.328,8.549-14.045a26.741,26.741,0,0,1,17.709-6.717h207.017a24.509,24.509,0,0,1,17.1,6.717q7.939,6.107,9.16,14.045L3448.2,505.736h-97.707l-14.656-97.1ZM3293.7,128.952q-1.221-7.328-4.885-10.381a9.847,9.847,0,0,0-7.939-3.664H3206.98q-11.6,0-13.435,14.045l-27.48,181.98h155.11Z"
                fill="var(--font-color)"
              />
              <path
                id="path849"
                d="M3793.84,17.2a64.491,64.491,0,0,1,28.091,6.107,65.554,65.554,0,0,1,23.206,15.877,65.554,65.554,0,0,1,15.877,23.205,64.493,64.493,0,0,1,6.107,28.091v87.936a74.835,74.835,0,0,1-13.435,43.358,76.412,76.412,0,0,1-33.587,28.7,78.278,78.278,0,0,1,36.64,28.091q14.656,19.541,14.656,45.19v181.98h-97.707V323.756a58.123,58.123,0,0,0-1.832-15.267q-1.221-6.717-4.275-9.16H3619.188V505.735h-97.707V17.2Zm-35.42,184.422a44.954,44.954,0,0,0,7.328-9.16q3.053-4.275,3.053-12.824V122.846q0-9.771-7.939-9.771H3619.187v88.547Z"
                fill="var(--font-color)"
              />
              <path
                id="path851"
                d="M4065.59,379.938h-97.707V19.642h97.707Zm0,125.8h-97.707V441.005h97.707Z"
                fill="var(--font-color)"
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
  </div>

  <div v-if="UploadProgress === '0%'" class="container">
    <!-- btn -->
    <div class="btn-container">
      <i @click="close" class="fas fa-chevron-left"></i>
      <h1 class="progress">04/04</h1>
      <i @click="next" class="fas fa-chevron-right"></i>
    </div>

    <!-- info -->
    <div class="info-container">
      <i @click="info" class="fas fa-info-circle"></i>
    </div>

    <!-- crop zone -->
    <Transition>
      <div class="img-container">
        <img class="crop-image" ref="image" :src="ImagecropSrc" alt="No Image detected" />
      </div>
    </Transition>

    <!-- preview -->
    <div class="preview-container">
      <h1 class="preview-text">Vorschau:</h1>
      <div class="preview-content-wrapper">
        <div class="preview-static preview"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Cropper from 'cropperjs';
import dimension from '@/data/profileSize.js';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { destroyCropper } from '@/utils/imageCropper';
import 'cropperjs/dist/cropper.css';
export default {
  components: {},
  data() {
    return {
      cropper: '',
      image: {},
      btnDisabled: false,
      sizeobj: dimension,
      totalWidth: 0,
    };
  },
  mounted() {
    this.totalWidth = window.outerWidth;
    if (!this.ImagecropSrc) {
      this.$router.push({
        name: 'Home',
        params: {
          notification: JSON.stringify({
            status: 'error',
            time: 3000,
            text: 'Der Dienst ist aktuell nicht verfügbar. Bitte versuche es später nochmal',
          }),
        },
      });
      return;
    }
    this.cropper = new Cropper(this.$refs.image, {
      responsive: true,
      restore: true,
      preview: '.preview',
      checkCrossOrigin: true,
      checkOrientation: true,
      modal: false,
      guides: true,
      center: true,
      highlight: true,
      background: false,
      autoCrop: true,
      movable: true,
      rotatable: true,
      scalable: false,
      zoomable: false,
      zoomOnTouch: false,
      zoomOnWheel: false,
      cropBoxMovable: true,
      cropBoxResizable: true,
      toggleDragModeOnDblclick: true,
      aspectRatio: 26 / 17,
      crop: () => {},
      ready: () => {},
    });
  },
  computed: {
    ...mapGetters('modal', ['LoadingStatus']),
    ...mapGetters('profile', ['ImagecropSrc', 'GET_UPLOADPROGRESS']),
    UploadProgress: function () {
      return String(this.GET_UPLOADPROGRESS + '%');
    },
    previewBoxHeightSmall: function () {
      const clamp = (num, min, max) => Math.min(Math.max(num, min), max);
      // Calculates the Size of one Profile, respects responsivity and margin
      return (
        clamp(
          this.totalWidth * this.sizeobj.xlarge.profileSmall.height.num,
          this.sizeobj.xlarge.profileSmall.height.min,
          this.sizeobj.xlarge.profileSmall.height.max
        ).toString() + 'px'
      );
    },
    previewBoxHeightMedium: function () {
      const clamp = (num, min, max) => Math.min(Math.max(num, min), max);
      // Calculates the Size of one Profile, respects responsivity and margin
      return (
        clamp(
          this.totalWidth * this.sizeobj.xlarge.profileMedium.height.num,
          this.sizeobj.xlarge.profileMedium.height.min,
          this.sizeobj.xlarge.profileMedium.height.max
        ).toString() + 'px'
      );
    },
    previewBoxHeightLarge: function () {
      const clamp = (num, min, max) => Math.min(Math.max(num, min), max);
      // Calculates the Size of one Profile, respects responsivity and margin
      return (
        clamp(
          this.totalWidth * this.sizeobj.xlarge.profileLarge.height.num,
          this.sizeobj.xlarge.profileLarge.height.min,
          this.sizeobj.xlarge.profileLarge.height.max
        ).toString() + 'px'
      );
    },
    previewBoxWidth: function () {
      const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

      // Calculates the Size of one Profile, respects responsivity and margin
      return (
        clamp(
          this.totalWidth * this.sizeobj.xlarge.width.num,
          this.sizeobj.xlarge.width.min,
          this.sizeobj.xlarge.width.max
        ).toString() + 'px'
      );
    },
  },
  methods: {
    ...mapActions('profile', [
      'uploadProfileImage',
      'getProfileImage',
      'deleteProfileImage',
    ]),
    ...mapActions('modal', [
      'showSubmitModal',
      'showLoadingModal',
      'hideLoadingModal',
      'hideImagecropModal',
      'setImagecropSource',
    ]),
    ...mapGetters('profile', ['GET_PROFILEIMAGE', 'GET_IMAGECROPRES']),
    ...mapMutations('profile', ['SET_IMAGECROPRES']),

    async next() {
      if (this.btnDisabled) return;
      this.btnDisabled = true;

      const res = this.cropper.getData();
      const key = 'click';
      this.SET_IMAGECROPRES({ key, res });

      // Encode src imageurl to base 64 respect the correct padding here
      let encoded = this.ImagecropSrc.toString().replace(/^data:(.*,)?/, '');
      if (encoded.length % 4 > 0) {
        encoded += '='.repeat(4 - (encoded.length % 4));
      }

      // Get collected crop cordinates
      var data = this.GET_IMAGECROPRES();
      data['src'] = encoded;

      // Upload Action
      try {
        await this.uploadProfileImage(data);
        this.btnDisabled = false;
      } catch (errorMessage) {
        this.btnDisabled = false;
        switch (errorMessage) {
          case 'IMAGE_TO_LARGE':
            this.showSubmitModal({
              status: 'error',
              time: 3000,
              text: 'Das Bild darf 5MB nicht überschreiten',
            });
            break;
          default:
            this.showSubmitModal({
              status: 'error',
              time: 5000,
              text: 'Der Dienst ist aktuell nicht verfügbar. Bitte versuche es später nochmal',
            });
            break;
        }
      }
    },
    close() {
      destroyCropper(this.cropper);
      this.$router.push({
        name: 'ImageUpload-03',
      });
    },
    info() {
      this.showSubmitModal({
        status: 'info',
        time: 20000,
        text: 'Damit dein Profilbild immer korrekt dargestellt wird kannst du hier die perfekte Darstellung definieren',
      });
    },
  },
  watch: {
    // whenever question changes, this function will run
    UploadProgress(newValue) {
      if (newValue === '100%') {
        destroyCropper(this.cropper);
        this.$router.push({
          name: 'Home',
          params: {
            uploadDone: JSON.stringify({
              status: 'info',
              time: 3000,
              text: 'Upload abgeschlossen',
            }),
          },
        });
      }
    },
  },
};
</script>

<style scoped>
/* dialog transition */
.v-enter-active {
  transition: opacity 1.3s ease;
}
.v-leave-active {
  transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.root {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1vh;
}

.container {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 10% 5% 50% 28%;
  grid-template-areas:
    'button'
    'info'
    'cropper'
    'preview';
  align-items: center;
  justify-content: center;
  padding-top: 2vh;
  padding-bottom: 2vh;
  width: 100%;
  height: 90vh;
  gap: 1%;
}

.progress,
.preview-text {
  display: flex;
  justify-content: center;
  font-family: 'Montserrat', sans-serif;
  color: var(--font-color);
  font-size: clamp(18px, 1.5rem, 50px);
  margin: 0.5rem 0;
}

.btn-container {
  grid-area: button;
  width: 70vw;
  justify-content: space-between;
  display: flex;
  margin-bottom: 4vh;
  margin-top: 2vh;
}
.btn-container > i {
  font-size: 4rem;
  color: #ed1c24;
}
.info-container {
  grid-area: info;
  width: 70vw;
  justify-content: center;
  display: flex;
  margin-bottom: 4vh;
  margin-top: 2vh;
}

.info-container > i {
  font-size: 3rem;
  color: #ed1c24;
}
.img-container {
  grid-area: cropper;
  width: 70vw;
  height: 100%;
}

.img-container > img {
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

.preview-container {
  grid-area: preview;
  height: 100%;
}

.preview-content-wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 70vw;
}
.preview-static {
  aspect-ratio: 26 / 17;
  width: 100%;
  height: 100%;
  background: green;
  animation: skeleton-loading 2s linear infinite alternate;
}

.progress-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: var(--background-index-color);
}

.progress-c {
  height: 30px;
  width: 70%;
  background-color: #f2f2f2;
  border-radius: 5px;
  margin-bottom: 10px;
}

.logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 70%;
  height: clamp(10rem, 10vw, 15rem);
}
.logofooter {
  height: 3vw;
}

.progress-bar {
  height: 100%;
  background-color: #ed1c24;
  border-radius: 5px;
  transition: width 0.5s ease-in-out;
}

@keyframes skeleton-loading {
  0% {
    background-color: var(--box-color);
  }
  100% {
    background-color: var(--textbox-color);
  }
}

.preview {
  overflow: hidden;
  background: transparent;
}

.cropper-view-box {
  outline: 10px solid #ed1c24;
}
@media only screen and (max-width: 500px) {
  .btn-container > i {
    font-size: 2rem;
  }

  .preview-static {
    width: 70vw;
    height: 45.69629245627172vw;
  }
  /* .img-container {
    width: 50vw;
    height: 30vh;
  } */
}
</style>
