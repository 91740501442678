<template>
  <BasicModal
    :title="'Registrieren'"
    :width="'65rem'"
    :height="'75vh'"
    :view="RegisterModalStatus"
  >
    <!-- Form -->
    <div class="register-form">
      <div @keyup.enter="send">
        <!-- Nickname -->
        <FormField
          :id="'nickname'"
          title="Benutzername"
          @inputError="this.form.profile_nickname.error = $event"
          v-model="form.profile_nickname.value"
          :value="form.profile_nickname.value"
          :class="{ error: this.form.profile_nickname.error }"
          :validations="validations.profile_nickname"
          :error="this.form.profile_nickname.error"
        />
        <!-- Email -->
        <FormField
          :id="'email'"
          title="Email"
          @inputError="this.form.appuser_email.error = $event"
          v-model="form.appuser_email.value"
          :value="this.form.appuser_email.value"
          :class="{ error: this.form.appuser_email.error }"
          :validations="validations.appuser_email"
          :error="this.form.appuser_email.error"
        />
        <!-- Password -->
        <FormField
          :id="'password'"
          title="Passwort"
          type="password"
          @inputError="this.form.appuser_newpassword.error = $event"
          v-model="form.appuser_newpassword.value"
          :value="this.form.appuser_newpassword.value"
          :class="{ error: this.form.appuser_newpassword.error }"
          :validations="validations.appuser_newpassword"
          :error="this.form.appuser_newpassword.error"
        />
        <!-- Password confirm -->
        <FormField
          :id="'password_confirm'"
          title="Passwort wiederholen"
          type="password"
          @inputError="this.form.appuser_password_confirm.error = $event"
          v-model="form.appuser_password_confirm.value"
          :value="this.form.appuser_password_confirm.value"
          :class="{ error: this.form.appuser_password_confirm.error }"
          :validations="validations.appuser_password_confirm"
          :error="this.form.appuser_password_confirm.error"
        />
        <!-- Password confirm -->
        <FormField
          :id="'affliate'"
          title="Geworben durch"
          :titletext="'Du wurdest geworben? Trage hier den Benutzernamen ein'"
          @inputError="this.form.profile_affliate.error = $event"
          v-model="form.profile_affliate.value"
          :value="this.form.profile_affliate.value"
          :class="{ error: this.form.profile_affliate.error }"
          :validations="validations.profile_affliate"
          :error="this.form.profile_affliate.error"
        />
        <div class="terms">
          <div class="flex items-center">
            <input
              v-model="agb"
              id="link-checkbox"
              type="checkbox"
              class="ml-9 h-6 w-6 rounded border-gray-300 bg-gray-100 text-red-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
            />
            <label for="link-checkbox" class="ml-4 font-medium xl:ml-2">
              Durch die Nutzung unserer Dienstleistungen bzw. den Zugriff auf unsere
              Website/Plattform erkläre ich hiermit meine ausdrückliche Einwilligung zur
              Akzeptanz der
              <a @click="showAgbModal" class="terms-link text-blue-600 hover:underline">
                Allgemeinen Geschäftsbedingungen (AGB)
              </a>
            </label>
          </div>
        </div>

        <FormButtonContainer>
          <FormSubmit
            @click="send"
            :disabled="formHasErrors"
            :submitText="'Registrieren'"
          />
          <FormSubmit @click="back" :submitText="'Login'" />
        </FormButtonContainer>
      </div>
    </div>
  </BasicModal>
</template>

<script>
import FormField from '@/components/Form/FormField.vue';
import FormButtonContainer from '@/components/Form/FormButtonContainer.vue';
import FormSubmit from '@/components/Form/FormSubmit.vue';
import BasicModal from '@/components/Ui/BasicModal.vue';
import { mapActions, mapGetters } from 'vuex';
export default {
  components: {
    FormField,
    FormSubmit,
    FormButtonContainer,
    BasicModal,
  },
  data() {
    return {
      agb: false,
      form: {
        profile_nickname: {
          value: '',
          error: false,
        },
        appuser_email: {
          value: '',
          error: false,
        },
        appuser_newpassword: {
          value: '',
          error: false,
        },
        appuser_password_confirm: {
          value: '',
          error: false,
        },
        profile_affliate: {
          value: '',
          id: '',
          error: false,
        },
      },
      validations: {
        profile_nickname: {
          maxLength: {
            value: 20,
            msg: false,
          },
          minLength: {
            value: 3,
            msg: false,
          },
          required: true,
          username: true,
        },
        appuser_email: {
          email: true,
          required: true,
        },
        appuser_newpassword: {
          required: true,
        },
        appuser_password_confirm: {
          required: true,
        },
        profile_affliate: {
          maxLength: {
            value: 20,
            msg: false,
          },
        },
      },

      //modal
      servererror: '',
    };
  },
  computed: {
    ...mapGetters('modal', [
      'LoginModalStatus',
      'RegisterModalStatus',
      'LoadingStatus',
      'PasswordForgot',
    ]),
    ...mapGetters('auth', ['AffliateUser']),
    formHasErrors: function () {
      const formFields = [
        this.form.profile_nickname.error,
        this.form.appuser_email.error,
        this.form.appuser_newpassword.error,
        this.form.appuser_password_confirm.error,
        this.form.profile_affliate.error,
      ];
      return formFields.some((element) => element === true);
    },
  },
  methods: {
    ...mapActions('auth', ['login']),
    ...mapActions('modal', [
      'showSubmitModal',
      'hideRegisterModal',
      'hideAllModals',
      'showAgbModal',
      'showLoginModal',
    ]),
    back() {
      this.hideAllModals();
      this.showLoginModal();
    },

    async send() {
      // Check if form fields have validation errors
      this.emitter.emit('checkAll');
      if (this.formHasErrors === true) {
        this.showSubmitModal({
          status: 'error',
          time: 3000,
          text: 'Prüfe deine Eingabe!',
        });
        return;
      }

      // Check if Password are equal
      if (
        this.form.appuser_newpassword.value !== this.form.appuser_password_confirm.value
      ) {
        this.form.appuser_password_confirm.error = true;
        this.form.appuser_newpassword.error = true;

        this.showSubmitModal({
          status: 'error',
          time: 5000,
          text: 'Passwörter stimmen nicht überein',
        });
        return;
      }

      if (!this.agb) {
        this.showSubmitModal({
          status: 'error',
          time: 5000,
          text: 'Um sich erfolgreich zu registrieren, musst du die Allgemeinen Geschäftsbedingungen (AGB) akzeptieren',
        });
        return;
      }

      // API Action

      try {
        await this.$API.post('/registration', {
          profile_nickname: this.form.profile_nickname.value,
          appuser_email: this.form.appuser_email.value.toLowerCase().trim(),
          appuser_newpassword: this.form.appuser_newpassword.value,
          profile_affliate: this.form.profile_affliate.value,
        });
        this.showSubmitModal({
          status: 'success',
          time: 5000,
          text: 'Bitte schaue in deinem Postfach nach der Bestätigungs E-Mail',
        });
        this.hideRegisterModal();
        this.showLoginModal();

        // Handle responses
      } catch (error) {
        switch (error.response.data.message) {
          case 'EMAIL_ALREADY_EXISTS':
            this.servererror = 'Die E-Mail Adresse ist bereits vergeben';
            this.form.appuser_email.error = true;
            break;
          case 'NICKNAME_ALREADY_EXISTS':
            this.servererror = 'Der Benutzername ist bereits vergeben';
            this.form.profile_nickname.error = true;
            break;
          default:
            this.servererror =
              'Der Dienst ist aktuell nicht verfügbar. Bitte versuche es später nochmal';
            break;
        }

        this.showSubmitModal({
          status: 'error',
          time: 5000,
          text: this.servererror,
        });
      }
    },
  },
  watch: {
    RegisterModalStatus(newValue) {
      if (newValue === true) {
        if (Object.keys(this.AffliateUser).length !== 0) {
          this.form.profile_affliate.value = this.AffliateUser['name'];
          this.form.profile_affliate.id = this.AffliateUser['id'];
        }
      }
    },
  },
};
</script>

<style scoped>
.register-form {
  background-color: var(--settings-box);
  border: solid #707070 1px;
  width: 100%;
}

.terms {
  display: grid;
  font-family: 'Lato', sans-serif;
  color: var(--font-color);
  padding: 1.5rem;
  align-items: center;
  grid-template-rows: auto;
  border-bottom: solid 1px grey;
  background-color: var(--settings-box);
  transition: width 0.5s ease;
}

.terms-link {
  color: red;
}
</style>
