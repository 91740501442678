import io from 'socket.io-client';
import axios from '../APIConfig';
import store from '../store/store';

const socket = io(process.env.VUE_APP_BASEURL, {
  autoConnect: true,
  auth: {},
  withCredentials: true,
  reconnection: true,
  reconnectionDelay: 3000,
});

// Add Abstraction to Instance
socket.secureEmit = secureEmit;

// Event handling
socket.on('connect', () => {
  console.log('connected'); // true
});

socket.on('error', (error) => {
  console.log(error);
});

socket.io.on('reconnect_attempt', (attempt) => {
  console.log(attempt);
});

// Todo: Add Toast Notifications here
socket.on('authorization', (status) => {
  if (status === 'UNAUTHORIZED') {
    refreshToken();
  }
  if (status === 'TOKEN_EXPIRED') {
    refreshToken(status);
  }
  if (status === 'PAYMENT_REQUIRED') {
    console.log('Bitte einloggen!');
  }
});

socket.on('disconnect', () => {
  logout();
  console.log('disconnected'); // true
});

// socket.on("privateMessage", (message) => {
//   console.log(message);
// });

socket.on('newMessage', (message) => {
  store.dispatch('chat/handleMsg', message, { root: true });
});

export default socket;

// Utils

function secureEmit(event, data) {
  // Abstraction to handle token on every event
  var payload = {
    data: data,
    token: store.getters['auth/GET_TOKEN'],
  };

  socket.emit(event, payload);
}

async function logout() {
  await axios.devInstance.get('/auth/browserclosed');
}

async function refreshToken(status = false) {
  try {
    // Try to get new token pairs
    let response = await axios.devInstance.get('/auth/refresh');
    store.commit('auth/SET_USER', response.data, { root: true });
    store.commit('auth/SET_LOGINSTATUS', true, { root: true });
    // Check if Socket Connection is connected
    if (!socket.connected) {
      socket.auth = response.data.access_token;
      socket.connect();

      // Handle orginal event again
      if (status) socket.secureEmit(status.event, status.data);
    }
  } catch (err) {
    console.log('Biite einloggen!');
  }
}
