<template>
  <div class="field">
    <div class="input-element">
      <VSelTags
        :title="categoryObj.category_name"
        :options="selectedOptions"
        :selectedOptions="siblingCategories"
        @selecting="addSiblingCategory($event)"
        @deselecting="removeSiblingCategory($event)"
      ></VSelTags>
    </div>
  </div>
</template>

<script>
import VSelTags from '@/components/Form/VSelTags.vue';
import { mapActions } from 'vuex';
export default {
  components: {
    VSelTags,
  },
  props: ['categoryObj'],
  data() {
    return {
      options: [],
      siblingCategories: [],
    };
  },
  mounted() {
    this.siblingCategories = this.categoryObj.child_categories;
    this.options = this.loadOptions(this.category_id);
  },
  methods: {
    ...mapActions('modal', [
      'showSubmitConfirmModal',
      'initSubmitConfirmModal',
      'showSubmitModal',
    ]),
    addSiblingCategory(selected) {
      // Check if category is present
      const ispresent = this.siblingCategories.some(
        (element) => element.category_id == selected.category_id
      );

      if (ispresent === true) {
        return;
      }
      if (this.siblingCategories.length > 1) {
        this.showSubmitModal({
          status: 'error',
          time: 1500,
          text: 'Maximale Anzahl erreicht',
        });
        return;
      }
      // Create new Object
      var category = {
        category_id: selected.category_id,
        category_name: selected.category_name,
        category_attributes: [],
      };

      this.siblingCategories.push(category);
      this.$emit('update:modelValue', this.siblingCategories);
    },
    removeSiblingCategory(category) {
      var index = this.siblingCategories.findIndex(
        (element) => element.category_id === category.category_id
      );
      if (index !== -1) {
        var arr = this.siblingCategories;
        arr.splice(index, 1);
        this.$emit('update:modelValue', arr);
      }
      this.$emit('update:modelValue', this.siblingCategories);
    },
    removeAllSiblingCategories() {
      this.siblingCategories = [];
      this.$emit('update:modelValue', this.siblingCategories);
    },
    updateAttributes(event, index) {
      // DEPRECATED
      this.siblingCategories[index].category_attributes = event;
      this.$emit('update:modelValue', this.siblingCategories);
    },
    async loadOptions(category_id) {
      try {
        var response = await this.$API.post('/category/child-category-sel', {
          category_id: category_id,
        });

        this.options = response.data;
      } catch (error) {
        return [];
      }
    },
  },
  computed: {
    category_id: function () {
      return this.categoryObj.category_id;
    },
    selectedOptions: function () {
      var selected = Array.from(this.siblingCategories);
      var opt = Array.from(this.options);
      var list = [];
      opt.forEach(function (option) {
        list.push({
          category_id: option.category_id,
          category_name: option.category_name,
          checked: selected.some(
            (selOption) => selOption.category_id === option.category_id
          ),
        });
      });
      return list;
    },
  },
};
</script>

<style scoped>
.field {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1rem 0;
  border-bottom: solid 1px grey;
  background-color: var(--settings-box);
}

.input-element {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
}
</style>
