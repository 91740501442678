<template>
  <BasicModal
    class="report-modal"
    :withHeadline="false"
    :title="this.ReportModalData.profile_nickname + ' melden'"
    :view="ReportModalStatus"
    :close="hideReportModal"
    :width="'45rem'"
  >
    <div class="report-form">
      <!-- body -->
      <FormField title="Grund" :value="this.form.repreason_id.value" type="select">
        <VSelect
          v-model="this.form.repreason_id.value"
          :selviewUrl="'/repreason/repreason-sel'"
        />
      </FormField>
      <FormField
        @inputError="this.form.rep_comment.error = $event"
        title="Kommentar"
        v-model="this.form.rep_comment.value"
        :value="this.form.rep_comment.value"
        :class="{ error: this.form.rep_comment.error }"
        :validations="this.validations.rep_comment"
        :error="this.form.rep_comment.error"
      />
      <FormButtonContainer>
        <FormSubmit @click="submit" :submitText="'senden'" />
      </FormButtonContainer>
    </div>
  </BasicModal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FormSubmit from '@/components/Form/FormSubmit.vue';
import VSelect from '@/components/Form/VSelect.vue';
import FormField from '@/components/Form/FormField.vue';
import BasicModal from '@/components/Ui/BasicModal.vue';
import FormButtonContainer from '@/components/Form/FormButtonContainer.vue';
export default {
  props: {
    title: {
      type: String,
    },
  },
  components: {
    FormSubmit,
    VSelect,
    FormField,
    BasicModal,
    FormButtonContainer,
  },
  data() {
    return {
      form: {
        repreason_id: {
          value: '',
          error: false,
        },
        rep_comment: {
          value: '',
          error: false,
        },
      },
      validations: {
        rep_comment: {
          maxLength: {
            value: 149,
            msg: false,
          },
          required: true,
        },
      },
    };
  },
  computed: {
    ...mapGetters('modal', ['SubmitConfirm']),
    ...mapGetters('modal', ['ReportModalStatus', 'ReportModalData']),
    formHasErrors: function () {
      const formFields = [this.form.repreason_id.error, this.form.rep_comment.error];
      return formFields.some((element) => element === true);
    },
  },
  methods: {
    ...mapActions('modal', ['hideAllModals', 'showSubmitModal', 'hideReportModal']),
    async submit() {
      // Check if form has validation errors
      this.emitter.emit('checkAll');
      if (this.formHasErrors === true) {
        this.showSubmitModal({
          status: 'error',
          time: 3000,
          text: 'Prüfe deine Eingabe!',
        });
        return;
      }

      if (!this.form.repreason_id.value) {
        this.showSubmitModal({
          status: 'error',
          time: 3000,
          text: 'Bitte wähle einen Grund aus!',
        });
        return;
      }

      try {
        await this.$API.post('/report/add', {
          profile_id: this.ReportModalData.profile_id,
          repreason_id: this.form.repreason_id.value,
          rep_comment: this.form.rep_comment.value,
        });
        const notification = this.ReportModalData.profile_nickname;
        this.hideReportModal();
        this.showSubmitModal({
          status: 'info',
          time: 5000,
          text: notification + ' wurde gemeldet!',
        });
      } catch (error) {
        if (error.response.data.message === 'ALREADY_REPORTED') {
          this.hideReportModal();
          this.showSubmitModal({
            status: 'error',
            time: 5000,
            text: 'Du hast diesen Benutzer bereits gemeldet!',
          });
          return;
        } else {
          this.showSubmitModal({
            status: 'error',
            time: 5000,
            text: 'Der Dienst ist aktuell nicht verfügbar. Bitte versuche es später nochmal',
          });
          return Promise.reject(error);
        }
      }
    },
  },
};
</script>

<style scoped>
.text {
  color: var(--font-color);
  margin-bottom: 2rem;
  text-align: center;
}
.box {
  display: flex;
  padding: 2rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 30vw;
  height: 10vw;
  background-color: var(--box-color);
  margin: 0 auto 0;
  border-radius: 10px;
}

.button-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

/* dialog transition */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.report-modal {
  z-index: 9999;
}

.report-form {
  background-color: var(--settings-box);
  border: solid #707070 1px;
  width: 100%;
}

@media only screen and (max-width: 1099px) {
  .box {
    width: 75vw;
    height: 100%;
  }

  .text {
    font-size: 20px;
  }

  .button-wrapper {
    gap: 1rem;
  }
}
</style>
