<template>
  <div
    v-if="!accepted"
    id="marketing-banner"
    tabindex="-1"
    class="custom-banner fixed left-1/2 top-6 z-50 flex w-[calc(100%-2rem)] -translate-x-1/2 flex-col justify-between rounded-lg border border-gray-100 bg-white p-4 shadow-sm dark:border-gray-600 dark:bg-gray-700 md:flex-row lg:max-w-7xl"
  >
    <div class="mb-3 mr-4 flex flex-col items-start md:mb-0 md:flex-row md:items-center">
      <a
        href="https://flowbite.com/"
        class="mb-2 flex items-center border-gray-200 dark:border-gray-600 md:mb-0 md:mr-4 md:border-r md:pr-4"
      >
        <img src="@/assets/logo.png" class="mr-2 h-6" alt="Flowbite Logo" />
        <span class="self-center whitespace-nowrap text-lg font-semibold dark:text-white">
          Zoom-Near
        </span>
      </a>
      <p class="flex items-center text-sm font-normal">
        Zoomnear verwendet technische notwendige Cookies um deine Authentifizierung zu
        verarbeiten.
      </p>
    </div>
    <div class="flex flex-shrink-0 items-center">
      <a
        @click="setConsentCookie"
        class="custom-btn focus:outline-none mr-2 rounded-lg px-5 py-2 text-xs font-medium text-white hover:bg-red-800 focus:ring-4 focus:ring-red-300"
      >
        Alles klar!
      </a>
      <button
        data-dismiss-target="#marketing-banner"
        type="button"
        class="absolute right-2.5 top-2.5 inline-flex flex-shrink-0 items-center justify-center rounded-lg p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white md:relative md:right-auto md:top-auto"
      >
        <svg
          aria-hidden="true"
          class="h-4 w-4"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          ></path>
        </svg>
        <span class="sr-only">Schließen</span>
      </button>
    </div>
  </div>
</template>

<script>
import '@/assets/css/tailwind.css';
import { mapActions, mapGetters } from 'vuex';
export default {
  components: {},
  props: {},
  data() {
    return {
      accepted: false,
    };
  },
  computed: {
    ...mapGetters('modal', ['AgbModalStatus']),
  },
  methods: {
    ...mapActions('modal', ['hideAllModals']),
    setCookie(name, value, days) {
      var expires = '';
      if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = '; expires=' + date.toUTCString();
      }
      document.cookie = name + '=' + value + expires + '; path=/';
    },
    setConsentCookie() {
      this.setCookie('cookieConsent', 'true', 90);
      this.accepted = true;
    },
    checkCookie() {
      var cookieAccepted = document.cookie.indexOf('cookieConsent=true') > -1;
      return cookieAccepted;
    },
  },
  mounted() {
    if (this.checkCookie()) {
      this.accepted = true;
    }
  },
};
</script>

<style scoped>
.custom {
  border-color: grey;
  background-color: var(--settings-box);
  color: var(--font-color);
}

.custom-btn {
  background-color: #ed1c24;
}

.custom-banner {
  background-color: var(--background-index-color);
  color: var(--font-color);
}
.payment-pending {
  color: var(--font-color);
}
</style>
