<template>
  <div class="tabs-wrapper">
    <div class="tabs-content">
      <div class="tabs-content-container">
        <div class="tabs-content-header">
          <div class="tabs-content-headline">
            <p>{{ tabData.currentTab.name }}</p>
          </div>
        </div>
        <form class="tabs-content-mainbox">
          <Transition name="fade" mode="out-in">
            <div :key="tabData.currentTab.component">
              <component :is="tabData.currentTab.component" v-bind="currentProperties"></component>
            </div>
          </Transition>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Basic from "@/components/Settings/SettingsBasic.vue";
import Categories from "@/components/Settings/SettingsCategories.vue";
import Private from "@/components/Settings/SettingsPrivate.vue";
import Socialmedia from "@/components/Settings/SettingsSocialmedia.vue";
import Image from "@/components/Settings/SettingsImage.vue";
export default {
  components: {
    Basic,
    Categories,
    Private,
    Socialmedia,
    Image,
  },
  props: ["formData", "tabData"],
  data() {
    return {};
  },
  computed: {
    currentProperties: function () {
      // serialize form object for the specific components
      if (this.tabData.currentTab.component === "Private") {
        return {
          PrivateEmailForm: this.formData.Private.PrivateEmailForm,
          PrivatePasswordForm: this.formData.Private.PrivatePasswordForm,
        };
      }

      if (this.tabData.currentTab.component === "Categories") {
        return {
          CategorieForm: this.formData.Categories,
        };
      }
      console.log(this.formData[this.tabData.currentTab.component], "Formdata");
      return { formobj: this.formData[this.tabData.currentTab.component] };
    },
  },
  methods: {
    closeMenue() {
      this.$refs.checkmark.checked = false;
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.tabs-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.tabs-content {
  width: 100%;
  display: flex;
  justify-content: center;
}

.tabs-content-container {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 1rem 0;
  width: 100%;
}

.tabs-content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tabs-content-headline {
  font-family: "Montserrat", sans-serif;
  font-size: clamp(12px, 1.5rem, 50px);
  font-weight: 800;
  margin: 1rem 0 0 0;
  color: var(--font-color);
}

.tabs-content-mainbox {
  background-color: var(--settings-box);
  border: solid #707070 1px;
}
</style>
