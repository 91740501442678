<template>
  <LoadingModal :view="LoadingStatus" />
  <div class="body-container">
    <button class="save" type="button" @click="close">ZURÜCK</button>
    <div class="top">
      <div class="image-container">
        <img class="crop-image" ref="imageSmall" :src="ImagecropSrc" alt="No Image detected" />
      </div>
      <div class="image-container">
        <img class="crop-image" ref="imageMedium" :src="ImagecropSrc" alt="No Image detected" />
      </div>
    </div>

    <div class="bot">
      <div class="image-container">
        <img class="crop-image" ref="imageLarge" :src="ImagecropSrc" alt="No Image detected" />
      </div>
      <div class="image-container">
        <img class="crop-image" ref="imageClick" :src="ImagecropSrc" alt="No Image detected" />
      </div>
    </div>
    <span> {{ servererror }} </span>
    <button class="save" type="button" @click="uploadAction">HOCHLADEN</button>
  </div>
</template>

<script>
import Cropper from "cropperjs";
import { mapGetters, mapActions } from "vuex";
import "cropperjs/dist/cropper.css";
import LoadingModal from "@/components/modals/LoadingModal.vue";
export default {
  components: {
    LoadingModal,
  },
  //   props: {
  //     src: {
  //       type: String,
  //     },
  //   },

  data() {
    return {
      test: true,
      cropper: {
        small: {},
        medium: {},
        large: {},
        click: {},
      },
      loadDone: {
        small: false,
        medium: false,
        large: false,
        click: false,
      },
      cropperNew: {},
      destination: {
        small: {},
        medium: {},
        large: {},
        click: {},
      },
      servererror: "",
      image: {},
    };
  },
  mounted() {
    this.cropper.small = new Cropper(this.$refs.imageSmall, {
      responsive: true,
      vieMode: 3,
      dragMode: "move",
      background: false,
      cropBoxResizable: false,
      cropBoxMovable: false,
      //   restore: true,
      //   checkCrossOrigin: true,
      //   //   modal: true,
      //   guides: true,
      //   center: true,
      //   highlight: true,
      //   background: false,
      //   autoCrop: true,
      //   movable: true,
      //   rotatable: true,
      //   //   scalable: true,
      //   zoomable: true,
      //   zoomOnTouch: true,
      //   zoomOnWheel: true,
      //   cropBoxMovable: true,
      //   cropBoxResizable: true,
      aspectRatio: 1 / 1,
      crop: () => {},
      ready: () => {
        this.loadDone.small = true;
      },
    });

    this.cropper.medium = new Cropper(this.$refs.imageMedium, {
      responsive: true,
      vieMode: 3,
      dragMode: "move",
      background: false,
      cropBoxResizable: false,
      cropBoxMovable: false,
      aspectRatio: 3 / 4,
      crop: () => {},
      ready: () => {
        this.loadDone.medium = true;
      },
    });

    this.cropper.large = new Cropper(this.$refs.imageLarge, {
      responsive: true,
      vieMode: 3,
      dragMode: "move",
      background: false,
      cropBoxResizable: false,
      cropBoxMovable: false,
      aspectRatio: 2 / 3,
      crop: () => {},
      ready: () => {
        this.loadDone.large = true;
      },
    });

    this.cropper.click = new Cropper(this.$refs.imageClick, {
      responsive: true,
      vieMode: 3,
      dragMode: "move",
      background: false,
      cropBoxResizable: false,
      cropBoxMovable: false,
      aspectRatio: 26 / 17,
      crop: () => {},
      ready: () => {
        this.loadDone.small = false;
        this.loadDone.medium = false;
        this.loadDone.large = false;
        this.loadDone.click = false;
      },
    });
  },
  computed: {
    ...mapGetters("modal", ["ImagecropSrc", "LoadingStatus"]),
    cropperReady: function () {
      return Object.values(this.loadDone).every((key) => key === true);
    },

    Progress: function (progressEvent) {
      return Math.round((progressEvent.loaded * 100) / progressEvent.total);
    },
  },
  methods: {
    ...mapActions("profile", ["uploadProfileImage", "getProfileImage"]),
    ...mapActions("modal", [
      "showSubmitModal",
      "showLoadingModal",
      "hideLoadingModal",
      "hideImagecropModal",
      "setImagecropSource",
    ]),
    ...mapGetters("profile", ["GET_PROFILEIMAGE"]),
    ...mapActions("profile", ["deleteProfileImage"]),
    getCroppedImage(cropper) {
      const canvas = cropper.getCroppedCanvas();
      const dataurl = canvas.toDataURL("image/png");
      return dataurl.replace("data:image/png;base64,", "");
    },
    async getCroppedSrc() {
      this.destination.small = this.getCroppedImage(this.cropper.small);
      this.destination.medium = this.getCroppedImage(this.cropper.medium);
      this.destination.large = this.getCroppedImage(this.cropper.large);
      this.destination.click = this.getCroppedImage(this.cropper.click);
    },
    destroyCropper() {
      this.cropper.small.destroy();
      this.cropper.medium.destroy();
      this.cropper.large.destroy();
      this.cropper.click.destroy();
    },
    close() {
      this.destroyCropper();
      this.hideLoadingModal();
      this.$router.push({
        name: "Home",
        params: {
          notification: JSON.stringify({
            status: "error",
            time: 1500,
            text: "Upload abgebrochen",
          }),
        },
      });
    },

    // Action
    async uploadAction() {
      // Get Cropped Src
      try {
        this.showLoadingModal();
        await this.uploadProfileImage({
          small: this.getCroppedImage(this.cropper.small),
          medium: this.getCroppedImage(this.cropper.medium),
          large: this.getCroppedImage(this.cropper.large),
          click: this.getCroppedImage(this.cropper.click),
        });
        this.getProfileImage();
        this.destroyCropper();
        this.$router.push({
          name: "Home",
          params: {
            notification: JSON.stringify({
              status: "success",
              time: 3000,
              text: "Upload abgeschlossen",
            }),
          },
        });
      } catch (errorMessage) {
        this.hideLoadingModal();
        switch (errorMessage) {
          case "IMAGE_TO_LARGE":
            this.showSubmitModal({
              status: "error",
              time: 3000,
              text: "Das Bild darf 5MB nicht überschreiten",
            });
            break;
          default:
            this.showSubmitModal({
              status: "error",
              time: 3000,
              text: "Der Dienst ist aktuell nicht verfügbar. Bitte versuche es später nochmal",
            });
            break;
        }
      }
    },
  },
};
</script>

<style scoped>
.body-container {
  position: absolute;
  display: flex;
  justify-content: center;

  align-items: center;
  flex-direction: column;
  color: var(--font-color);
  width: 99vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.644);
  position: fixed;
  z-index: 10;
}
.top,
.bot {
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-container {
  margin: 1rem;
  width: 15vw;
  height: 15vw;
}

.cropper-modal {
  background-color: unset;
  opacity: 0;
}
.cropper .save {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12rem;
  height: 2.5rem;
  background-color: var(--settings-input);
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: clamp(12px, 1rem, 50px);
  color: var(--font-color);
}
.save:hover {
  background-color: #ed1c24;
}
</style>
