<template>
  <p class="check">
    öffentlich
    <input
      @click.prevent="toggleProfilePublic"
      type="checkbox"
      v-model="GET_PROFILEPUBLIC"
      name="switch"
      id="switch"
    />
    <label for="switch"></label>
  </p>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters("profile", ["GET_PROFILEPUBLIC"]),
  },
  methods: {
    ...mapActions("profile", ["toggleProfilePublic", "fetchProfilePublic"]),
  },
  async beforeMount() {
    this.fetchProfilePublic();
  },
  mounted() {},
};
</script>

<style scoped>
.check {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2rem;
  font-size: clamp(12px, 1.5rem, 50px);
  font-weight: 800;
  color: var(--font-color);
  -ms-user-select: None;
  -moz-user-select: None;
  -webkit-user-select: None;
  user-select: None;
}
.switch {
  position: relative;
  display: inline-block;
  width: 2rem;
  height: 1rem;
  margin: 0.2rem 0 0 0.5rem;
}
input[type="checkbox"] {
  width: 0;
  height: 0;
  visibility: hidden;
}
label {
  width: 2rem;
  height: 1rem;
  display: block;
  background-color: #ededef;
  border-radius: 100px;
  position: relative;
  cursor: pointer;
  transition: 0.5s;
  border: solid 1px black;
  top: 0.1rem;
  left: 0.3rem;
}
label::after {
  content: "";
  width: 0.8rem;
  height: 0.8rem;
  background-color: black;
  position: absolute;
  border-radius: 70px;
  top: 0.1rem;
  left: 0.1rem;
  transition: 0.5s;
}
input:checked + label:after {
  left: calc(100% - 0.1rem);
  transform: translateX(-100%);
  background-color: #ed1c24;
}
input:checked + label {
  background-color: black;
  border: solid 1px #ed1c24;
}
</style>
