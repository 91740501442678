<template>
  <ProfileClick />
  <ProfileContainer />
  <ProfileHover />
</template>

<script>
import { mapGetters } from 'vuex';
// import dimension from '@/data/profileSize.js';
import ProfileContainer from '@/components/Profile/ProfileContainer.vue';
import ProfileHover from '@/components/Profile/ProfileHover.vue';
import ProfileClick from '@/components/Profile/ProfileClick.vue';
export default {
  components: {
    ProfileContainer,
    ProfileClick,
    ProfileHover,
  },
  props: ['totalWidth'],
  data() {
    return {};
  },
  methods: {},
  computed: {
    ...mapGetters({
      responseLength: 'search/SEARCHRESPONSELENGTH',
      loading: 'search/GET_SEARCHLOADING',
    }),
    ...mapGetters({
      messages: 'chat/GET_MESSAGES',
      activeInbox: 'chat/GET_ACTIVEINBOX',
    }),
  },
  mounted() {},
};
</script>

<style></style>
