import socket from '@/utils/socket';
export default {
  namespaced: true,
  state() {
    return {
      user: {},
      admin: false,
      loggedIn: false,
      hasPayment: false,
      paymentPending: false,
      socket: false,
      loadingDone: true,
      access_token: '',
      appuser_id: '',
      profile_id: '',
      affliate_user: {},
      cookieAccepted: false,
    };
  },
  mutations: {
    SET_USER(state, payload) {
      state.access_token = payload.access_token;
      state.appuser_id = payload.appuser_id;
      state.profile_id = payload.profile_id;
      state.hasPayment = payload.hasPayment;
      state.admin = payload.admin;
      window.localStorage.user = JSON.stringify(state);
    },
    SET_LOGINSTATUS(state, payload) {
      state.loggedIn = payload;
    },
    SET_PAYMENTSTATUS(state, payload) {
      state.hasPayment = payload;
    },
    SET_PAYMENTPENDING(state, payload) {
      state.paymentPending = payload;
    },
    SET_USERLOGOUT(state) {
      state.appuser_id = null;
      state.loggedIn = false;
      state.admin = false;
      state.hasPayment = false;
      state.access_token = '';
      window.localStorage.user = '';
    },
    SET_LOADINGDONE(state, status) {
      state.loadingDone = status;
    },
    SET_AFFLIATEUSER(state, usrObj) {
      state.affliate_user = usrObj;
    },
    SET_COOKIEACCEPTED(state, payload) {
      state.cookieAccepted = payload;
    },
  },
  getters: {
    GET_TOKEN: (state) => {
      return state.access_token;
    },
    GET_USER: (state) => {
      return state.appuser_id;
    },
    GET_PROFILE: (state) => {
      return state.profile_id;
    },
    LoginStatus: (state) => {
      return state.loggedIn;
    },
    PaymentStatus: (state) => {
      return state.hasPayment;
    },
    PaymentPending: (state) => {
      return state.paymentPending;
    },
    IsAdmin: (state) => {
      return state.admin;
    },
    LoadingDone: (state) => {
      return state.loadingDone;
    },
    SocketStatus: () => {
      if (Object.prototype.hasOwnProperty.call(socket, 'connected')) {
        return socket.connected;
      }
      return false;
    },
    AffliateUser: (state) => {
      return state.affliate_user;
    },
    cookieAccepted: (state) => {
      return state.cookieAccepted;
    },
  },
  actions: {
    async login(context, payload) {
      try {
        let response = await this.$API.post('/login', payload);
        if (response.status === 200) {
          // Set tokens and status flags
          context.commit('SET_USER', response.data);
          context.commit('SET_LOGINSTATUS', true);
          context.dispatch('SocketLogin');
        }

        if (response.status === 202) {
          context.commit('SET_USER', response.data);
          context.commit('SET_PAYMENTPENDING', true);
          return response.status;
        }
      } catch (error) {
        if (error.response.status === 402) {
          context.commit('SET_USER', error.response.data);
          throw error.response.statusText;
        }
        throw error.response.data.message;
      }
    },
    SocketLogin(context) {
      if (context.getters.PaymentStatus && !socket.connected) {
        socket.auth = context.getters.GET_TOKEN;
        socket.connect();
      }
      return;
    },
    async logout(context) {
      try {
        // Discard tokens
        await this.$API.post('/logout/access');
        await this.$API.post('/logout/refresh');

        //Change login flag
        context.commit('SET_USERLOGOUT');

        // Disconnect socket
        if (socket.connected) socket.disconnect();
      } catch (error) {
        throw error.response.data.message;
      }
    },
    async loginRefresh(context) {
      try {
        let response = await this.$API.get('/auth/refresh');
        context.commit('SET_USER', response.data);
        context.commit('SET_LOGINSTATUS', true);
        context.dispatch('SocketLogin');
      } catch (error) {
        throw error.response.data.message;
      }
    },

    async getAffliateUser(context, profile_id) {
      let response = await this.$API.get('/auth/affliate/' + String(profile_id));
      if (response.status === 200) {
        context.commit('SET_AFFLIATEUSER', response.data);
      }
    },

    updateLoadingDone(context, status) {
      context.commit('SET_LOADINGDONE', status);
    },
    updatePaymentPending(context, status) {
      context.commit('SET_PAYMENTPENDING', status);
    },
    // async checkPayment(context) {
    //     try {
    //         let response = await this.$API.get("/auth/check-payment")
    //     }
    // }
  },
};
