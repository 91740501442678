<template>
  <div>
    <!-- <Transition name="fade"> -->
    <div v-if="MobileSearchStatus" class="searchwrapper">
      <div class="search-container">
        <div class="list">
          <div v-if="this.default !== true" class="box">
            <TransitionGroup name="list">
              <button
                v-for="option in filteredSel"
                :key="option.id"
                :value="option.name"
                @click="this.addSearchParam(option)"
                class="category"
              >
                <p>{{ option.name }}</p>
              </button>
            </TransitionGroup>
          </div>
        </div>

        <div class="search">
          <div class="searchbox">
            <TransitionGroup name="list">
              <button
                v-for="param in GET_SEARCHPARAMS"
                :key="param.id"
                @click="this.removeSearchParam(param)"
                class="searchbtn"
              >
                <!-- icon depents on ref condition -->
                <span v-if="param.ref == 'X1'" class="material-icons-outlined searchimg">
                  construction
                </span>
                <span v-if="param.ref == 'X2'" class="material-icons-outlined searchimg">
                  share
                </span>
                <span v-if="param.ref == 'X3'" class="material-icons-outlined searchimg">
                  tag
                </span>
                <span v-if="param.ref == 'X4'" class="material-icons-outlined searchimg">
                  home
                </span>

                <!-- param name -->
                <p>{{ param.name }}</p>

                <!-- remove icon -->
                <span class="material-icons-outlined close">close</span>
              </button>
            </TransitionGroup>
            <span class="material-icons-outlined searchicon">search</span>
          </div>
        </div>
      </div>
    </div>
    <!-- </Transition> -->

    <div v-if="MobileNavStatus" class="options">
      <div class="textbox">
        <p
          v-if="LoginStatus !== 'loading' && LoginStatus !== true"
          class="login"
          @click="showLoginModal"
        >
          Login
        </p>
        <p
          v-if="LoginStatus !== 'loading' && LoginStatus !== true"
          class="login"
          @click="showRegisterModal"
        >
          Registrieren
        </p>
        <p
          v-if="LoginStatus !== 'loading' && LoginStatus === true"
          class="login"
          @click="logoutAction"
        >
          Abmelden
        </p>
        <p
          class="settings"
          v-if="LoginStatus !== 'loading' && LoginStatus !== false"
          @click="showOptionsModal"
        >
          Einstellungen
        </p>
        <p class="darkmode">
          Dunkles Design
          <input
            @change="toggleTheme"
            type="checkbox"
            v-model="checked"
            name="switch"
            id="switch"
          />
          <label for="switch"></label>
        </p>
        <div class="agbs">
          <p class="agb" @click="showImpressumModal">Impressum</p>
          <p class="agb" @click="showDatenschutzModal">Datenschutz</p>
          <p class="agb" @click="showAgbModal">AGB</p>
          <p class="agb" @click="showAffliateModal">Partner</p>
          <p class="agb" @click="toAdmin" v-if="IsAdmin">Admin</p>
        </div>
      </div>
    </div>

    <div class="footer">
      <div @click="hideAllModals" class="logo">
        <Transition mode="out-in">
          <img
            v-if="!MobileSearchStatus && !ChatInboxStatus"
            src="@/assets/logo.png"
            alt="logofooter"
            class="logofooter"
          />
          <i v-else @click="hideAllModals" id="close-search-btn" class="fas fa-times"></i>
        </Transition>
      </div>
      <div class="crosshair">
        <div
          :class="{ selected: this.GET_SEARCHPARAMS.length > 0 && !MobileSearchStatus }"
          class="crossbox"
        >
          <div class="cross1"></div>
          <div class="cross2"></div>
          <div class="cross3"></div>
          <div class="cross4"></div>
          <div @click="toggleSearch('X1')" class="top">
            <span
              :class="{ 'current-search': MobileSearchStatus && searchActive === 'X1' }"
              class="favicon"
            >
              1
            </span>
          </div>
          <div @click="toggleSearch('X3')" class="bot">
            <span
              :class="{ 'current-search': MobileSearchStatus && searchActive === 'X3' }"
              class="favicon fas fa-star"
            ></span>
          </div>
          <div @click="toggleSearch('X2')" class="left">
            <span
              :class="{ 'current-search': MobileSearchStatus && searchActive === 'X2' }"
              class="favicon"
            >
              2
            </span>
          </div>
          <div @click="toggleSearch('X4')" class="right">
            <span
              :class="{ 'current-search': MobileSearchStatus && searchActive === 'X4' }"
              class="favicon"
            >
              3
            </span>
          </div>
        </div>
      </div>

      <!-- todo -->
      <div class="chat">
        <span
          v-if="PaymentStatus"
          @click="toggleChatInboxMobile"
          class="material-icons-outlined chatpng"
        >
          chat_bubble_outline
        </span>
        <span @click="toggleNavInterface" class="material-icons-outlined burger">
          menu
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  components: {},
  data() {
    return {
      theme: '',
      checked: true,
      default: true,
      selOptions: [],
      searchActive: '',
    };
  },
  computed: {
    ...mapGetters('search', ['GET_SELOPTIONS']),
    ...mapGetters('search', ['GET_SEARCHPARAMS']),
    ...mapGetters('auth', ['LoginStatus', 'PaymentStatus', 'IsAdmin']),
    ...mapGetters('modal', [
      'LoadingStatus',
      'MobileSearchStatus',
      'ChatInboxStatus',
      'MobileNavStatus',
      'isMobile',
    ]),
    filteredSel: function () {
      return this.GET_SELOPTIONS.filter(
        (option) => !this.GET_SEARCHPARAMS.includes(option)
      );
    },
  },
  methods: {
    ...mapActions('search', [
      'fetchOptions',
      'addSearchParam',
      'removeSearchParam',
      'removeobj',
    ]),
    ...mapActions('modal', [
      'toggleChatInbox',
      'hideAllModals',
      'showMobileSearch',
      'hideMobileSearch',
      'toggleMobileSearch',
      'showMobileNavigation',
      'hideMobileNavigation',
      'toggleMobileNavigation',
      'hideChatInbox',
      'hideChatMessages',
      'hideOptionsModal',
      'showSubmitConfirmModal',
    ]),
    toAdmin() {
      this.$router.push({ name: 'Dashboard' });
    },
    toggleSearch: function (ref) {
      this.searchActive = ref;
      this.hideMobileNavigation();
      this.hideChatInbox();
      this.hideChatMessages();
      this.hideOptionsModal();
      //   this.showSubmitConfirmModal();
      //Search
      this.showMobileSearch();

      this.default = false;

      this.fetchOptions(ref);
    },
    toggleChatInboxMobile() {
      //Close all other Mobile Menues first
      this.hideMobileNavigation();
      this.hideChatMessages();
      this.hideOptionsModal();
      this.showSubmitConfirmModal();
      this.hideMobileNavigation();
      this.hideMobileSearch();
      // Chat
      this.toggleChatInbox();
    },
    toggleNavInterface() {
      //Close all other Mobile Menues first
      this.hideMobileSearch();
      this.hideChatInbox();
      this.hideChatMessages();
      this.hideOptionsModal();
      this.showSubmitConfirmModal();
      //Mobile Navigation
      this.toggleMobileNavigation();
    },
    toggleTheme() {
      if (this.checked) {
        this.theme = 'darkMode';
      } else {
        this.theme = 'root';
      }
      this.$cookies.set('theme', this.theme, '1y');
      document.documentElement.setAttribute('data-theme', this.theme);
    },
    ...mapActions('modal', [
      'showLoginModal',
      'showOptionsModal',
      'showRegisterModal',
      'showImpressumModal',
      'showDatenschutzModal',
      'showAgbModal',
      'showAffliateModal',
    ]),
    ...mapActions('auth', ['logout']),
    ...mapActions('search', ['search_action']),
    async logoutAction() {
      await this.logout();
      this.search_action();
    },
  },
  beforeMount() {
    var theme = this.$cookies.get('theme');
    document.documentElement.setAttribute('data-theme', theme);

    if (theme === 'darkMode') {
      this.checked = true;
    } else {
      this.checked = false;
    }
  },
};
</script>
<style scoped>
/* Transition */

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.list-move, /* apply transition to moving elements */
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(50px);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.list-leave-active {
  position: absolute;
}

.searchwrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.close-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  position: fixed;
  width: 17rem;
  height: 5rem;
  bottom: 70vh;
  font-size: 5rem;
  color: #ed1c24;
}

.search-container {
  position: fixed;
  width: 17rem;
  height: 50vh;
  bottom: 20vh;
}
.search {
  display: flex;
  position: fixed;
  bottom: 10rem;
  justify-content: center;
  align-items: center;
  width: 17rem;
  height: 10rem;
}
.searchbox {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: center;
  height: 10rem;
  width: 17rem;
  background-color: var(--box-color);
  border-radius: 10px;
  border: solid #707070 1px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.searchbtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15rem;
  padding: 0.5rem 0;
  margin: 0.15rem 0.15rem;
  text-decoration: none;
  border: solid 0px;
  background-color: var(--textbox-color);
  color: var(--font-color);
  cursor: pointer;
  border-radius: 10px;
  z-index: 1;
  font-size: clamp(18px, 1.6vw, 20px);
  word-wrap: break-word;
  -moz-hyphens: auto;
  -o-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
.searchbtn p {
  padding: 0 0.5rem 0.2rem 0.5rem;
}
.searchicon {
  position: absolute;
  font-size: 10rem;
  margin: 0 0 0 1rem;
  color: var(--img-color);
}
.searchimg {
  font-size: 1.5rem;
  padding: 0 0 0 1rem;
}
.favicon {
  font-size: 1.5rem;
  padding: 0 0.2rem;
  color: white;
  -ms-user-select: None;
  -moz-user-select: None;
  -webkit-user-select: None;
  user-select: None;
  transform: rotate(-45deg);
}
.close {
  padding: 0 1rem 0 0;
  font-size: 1.5rem;
  color: var(--font-color);
}
.list {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  width: 17rem;
  height: 14rem;
  bottom: 21rem;
  background-color: var(--settings-box);
}
.box {
  display: flex;
  position: fixed;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  height: 14rem;
  width: 17rem;
  background-color: var(--box-color);
  border-radius: 10px;
  border: solid #707070 1px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.category {
  width: 15rem;
  margin: 0.15rem 0;
  padding: 0.5rem 0;
  text-decoration: none;
  border: solid 0px;
  background-color: var(--textbox-color);
  color: var(--font-color);
  cursor: pointer;
  border-radius: 10px;
  z-index: 1;
  font-size: clamp(18px, 1.6vw, 20px);
  word-wrap: break-word;
  -moz-hyphens: auto;
  -o-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
.categoryimg {
  position: absolute;
  font-size: 10rem;
  padding: 2rem 0 0 0;
  color: var(--img-color);
}

/*  --------------------------------------------------------  */

.options {
  top: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 15rem;
  opacity: 1;
}
.textbox {
  justify-content: flex-start;
  text-align: flex-start;
  flex-direction: column;
  width: 17rem;
  height: 45vh;
  background-color: #cccccc;
  border-radius: 10px;
  border: solid #707070 1px;
  background-color: var(--box-color);
}
.login,
.settings {
  font-size: clamp(12px, 1.4rem, 50px);
  cursor: pointer;
  color: var(--font-color);
  margin: 0.5rem 1rem;
}
.darkmode {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: clamp(12px, 1.4rem, 50px);
  color: var(--font-color);
  margin: 2rem 1rem 0 1rem;
}
.switch {
  position: relative;
  display: inline-block;
  width: 1.8rem;
  height: 0.9rem;
  margin: 0.2rem 0 0 0.5rem;
}
input[type='checkbox'] {
  width: 0;
  height: 0;
  visibility: hidden;
}
label {
  width: 2.1rem;
  height: 0.7rem;
  display: block;
  background-color: #ededef;
  border-radius: 100px;
  position: relative;
  cursor: pointer;
  transition: 0.5s;
  border: solid 1px black;
  top: 0rem;
  left: 0.3rem;
}
label::after {
  content: '';
  width: 0.6rem;
  height: 0.6rem;
  background-color: black;
  position: absolute;
  border-radius: 70px;
  top: 0.05rem;
  left: 0.1rem;
  transition: 0.5s;
}
input:checked + label:after {
  left: calc(100% - 0.1rem);
  transform: translateX(-100%);
  background-color: #ed1c24;
}
input:checked + label {
  background-color: black;
  border: solid 1px #ed1c24;
}
.agbs {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  height: 4rem;
  margin-top: 4rem;
  flex-direction: column;
  padding: 0 0 0.5rem 0;
}
.agb {
  font-size: clamp(12px, 0.9rem, 50px);
  cursor: pointer;
  margin: 0.5rem 1rem;
  color: var(--font-color);
}
/* ------------------------------------------------------------  */

.footer {
  position: fixed;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 9rem;
  background-color: var(--background-color);
  overflow: hidden;
}
.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 9rem;
  height: 8rem;
}
.logofooter {
  height: 2.5rem;
}
#close-search-btn {
  font-size: 4rem;
  color: var(--font-color);
}
.v-enter-active {
  transition: transform 1s ease-in;
}
/* .v-leave-active {
  transition: tranform 0.3s ease;
} */

.v-enter-from {
  transform: rotate(5.142rad);
}
/* .v-leave-to {
  transform: rotate(20.142rad);
} */
.crosshair {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12rem;
  height: 8rem;
}
.chat {
  width: 9rem;
  height: 10rem;
}
.crossbox {
  height: 5rem;
  width: 5rem;
  transform: rotate(45deg);
  border: solid black 1px;
}

.selected {
  border: solid transparent 3px;
  animation: picked 4s linear infinite normal;
}

@keyframes picked {
  0% {
    border: solid transparent 3px;
  }
  50% {
    border-left: solid yellow 3px;
    border-top: solid yellow 3px;
    border-right: solid yellow 3px;
    border-bottom: solid yellow 3px;
  }

  100% {
    border: solid transparent 3px;
  }
}

.current-search.crossimg {
  color: black;
}

.top,
.left,
.bot,
.right {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 2.5rem;
  height: 2.5rem;
  background-color: #ed1c24;
  cursor: pointer;
}
.crossimg {
  font-size: 1.5rem;
  transform: rotate(-45deg);
  color: white;
}

.bot {
  top: 2.5rem;
  left: 2.5rem;
}
.left {
  top: 2.5rem;
}
.right {
  right: 2.5rem;
  left: 2.5rem;
}
.cross1,
.cross2,
.cross3,
.cross4 {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 0.01rem solid transparent;
  border-right: 0.01rem solid transparent;
  border-bottom: 3.5rem solid black;
  z-index: 2;
}
.cross1 {
  top: 2.3rem;
  right: 2.45rem;
}
.cross2 {
  top: 0.75rem;
  left: 0.75rem;
  transform: rotate(90deg);
}
.cross3 {
  right: 2.45rem;
  bottom: 2.5rem;
  transform: rotate(180deg);
}
.cross4 {
  right: 0.75rem;
  bottom: 0.75rem;
  transform: rotate(270deg);
}

.chatpng {
  /* change png */
  position: absolute;
  margin: 1.5rem 0 0 2.2rem;
  font-size: 2rem;
  cursor: pointer;
  color: var(--font-color);
}
.chatpng:hover,
.burger:hover {
  transform: scale(1.1);
}
.burger {
  position: absolute;
  margin: 4rem 0 0 2rem;
  font-size: 2.5rem;
  cursor: pointer;
  color: var(--font-color);
}
</style>
