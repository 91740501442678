export default {
  namespaced: true,
  state() {
    return {
      profile: {
        profile_id: '',
        appuser_email: '',
        profile_nickname: '',
        profile_aboutme: '',
        profile_firstname: '',
        profile_lastname: '',
        profile_street: '',
        profile_city: '',
        profile_website: '',
        profile_provinz_id: '',
        profile_instagram: '',
        profile_facebook: '',
        profile_twitch: '',
        profile_twitter: '',
        profile_youtube: '',
        profile_tiktok: '',
        profile_linkedin: '',
        profile_xing: '',
        profile_pinterest: '',
        imagesrc_click: '',
        profile_categories: '',
        profile_favorite: false,
      },
      profile_saved: false,
      profile_public: false,
      uploadProcessing: false,
      uploadProgress: 0,
      UploadImage: {
        result: {
          small: '',
          medium: '',
          large: '',
          click: '',
          src: '',
        },
      },
    };
  },
  mutations: {
    SET_PROFILEOBJ(state, payload) {
      for (const [key, value] of Object.entries(payload[0])) {
        if (value === null) {
          state.profile[key] = '';
          continue;
        }
        state.profile[key] = value;
      }
    },
    SET_PROFILE(state, payload) {
      state.profile = payload;
    },
    SET_PROFILEPUBLIC(state, payload) {
      state.profile_public = payload;
    },
    SET_PROFILEIMAGE(state, base64) {
      state.profile.profile_image = base64;
    },
    SET_UPLOAD_PROGRESS(state, percent) {
      state.uploadProgress = percent;
    },
    SET_UPLOAD_PROCESSING(state, bool) {
      state.uploadProcessing = bool;
    },
    SET_IMAGECROPSRC(state, src) {
      state.UploadImage.src = src;
    },
    SET_IMAGECROPRES(state, { key, res }) {
      state.UploadImage.result[key] = res;
    },
    SET_PROFILEXCATEGORY(state, payload) {
      state.profile.profile_categories = payload;
    },
  },
  getters: {
    profile: (state) => {
      return state.profile;
    },
    GET_PROFILEIMAGE: (state) => {
      return state.profile.profile_image;
    },
    GET_PROFILEPUBLIC: (state) => {
      return state.profile_public;
    },
    ImagecropSrc: (state) => {
      return state.UploadImage.src;
    },
    GET_IMAGECROPRES: (state) => {
      return state.UploadImage.result;
    },
    GET_UPLOADPROGRESS: (state) => {
      return state.uploadProgress;
    },
    GET_UPLOAD_PROCESSING: (state) => {
      return state.uploadProcessing;
    },
    GET_PROFILEBASIC: (state) => {
      return {
        profile_firstname: {
          value: state.profile.profile_firstname,
          error: false,
        },
        profile_lastname: {
          value: state.profile.profile_lastname,
          error: false,
        },
        profile_nickname: {
          value: state.profile.profile_nickname,
          error: false,
        },
        profile_provinz_id: {
          value: state.profile.profile_provinz_id,
          error: false,
        },
        profile_city: {
          value: state.profile.profile_city,
          error: false,
        },
        profile_aboutme: {
          value: state.profile.profile_aboutme,
          error: false,
        },
      };
    },
    GET_PROFILESOCIALMEDIA: (state) => {
      return {
        profile_facebook: {
          value: state.profile.profile_facebook,
          error: false,
        },
        profile_instagram: {
          value: state.profile.profile_instagram,
          error: false,
        },
        profile_linkedin: {
          value: state.profile.profile_linkedin,
          error: false,
        },
        profile_pinterest: {
          value: state.profile.profile_pinterest,
          error: false,
        },
        profile_tiktok: {
          value: state.profile.profile_tiktok,
          error: false,
        },
        profile_twitch: {
          value: state.profile.profile_twitch,
          error: false,
        },
        profile_twitter: {
          value: state.profile.profile_twitter,
          error: false,
        },
        profile_xing: {
          value: state.profile.profile_xing,
          error: false,
        },
        profile_youtube: {
          value: state.profile.profile_youtube,
          error: false,
        },
        profile_website: {
          value: state.profile.profile_additionalone,
          error: false,
        },
      };
    },
    GET_PROFILEPRIVATEMAIL: (state) => {
      const email = state.profile.appuser_email;
      const [name, domain] = email.split('@');
      const obscuredEmail = `${name[0]}${new Array(name.length).join('*')}@${domain}`;
      return {
        appuser_email: {
          value: obscuredEmail,
          error: false,
        },
        appuser_password: {
          value: '',
          error: false,
        },
      };
    },
    GET_PROFILEPRIVATEPASSWORD: () => {
      return {
        appuser_password: {
          value: '',
          error: false,
        },
        appuser_newpassword: {
          value: '',
          error: false,
        },
        appuser_newpassword_confirm: {
          value: '',
          error: false,
        },
      };
    },
    GET_PROFILECATEGORIES: (state) => {
      return state.profile.profile_categories;
    },
  },
  actions: {
    async ProfileLoadAction(context) {
      // await this.$API.get('/profile').then((result) => {
      //     context.commit('SET_PROFILE', result.data[0])
      // }).catch((err) => {
      //     console.log(err)
      // })
      try {
        context.dispatch('getProfileData').then(() => {
          context.dispatch('getProfileImage');
        });
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async getProfileData(context) {
      try {
        let response = await this.$API.get('/profile');
        if (response.status === 200) {
          context.commit('SET_PROFILEOBJ', response.data);
        }
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getProfileImage(context) {
      try {
        context.commit('SET_UPLOAD_PROCESSING', true);
        let response = await this.$API.get('/image/profile/settings');
        if (response.status === 200) {
          context.commit('SET_PROFILEIMAGE', response.data);
          context.commit('SET_UPLOAD_PROCESSING', false);
          //   context.dispatch("search/search_action", null, { root: true });
        }
      } catch (error) {
        context.commit('SET_PROFILEIMAGE', '');
        context.commit('SET_UPLOAD_PROCESSING', false);
        return Promise.reject(error);
      }
    },

    async deleteProfileImage(context) {
      var profile_image = context.getters.GET_PROFILEIMAGE;
      if (!profile_image) return;
      try {
        await context.dispatch(
          'modal/initSubmitConfirmModal',
          {
            active: true,
            text: 'Bild wirklich entfernen?',
          },
          { root: true }
        );
        context.dispatch('modal/showSubmitConfirmModal', false, { root: true });
        await this.$API.delete('/image/delete');
        context.commit('SET_PROFILEIMAGE', '');
      } catch (error) {
        context.dispatch('modal/showSubmitConfirmModal', false, { root: true });
      }
    },
    setImagecropSource(context, src) {
      context.commit('SET_IMAGECROPSRC', src);
    },
    async uploadProfileImage(context, images) {
      const config = {
        onUploadProgress: (event) => {
          context.commit(
            'SET_UPLOAD_PROGRESS',
            Math.round((event.loaded * 100) / event.total)
          );
        },
      };

      try {
        context.commit('SET_UPLOAD_PROCESSING', true);
        await this.$API.post('/image/upload', images, config);
        context.commit('SET_UPLOAD_PROGRESS', 0);
        await context.dispatch('getProfileImage');
        context.commit('SET_UPLOAD_PROCESSING', false);
      } catch (error) {
        context.commit('SET_UPLOAD_PROCESSING', false);
        throw error.response.data.message;
      }
    },

    async fetchProfilePublic(context) {
      try {
        let response = await this.$API.get('/profile/public');
        if (response.status === 200) {
          context.commit('SET_PROFILEPUBLIC', response.data);
        }
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async toggleProfilePublic(context) {
      try {
        let response = await this.$API.post('/profile/public/toggle');
        if (response.status === 200) {
          console.log(response);
          context.commit('SET_PROFILEPUBLIC', response.data);
        }
      } catch (error) {
        switch (error.response.data.message) {
          case 'NOT_VALID':
            context.dispatch(
              'modal/showSubmitModal',
              {
                status: 'info',
                text: 'Füge ein Foto hinzu um dein Profile öffentlich zu schalten',
                time: 6000,
              },
              { root: true }
            );
            break;
          case 'NO_CATEGORYS':
            context.dispatch(
              'modal/showSubmitModal',
              {
                status: 'info',
                text: 'Wähle mindestens eine Dienstleistung aus um dein Profile öffentlich zu schalten',
                time: 6000,
              },
              { root: true }
            );
            break;

          default:
            context.dispatch(
              'modal/showSubmitModal',
              {
                status: 'error',
                text: 'Ein unerwarteter Fehler ist aufgetreten, versuche es später erneut',
                time: 5000,
              },
              { root: true }
            );
            break;
        }
      }
    },
  },
};
