let links = {
  instagram: "https://www.instagram.com/",
  facebook: "https://www.facebook.com/",
  twitch: "https://www.twitch.tv/",
  twitter: "https://twitter.com/",
  youtube: "https://www.youtube.com/c/",
  tiktok: "https://www.tiktok.com/@",
  linkedin: "https://www.linkedin.com/in/",
  xing: "https://www.xing.com/profile/",
  pinterest: "https://www.pinterest.de/",
};

export default links;
