<template>
  <button
    id="dropdownRadioButton"
    data-dropdown-toggle="dropdownRadio"
    class="ml-4 inline-flex items-center text-gray-500 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-3 py-1.5 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
    type="button"
  >
    {{ current_search.label }}
    <svg
      class="w-3 h-3 ml-2"
      aria-hidden="true"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M19 9l-7 7-7-7"
      ></path>
    </svg>
  </button>
  <!-- Dropdown menu -->
  <div
    id="dropdownRadio"
    class="z-10 hidden w-48 bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600"
    data-popper-reference-hidden=""
    data-popper-escaped=""
    data-popper-placement="top"
    style="
      position: absolute;
      inset: auto auto 0px 0px;
      margin: 0px;
      transform: translate3d(522.5px, 3847.5px, 0px);
    "
  >
    <ul
      class="p-3 space-y-1 text-sm text-gray-700 dark:text-gray-200"
      aria-labelledby="dropdownRadioButton"
    >
      <li v-for="(item, index) in avaliable_options" :key="index">
        <div
          @click="changeSearch(item)"
          class="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600"
        >
          <label
            for="filter-radio-example-1"
            class="w-full ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300"
            >{{ item.label }}</label
          >
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { initDropdowns } from "flowbite";
export default {
  props: ["current_search", "avaliable_options"],
  methods: {
    changeSearch(data) {
      this.$emit("changeSearch", data);
    },
  },
  mounted() {
    initDropdowns();
  },
};
</script>

<style></style>
