<template>
  <div
    class="p-4 min-h-screen flex-col items-center justify-between border rounded-lg shadow-sm border-gray-700 sm:p-6 bg-gray-800"
  >
    <!-- Header -->
    <div class="w-full mb-1">
      <div class="mb-4">
        <h1 class="text-xl font-semibold sm:text-2xl text-white">User</h1>
      </div>
    </div>
    <!-- Header -->

    <div class="dark w-full">
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
        <!-- Search -->
        <div class="flex p-4 items-center justify-start pb-4">
          <label for="table-search" class="sr-only">Search</label>
          <div class="relative">
            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                class="w-5 h-5 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div>
            <input
              v-model="current_search.value"
              @input="handleSearch"
              type="text"
              id="table-search-users"
              class="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Search for users"
            />
          </div>
          <SearchDropdown
            @changeSearch="this.current_search = $event"
            :avaliable_options="search_options"
            :current_search="current_search"
          />
        </div>
        <!-- Search -->
        <div class="w-full p-10">
          <div class="flex p-2 items-center justify-center pb-4">
            <Pagination
              @paginate="this.getData($event)"
              :total_pages="total_pages"
              :current_page="current_page"
            />
          </div>
          <!-- Table -->
          <table class="w-fulltext-sm w-full text-left text-gray-500 dark:text-gray-400">
            <thead
              class="bg-gray-50 dark:bg-gray-700 p-4 text-base font-medium text-gray-900 whitespace-nowrap dark:text-white"
            >
              <tr>
                <th scope="col" class="p-4"></th>
                <th scope="col" class="px-6 py-3">Benutzername</th>
                <th scope="col" class="px-6 py-3">E-Mail</th>
                <th scope="col" class="px-6 py-3">Status</th>
                <th scope="col" class="px-6 py-3"></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="user in data"
                :key="user.appuser_id"
                class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
              >
                <td class="w-4 p-4"></td>
                <th
                  scope="row"
                  class="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white"
                >
                  <VLazyImage
                    v-if="user.imagesrc_small"
                    class="w-10 h-10 rounded-full"
                    :src="
                      'https://devel.kremer-marketing.de' +
                      '/image/search/' +
                      user.imagesrc_small +
                      '?mode=tiny'
                    "
                    alt=""
                  />
                  <VLazyImage
                    v-else
                    class="w-10 h-10 rounded-full"
                    :src="'https://uxwing.com/wp-content/themes/uxwing/download/peoples-avatars/no-profile-picture-icon.png'"
                    alt=""
                  />
                  <!-- <img
                    class="w-10 h-10 rounded-full"

                    alt="https://uxwing.com/wp-content/themes/uxwing/download/peoples-avatars/no-profile-picture-icon.png"
                  /> -->
                  <div class="pl-3">
                    <div class="text-base font-semibold">{{ user.profile_nickname }}</div>
                    <!-- <div class="font-normal text-gray-500">
                      {{ user.appuser_email }}
                    </div> -->
                  </div>
                </th>
                <td class="px-6 py-4">
                  {{ user.appuser_email }}
                </td>
                <td class="px-6 py-4">
                  <div v-if="user.appuser_socket_login" class="flex items-center">
                    <div class="h-2.5 w-2.5 rounded-full bg-green-500 mr-2"></div>
                    Online
                  </div>
                  <div v-if="!user.appuser_socket_login" class="flex items-center">
                    <div class="h-2.5 w-2.5 rounded-full bg-red-500 mr-2"></div>
                    Offline
                  </div>
                </td>
                <td class="">
                  <button
                    @click="openUserModal(user.appuser_id)"
                    id="editUserModal"
                    type="button"
                    class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    <svg
                      aria-hidden="true"
                      class="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/Dashboard/Ui/Pagination.vue";
import VLazyImage from "v-lazy-image";
import SearchDropdown from "@/components/Dashboard/Ui/SearchDropdown.vue";
import { mapActions } from "vuex";
export default {
  components: {
    Pagination,
    VLazyImage,
    SearchDropdown,
  },

  data() {
    return {
      data: "",
      total_pages: 0,
      current_page: 1,
      per_page: 20,
      total_count: 0,
      search_options: [
        {
          column: "profile_nickname",
          label: "Benutzername",
        },
        {
          column: "appuser_email",
          label: "E-Mail",
        },
      ],
      current_search: {
        value: "",
        column: "profile_nickname",
        label: "Benutzername",
      },
    };
  },
  computed: {},
  methods: {
    ...mapActions("dashboard", ["openUserModal"]),
    async getData(page = 1) {
      this.current_page = page;
      var response = await this.$API.post("/admin/user", {
        page: this.current_page,
        per_page: this.per_page,
        search_column: this.current_search.column,
        search_value: this.current_search.value,
      });
      if (response.status === 200) {
        this.data = response.data.results;
        (this.total_pages = response.data.pages), (this.total_count = response.data.total);
      }
    },
    async handleSearch() {
      if (this.current_search.value.length > 3 || this.current_search.value.length === 0) {
        await this.getData();
        return;
      }
    },
  },
  async mounted() {
    this.getData();
  },
};
</script>

<style></style>
