.
<template>
  <div class="options">
    <span
      v-show="PaymentStatus"
      @click="toggleChatInbox"
      class="material-icons-outlined chatpng"
      :class="{ unseen: this.GET_UNSEEN }"
    >
      {{ this.chatIcon }}
    </span>
    <div class="textbox">
      <p
        v-if="LoadingDone && LoginStatus !== true"
        class="login"
        id="login"
        @click="showLoginModal"
      >
        Login
      </p>
      <p
        v-if="LoadingDone && LoginStatus !== true"
        class="login"
        id="register"
        @click="showRegisterModal"
      >
        Registrieren
      </p>
      <p
        v-if="LoadingDone && LoginStatus === true"
        class="login"
        id="logout"
        @click="logoutAction"
      >
        Abmelden
      </p>
      <p
        class="settings"
        v-if="LoadingDone && LoginStatus !== false"
        @click="showOptionsModal"
      >
        Einstellungen
      </p>
      <p class="darkmode">
        Dunkles Design
        <input
          @change="toggleTheme"
          type="checkbox"
          v-model="checked"
          name="switch"
          id="switch"
        />
        <label for="switch"></label>
      </p>
    </div>
  </div>
  <div class="chat">
    <div class="chatbox">
      <i class="fas fa-comment-alt-exclamation"></i>
      <div class="info">
        <p @click="showImpressumModal">Impressum</p>
        <p @click="showDatenschutzModal">Datenschutz</p>
        <p @click="showAgbModal">AGB</p>
        <p @click="showAffliateModal">Partner</p>
        <p @click="toAdmin" v-if="IsAdmin">Admin</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      theme: '',
      checked: true,
    };
  },
  computed: {
    ...mapGetters('auth', ['LoginStatus', 'PaymentStatus', 'LoadingDone', 'IsAdmin']),
    ...mapGetters('modal', ['LoadingStatus']),
    ...mapGetters('chat', ['GET_UNSEEN']),
    chatIcon: function () {
      if (this.GET_UNSEEN) return 'feedback';
      return 'chat_bubble_outline';
    },
  },
  methods: {
    toggleTheme() {
      if (this.checked) {
        this.theme = 'darkMode';
      } else {
        this.theme = 'root';
      }
      this.$cookies.set('theme', this.theme, '1y');
      document.documentElement.setAttribute('data-theme', this.theme);
    },
    toAdmin() {
      this.$router.push({ name: 'Dashboard' });
    },
    ...mapActions('modal', [
      'showLoginModal',
      'showOptionsModal',
      'showRegisterModal',
      'showImpressumModal',
      'showDatenschutzModal',
      'showAgbModal',
      'showAffliateModal',
      'toggleChatInbox',
    ]),
    ...mapActions('auth', ['logout']),
    ...mapActions('search', ['search_action']),
    async logoutAction() {
      await this.logout();
      this.search_action();
    },
  },
  beforeMount() {
    var theme = this.$cookies.get('theme');
    document.documentElement.setAttribute('data-theme', theme);

    if (theme === 'darkMode') {
      this.checked = true;
    } else {
      this.checked = false;
    }
  },
};
</script>

<style scoped>
.options {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 17vw;
  height: clamp(10rem, 10vw, 15rem);
}
.textbox {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: column;
  height: clamp(8rem, 8vw, 13rem);
}
.login,
.settings {
  font-size: clamp(1px, 1.1vw, 40px);
  cursor: pointer;
  color: var(--font-color);
}
.login:hover,
.settings:hover {
  transform: scale(1.01);
  color: #ed1c24;
}
.darkmode {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--font-color);
  font-size: clamp(1px, 0.9vw, 30px);
  -ms-user-select: None;
  -moz-user-select: None;
  -webkit-user-select: None;
  user-select: None;
}
.switch {
  position: relative;
  display: inline-block;
  width: 2rem;
  height: 1rem;
  margin: 0.2rem 0 0 0.5rem;
}
input[type='checkbox'] {
  width: 0;
  height: 0;
  visibility: hidden;
}
label {
  width: 2rem;
  height: 1rem;
  display: block;
  background-color: #ededef;
  border-radius: 100px;
  position: relative;
  cursor: pointer;
  transition: 0.5s;
  border: solid 1px black;
  top: 0.1rem;
  left: 0.3rem;
}
label::after {
  content: '';
  width: 0.8rem;
  height: 0.8rem;
  background-color: black;
  position: absolute;
  border-radius: 70px;
  top: 0.1rem;
  left: 0.1rem;
  transition: 0.5s;
}
input:checked + label:after {
  left: calc(100% - 0.1rem);
  transform: translateX(-100%);
  background-color: #ed1c24;
}
input:checked + label {
  background-color: black;
  border: solid 1px #ed1c24;
}

.chat {
  display: flex;
  align-items: center;
  width: 5vw;
  height: clamp(10rem, 10vw, 15rem);
}
.chatbox {
  width: 5vw;
  height: clamp(8rem, 8vw, 13rem);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 0 0 3rem 0;
}
.chatpng {
  cursor: pointer;
  font-size: clamp(12px, 2vw, 50px);
  color: var(--font-color);
  font-weight: 100;
  -ms-user-select: None;
  -moz-user-select: None;
  -webkit-user-select: None;
  user-select: None;
}
.unseen {
  color: red;
  animation: pulse 5s infinite;
}

@keyframes pulse {
  0% {
    color: white;
  }
  100% {
    color: #ff4136;
  }
}

.info {
  padding: 0rem 0 0 0;
}
.info p {
  font-size: clamp(12px, 0.8vw, 20px);
  cursor: pointer;
  color: var(--font-color);
  word-break: break-all;
}
.info p:hover,
.chatpng:hover {
  transform: scale(1.1);
  color: #ed1c24;
}
</style>
