<template>
  <BasicModal
    :title="'Passwort vergessen'"
    :width="'65rem'"
    :height="'75vh'"
    :view="PasswordForgotStatus.status"
  >
    <div v-if="!PasswordForgotStatus.changePassword" class="reset-password">
      <div @keyup.enter="emailSubmit">
        <FormField
          title="E-Mail"
          :icon="'fas fa-envelope'"
          @inputError="this.emailForm.appuser_email.error = $event"
          v-model="emailForm.appuser_email.value"
          :value="this.emailForm.appuser_email.value"
          :class="{ error: this.emailForm.appuser_email.error }"
          :validations="validations.appuser_email"
          :error="this.emailForm.appuser_email.error"
        />
      </div>
    </div>

    <div v-if="PasswordForgotStatus.changePassword" class="change-password">
      <div @keyup.enter="emailSubmit">
        <FormField
          title="Neues Passwort"
          type="password"
          @inputError="this.passwordForm.appuser_newpassword.error = $event"
          v-model="passwordForm.appuser_newpassword.value"
          :value="this.passwordForm.appuser_newpassword.value"
          :class="{ error: this.passwordForm.appuser_newpassword.error }"
          :validations="validations.appuser_newpassword"
          :error="this.passwordForm.appuser_newpassword.error"
        />

        <FormField
          title="Passwort wiederholen"
          type="password"
          @inputError="this.passwordForm.appuser_newpassword_confirm.error = $event"
          v-model="passwordForm.appuser_newpassword_confirm.value"
          :value="this.passwordForm.appuser_newpassword_confirm.value"
          :class="{ error: this.passwordForm.appuser_newpassword_confirm.error }"
          :validations="validations.appuser_newpassword_confirm"
          :error="this.passwordForm.appuser_newpassword_confirm.error"
        />
      </div>
    </div>
    <FormButtonContainer>
      <FormSubmit
        v-if="!PasswordForgotStatus.changePassword"
        @click="emailSubmit"
        :submitText="'Passwort zurücksetzen'"
      />
      <FormSubmit
        @click="back"
        v-if="!PasswordForgotStatus.changePassword"
        :submitText="'Zurück'"
      />
      <FormSubmit
        v-if="PasswordForgotStatus.changePassword"
        @click="pwSubmit"
        :submitText="'Passwort ändern'"
      />
    </FormButtonContainer>
  </BasicModal>
</template>

<script>
import FormField from "@/components/Form/FormField.vue";
import FormButtonContainer from "@/components/Form/FormButtonContainer.vue";
import BasicModal from "@/components/Ui/BasicModal.vue";
import FormSubmit from "@/components/Form/FormSubmit.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    FormField,
    BasicModal,
    FormButtonContainer,
    FormSubmit,
  },
  props: {},
  data() {
    return {
      emailForm: {
        appuser_email: {
          value: "",
          error: false,
        },
      },
      passwordForm: {
        appuser_newpassword: {
          value: "",
          error: false,
        },
        appuser_newpassword_confirm: {
          value: "",
          error: false,
        },
      },
      validations: {
        appuser_email: {
          email: true,
          required: true,
        },
        appuser_newpassword: {
          required: true,
        },
        appuser_newpassword_confirm: {
          required: true,
        },
      },

      //modal
      servererror: "",
    };
  },
  computed: {
    ...mapGetters("modal", ["PasswordForgotStatus"]),
    emailFormHasErrors: function () {
      const formFields = [this.emailForm.appuser_email.error];
      return formFields.some((element) => element === true);
    },
    passwordFormHasErrors: function () {
      const formFields = [
        this.passwordForm.appuser_newpassword.error,
        this.passwordForm.appuser_newpassword_confirm.error,
      ];
      return formFields.some((element) => element === true);
    },
  },
  methods: {
    ...mapActions("modal", [
      "showLoginModal",
      "showPasswordForgot",
      "showSubmitModal",
      "hidePasswordForgot",
      "hidePasswordChange",
    ]),
    back() {
      this.hidePasswordForgot();
      this.showLoginModal();
    },
    notoken() {
      this.showSubmitModal({
        status: "error",
        time: 3000,
        text: "Es ist ein Fehler aufgetreten versuche es erneut!",
      });
      this.hidePasswordChange();

      // Reset to default
      this.passwordForm.appuser_newpassword.value = "";
      this.passwordForm.appuser_newpassword_confirm.value = "";
    },
    async pwSubmit() {
      // If no token in the url exit here
      if (!this.$route.params.pwresettoken) {
        this.notoken();
        return;
      }
      // Check if form has validation errors
      this.emitter.emit("checkAll");
      if (this.passwordFormHasErrors === true) {
        this.showSubmitModal({
          status: "error",
          time: 3000,
          text: "Prüfe deine Eingabe!",
        });
        return;
      }

      if (
        this.passwordForm.appuser_newpassword.value !==
        this.passwordForm.appuser_newpassword_confirm.value
      ) {
        this.showSubmitModal({
          status: "error",
          time: 3000,
          text: "Passwörter stimmen nicht überein",
        });
        return;
      }

      try {
        let url = "/login/changepassword/" + this.$route.params.pwresettoken;
        await this.$API.post(url, { password: this.passwordForm.appuser_newpassword.value });
        this.hidePasswordChange();

        // Reset to default
        this.passwordForm.appuser_newpassword.value = "";
        this.passwordForm.appuser_newpassword_confirm.value = "";

        // Remove token from URL
        this.$router.replace("/");

        // SuccessModal
        this.showSubmitModal({
          status: "success",
          time: 3000,
          text: "Dein Password wurde zurückgesetzt. Bitte logge dich ein",
        });

        this.hidePasswordForgot();
        this.showLoginModal();

        // Handle responses
      } catch (error) {
        switch (error.response.data.message) {
          case "EMAIL_NOT_EXISTS":
            this.showSubmitModal({
              status: "error",
              time: 3000,
              text: "E-Mail-Adresse existiert nicht!",
            });
            break;

          default:
            this.servererror =
              "Der Dienst ist aktuell nicht verfügbar. Bitte versuche es später nochmal";
            break;
        }
      }

      return;
    },
    async emailSubmit() {
      // Check if form has validation errors
      this.emitter.emit("checkAll");
      if (this.emailFormHasErrors === true) {
        this.showSubmitModal({
          status: "error",
          time: 3000,
          text: "Prüfe deine Eingabe!",
        });
        return;
      }

      try {
        await this.$API.post("/login/resetpassword", {
          email: this.emailForm.appuser_email.value.toLowerCase().trim(),
        });
        this.showSubmitModal({
          status: "success",
          time: 5000,
          text: "Bitte schaue in deinem Postfach um dein Passwort zurückzusetzen.",
        });

        // Handle responses
      } catch (error) {
        switch (error.response.data.message) {
          case "EMAIL_NOT_EXISTS":
            this.servererror = "E-Mail-Adresse existiert nicht!";
            break;

          default:
            this.servererror =
              "Der Dienst ist aktuell nicht verfügbar. Bitte versuche es später nochmal";
            break;
        }
        this.showSubmitModal({
          status: "error",
          time: 5000,
          text: this.servererror,
        });
      }
    },
  },
};
</script>

<style scoped>
.reset-password,
.change-password {
  background-color: var(--settings-box);
  border: solid #707070 1px;
  width: 100%;
}
</style>
