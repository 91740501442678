<template>
  <div
    class="min-h-screen flex-col items-center justify-between rounded-lg border border-gray-700 bg-gray-800 p-4 shadow-sm sm:p-6"
  >
    <!-- Header -->
    <div class="mb-1 w-full">
      <div class="mb-4">
        <h1 class="text-xl font-semibold text-white sm:text-2xl">X2</h1>
      </div>
    </div>
    <!-- Header -->

    <div class="dark w-full">
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
        <!-- Search -->
        <div class="flex items-center justify-start p-4 pb-4">
          <label for="table-search" class="sr-only">Search</label>
          <div class="relative">
            <div
              class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
            >
              <svg
                class="h-5 w-5 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div>
            <input
              v-model="current_search.value"
              @input="handleSearch"
              type="text"
              id="table-search-users"
              class="block w-80 rounded-lg border border-gray-300 bg-gray-50 p-2 pl-10 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
              placeholder="Search for users"
            />
          </div>
          <SearchDropdown
            @changeSearch="this.current_search = $event"
            :avaliable_options="search_options"
            :current_search="current_search"
          />
        </div>

        <!-- Buttons -->
        <div class="w-full p-10">
          <div class="grid grid-cols-10 gap-4">
            <div class="col-span-10 md:col-span-5">
              <div class="flex items-center justify-start p-2 pb-4">
                <button
                  @click="handleSave"
                  type="button"
                  class="focus:outline-none mb-2 mr-2 rounded-lg bg-blue-700 px-5 py-2.5 text-sm font-medium text-white hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Speichern
                </button>

                <button
                  @click="handleInsert(this.form.length)"
                  type="button"
                  class="focus:outline-none mb-2 mr-2 inline-flex items-center rounded-lg bg-green-600 px-5 py-2.5 text-sm font-medium text-white hover:bg-green-500 focus:ring-4 focus:ring-green-300"
                >
                  <svg
                    aria-hidden="true"
                    class="h-5 w-5"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="1.5"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 4.5v15m7.5-7.5h-15"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </button>

                <!-- Dropdown menu -->
                <button
                  id="dropdownDefaultButton"
                  data-dropdown-toggle="dropdown"
                  class="focus:outline-none dark:bg-grey-600 mb-2 mr-2 inline-flex items-center rounded-lg border border-gray-600 bg-gray-800 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-gray-700 focus:ring-4 focus:ring-gray-700"
                  type="button"
                >
                  {{ root_category.selected.category_name }}
                  <svg
                    class="ml-2 h-4 w-4"
                    aria-hidden="true"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M19 9l-7 7-7-7"
                    ></path>
                  </svg>
                </button>

                <div
                  id="dropdown"
                  class="z-10 hidden w-44 divide-y divide-gray-100 rounded-lg bg-white shadow dark:bg-gray-700"
                >
                  <ul
                    class="py-2 text-sm text-gray-700 dark:text-gray-200"
                    aria-labelledby="dropdownDefaultButton"
                  >
                    <li
                      v-for="category in root_category.options"
                      :key="category.category_id"
                      @click="root_category.selected = category"
                    >
                      <button
                        class="block w-full px-4 py-2 hover:bg-gray-100 focus:bg-gray-500 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        {{ category.category_name }}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <!-- Pagination -->
            <div class="col-span-10 md:col-span-5">
              <div class="flex items-center justify-start p-2 pb-4">
                <Pagination
                  @paginate="
                    this.getData($event);
                    this.resetOperations();
                  "
                  :total_pages="total_pages"
                  :current_page="current_page"
                />
              </div>
            </div>
          </div>

          <!-- Table -->
          <table class="w-fulltext-sm w-full text-left text-gray-500 dark:text-gray-400">
            <thead
              class="whitespace-nowrap bg-gray-50 p-4 text-base font-medium text-gray-900 dark:bg-gray-700 dark:text-white"
            >
              <tr>
                <th scope="col" class="p-4"></th>
                <th scope="col" class="px-6 py-3">Name</th>
                <th scope="col" class="px-6 py-3">Anzahl Verknüpfte Profile</th>
                <th scope="col" class="px-2 py-3"></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="category in form"
                :key="category.category_id"
                class="border-b bg-white hover:bg-gray-50 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-600"
              >
                <!-- Category Name -->
                <td class="w-4 p-4"></td>
                <td class="px-6 py-3">
                  <input
                    type="text"
                    :disabled="category.type === 'delete'"
                    v-model="category.value"
                    @change="
                      handleInput(category.value, category.category_name, category);
                      category.error = category.value.length === 0;
                    "
                    id="category"
                    class="block w-full rounded-lg border-2 bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:shadow-sm-light"
                    :class="{
                      'border-red-700':
                        category.value.length === 0 || category.type === 'delete',
                      'border-yellow-400': category.value !== category.category_name,
                      'focus:border-red-700':
                        category.value.length === 0 || category.type === 'delete',
                      'focus:ring-red-700':
                        category.value.length === 0 || category.type === 'delete',
                      'focus:border-blue-500':
                        category.value.length > 0 && !category.type,
                      'focus:ring-blue-500': category.value.length > 0 && !category.type,
                      'border-gray-700': category.value.length > 0,
                      'border-green-400':
                        category.type === 'insert' && category.value.length > 0,
                      'focus:border-green-500 focus:ring-green-500':
                        category.type === 'insert' && category.value.length > 0,
                    }"
                    required
                  />
                </td>

                <!-- Profile Count -->
                <td class="px-6 py-4 text-base font-semibold">
                  {{ category.category_profile_count }}
                </td>
                <td class="px-2 py-3">
                  <button
                    v-if="category.type !== 'delete' && category.type !== 'update'"
                    @click="handleDelete(category)"
                    type="button"
                    class="focus:outline-none inline-flex items-center rounded-lg bg-red-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-red-500 focus:ring-4 focus:ring-red-300"
                  >
                    <svg
                      class="h-5 w-5"
                      aria-hidden="true"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="1.5"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  </button>

                  <button
                    v-if="category.type === 'delete'"
                    @click="handleDeleteToggle(category)"
                    type="button"
                    class="focus:outline-none inline-flex items-center rounded-lg bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    <svg
                      aria-hidden="true"
                      class="h-5 w-5"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="1.5"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 18L18 6M6 6l12 12"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  </button>
                  <button
                    v-if="category.type === 'update'"
                    @click="handleUpdateToggle(category)"
                    type="button"
                    class="focus:outline-none inline-flex items-center rounded-lg bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    <svg
                      aria-hidden="true"
                      class="h-5 w-5"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="1.5"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 18L18 6M6 6l12 12"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/Dashboard/Ui/Pagination.vue';
import SearchDropdown from '@/components/Dashboard/Ui/SearchDropdown.vue';
import { mapActions } from 'vuex';
export default {
  components: {
    Pagination,
    SearchDropdown,
  },

  data() {
    return {
      data: '',
      total_pages: 0,
      current_page: 1,
      per_page: 20,
      total_count: 0,
      search_options: [
        {
          column: 'category_name',
          label: 'Kategorie',
        },
      ],
      current_search: {
        value: '',
        column: 'category_name',
        label: 'Kategorie',
      },
      root_category: {
        options: [],
        selected: '',
      },
      operations: {
        insert: [],
        update: [],
        delete: [],
        invalid: [],
      },
    };
  },
  computed: {
    form: function () {
      // Need to create a form obj to mutate data
      var form = [];
      for (let i = 0; i < this.data.length; i++) {
        var obj = this.data[i];
        if ('value' in obj) {
          if (obj['value'].length === 0) obj['error'] = true;
          form.push(obj);
          continue;
        }
        obj['value'] = obj.category_name;
        if (obj['value'].length === 0) obj['error'] = true;
        else obj['error'] = false;
        form.push(obj);
      }
      return form;
    },
    operationsLength: function () {
      return (
        this.operations.update.length +
        this.operations.insert.length +
        this.operations.delete.length
      );
    },
  },
  methods: {
    ...mapActions('dashboard', ['openReportDrawer']),
    ...mapActions('modal', ['showSubmitModal']),
    async getData(page = 1) {
      this.current_page = page;
      var response = await this.$API.post('/admin/x2/all', {
        root_category_id: this.root_category.selected.category_id,
        page: this.current_page,
        per_page: this.per_page,
        search_column: this.current_search.column,
        search_value: this.current_search.value,
      });
      if (response.status === 200) {
        this.data = response.data.results;
        (this.total_pages = response.data.pages),
          (this.total_count = response.data.total);
      }
    },
    async handleSearch() {
      if (
        this.current_search.value.length > 3 ||
        this.current_search.value.length === 0
      ) {
        await this.getData();
        this.resetOperations();
        return;
      }
    },
    handleInput(newVal, oldVal, obj) {
      if (newVal !== oldVal && newVal.length > 0) {
        // Insert
        if (!obj.category_id) {
          list = this.operations.insert;
          index = list.findIndex((element) => element.row === obj.row);
          // Create or update existing entrys in insert operations
          if (index !== -1) {
            const target = list[index];
            target.value = newVal;
          } else list.push({ id: 0, value: newVal, row: obj.row });
          return;
        }

        // Update
        var list = this.operations.update;
        var index = list.findIndex((element) => element.id === obj.category_id);
        // Create or update existing entrys in update operations
        if (index !== -1) {
          const target = list[index];
          target.value = newVal;
        } else list.push({ id: obj.category_id, value: newVal });

        // Need to update the type too in the form
        var dataObj = this.data.find(
          (element) => element.category_id === obj.category_id
        );
        dataObj['type'] = 'update';
      }
    },
    handleInsert(row) {
      this.data.push({ category_id: '', category_name: '', type: 'insert', row: row });
    },
    handleDelete(category) {
      if (category.type === 'insert') {
        this.operations.insert = this.operations.insert.filter(
          (element) => element.value !== category.value && element.row !== category.row
        );
        this.data = this.data.filter((element) => element.row !== category.row);
        return;
      }
      this.operations.delete.push({ id: category.category_id });
      var dataObj = this.data.find(
        (element) => element.category_id === category.category_id
      );
      dataObj['type'] = 'delete';
    },
    handleDeleteToggle(category) {
      this.operations.delete = this.operations.delete.filter(
        (element) => element.id !== category.category_id
      );
      var dataObj = this.data.find(
        (element) => element.category_id === category.category_id
      );
      dataObj['type'] = '';
    },
    handleUpdateToggle(category) {
      this.operations.update = this.operations.delete.filter(
        (element) => element.id !== category.category_id
      );
      var dataObj = this.data.find(
        (element) => element.category_id === category.category_id
      );
      dataObj['type'] = '';
      dataObj['value'] = dataObj['category_name'];
    },

    resetOperations() {
      this.operations.update = [];
      this.operations.insert = [];
      this.operations.delete = [];
    },

    async handleSave() {
      if (this.operationsLength === 0) {
        this.showSubmitModal({
          status: 'error',
          time: 2000,
          text: 'keine Änderungen!',
        });
        return;
      }
      var response = await this.$API.post('/admin/x2/edit', {
        root_category_id: this.root_category.selected.category_id,
        insert: this.operations.insert,
        update: this.operations.update,
        delete: this.operations.delete,
      });
      if (response.status === 200) {
        this.resetOperations();
        this.getData();

        if (response.data.length > 0) {
          response.data.forEach((element) =>
            this.showSubmitModal({
              status: 'info',
              time: 15000,
              text: element,
            })
          );
        } else {
          this.showSubmitModal({
            status: 'success',
            time: 2000,
            text: '',
          });
        }
      }
    },
    async fetchX1Options() {
      var response = await this.$API.get('/admin/x1/options');
      if (response.status === 200) {
        this.root_category.options = response.data;
        this.root_category.selected = response.data.find(
          (element) => element.category_id == 1
        );
      }
    },
  },
  watch: {
    'root_category.selected': {
      async handler() {
        this.resetOperations();
        this.getData();
      },
      deep: true, // Überwacht auch Änderungen in geschachtelten Objekten
    },
  },
  async mounted() {
    this.fetchX1Options().then(() => this.getData());
  },
  beforeUnmount() {},
};
</script>

<style></style>
