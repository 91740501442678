<template>
  <div class="modal" v-if="show">
    <!-- Controls the background element to close the modal -->
    <div class="modal-background">
      <!-- <div class="modal-dialog"> -->
      <div class="lds-dual-ring"></div>
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    view: Boolean,
  },
  data() {
    return {
      a: "40rem",
    };
  },
  computed: {
    show: function () {
      return this.view;
    },
  },
};
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 11;
  overflow-x: hidden;
  overflow-y: auto;
}
.modal-background {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.4);
  z-index: 1;
}
.modal-dialog {
  position: relative;
  height: v-bind(dialogheight);
  width: v-bind(dialogwidth);
  margin: 5rem auto;
  display: flex;
  flex-direction: column;
  z-index: 2;
  overflow: scroll;
}
.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid rgb(223, 16, 16);
  border-color: rgb(199, 15, 15) transparent rgb(204, 12, 12) transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
