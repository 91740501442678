import { createStore } from "vuex";
import auth from "./modules/auth";
import modal from "./modules/modal";
import profile from "./modules/profile";
import search from "./modules/search";
import chat from "./modules/chat";
import dashboard from "./modules/dashboard";

const store = createStore({
  modules: {
    auth,
    modal,
    profile,
    search,
    chat,
    dashboard,
  },
});

export default store;
