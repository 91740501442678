import axios from 'axios';
import store from './store/store';
import socket from './utils/socket';

function createInstance(URL) {
  return axios.create({
    baseURL: URL,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });
}
const devInstance = createInstance(process.env.VUE_APP_BASEURL);
devInstance.interceptors.request.use((config) => {
  const token = store.getters['auth/GET_TOKEN'];
  config.headers['Authorization'] = 'Bearer ' + token;
  return config;
});
// const productionInstance = createInstance("http://localhost:5000")  todo: Adjust for production

devInstance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  async (error) => {
    const request = error.config;
    const status = error.response ? error.response.status : null;

    // Check if Access Token is expired, try to refresh
    if (status === 422 && !request._retry) {
      // Set refresh done flag
      request._retry = true;

      try {
        let response = await devInstance.get('/auth/refresh');
        // Commits new Tokens to the Store
        store.commit('auth/SET_USER', response.data, { root: true });
        store.commit('auth/SET_LOGINSTATUS', true);
        // Check if Socket Connection is connected
        if (!socket.connected) {
          socket.auth = response.data.access_token;
          socket.connect();
        }
        // Retry original request
        return devInstance.request(request);
      } catch (err) {
        // rejects the error to be handled again
        // throw new Error("Unauthorized");
      }
    }

    if (status === 401) {
      store.dispatch('modal/hideAllModals', ['passwordForgot'], { root: true });
      console.log('Session expired!');
      store.commit('auth/SET_LOGINSTATUS', false);
      store.dispatch('modal/showLoginModal', { root: true });
    }

    if (status === 402) {
      store.commit('auth/SET_PAYMENTSTATUS', false);
      store.commit('auth/SET_LOGINSTATUS', false);
      store.dispatch('modal/hideAllModals');
      store.dispatch('modal/showPaymentModal', null, { root: true });
    }
    // If refresh fails
    if (status === 403 && request._retry) {
      store.dispatch('modal/hideAllModals', ['passwordForgot'], { root: true });
      console.log('Session expired!');
      store.commit('auth/SET_LOGINSTATUS', false);
      return Promise.reject(error);
    }

    // if (status === 403) {
    //   store.dispatch("modal/hideAllModals");
    //   console.log("Session expired!");
    //   store.commit("auth/SET_LOGINSTATUS", false);
    //   throw new Error("Unauthorized");
    // }

    if (status === 422) {
      store.commit('auth/SET_LOGINSTATUS', false);
      store.dispatch('modal/hideAllModals', ['login'], { root: true });
      throw new Error('Please login!');
    }

    return Promise.reject(error);

    // Do something with response error
    // }
    // console.log("WITHOUT RESPONSE")
    // console.log(error)
    // return Promise.reject(error);
  }
);

export default {
  devInstance,
  // install: function (app) {
  //     // Object.defineProperty(Vue.prototype, '$API', { value: devInstance });
  //     app.config.globalProperties.$API = instance;
};
// Check debug/build mode
