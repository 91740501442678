import {
  createRouter,
  //   createWebHashHistory,
  createWebHistory,
} from 'vue-router';
import ExternalLink from '../views/ExternalLink.vue';
import Home from '../views/Home.vue';
import ImageUpload from '../views/ImageUpload.vue';
import ImageUpload01 from '../views/ImageUpload/01.vue';
import ImageUpload02 from '../views/ImageUpload/02.vue';
import ImageUpload03 from '../views/ImageUpload/03.vue';
import ImageUpload04 from '../views/ImageUpload/04.vue';
import Dashboard from '../views/Dashboard.vue';
import axios from '../APIConfig';
import store from '../store/store';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/external/back',
    name: 'ExternalBack',
    component: Home,
  },

  {
    path: '/register/confirm/:token?',
    name: 'RegisterConfirm',
    component: Home,
  },
  {
    path: '/checkout/success',
    name: 'CheckoutSuccess',
    component: Home,
  },
  {
    path: '/email/reset/confirm/:resettoken?',
    name: 'EmailResetConfirm',
    component: Home,
  },
  {
    path: '/login/resetpassword/confirm/:pwresettoken?',
    name: 'PasswordResetConfirm',
    component: Home,
  },
  {
    path: '/affliate/user/:profile_id?',
    name: 'Affliate',
    component: Home,
  },

  {
    path: '/external/redirect',
    name: 'ExternalLink',
    component: ExternalLink,
  },
  {
    path: '/image/upload',
    name: 'ImageUpload',
    component: ImageUpload,
  },
  {
    path: '/image/upload/01',
    name: 'ImageUpload-01',
    component: ImageUpload01,
  },
  {
    path: '/image/upload/02',
    name: 'ImageUpload-02',
    component: ImageUpload02,
  },
  {
    path: '/image/upload/03',
    name: 'ImageUpload-03',
    component: ImageUpload03,
  },
  {
    path: '/image/upload/04',
    name: 'ImageUpload-04',
    component: ImageUpload04,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

var api = axios.devInstance;
const homeTargets = [
  'Home',
  'ExternalBack',
  'RegisterConfirm',
  'EmailResetConfirm',
  'PasswordResetConfirm',
];

// Before

router.afterEach(async function (to, from) {
  if (to.name == 'Home' && from.name == 'ExternalLink') {
    store.dispatch('search/search_action', null, { root: true });
  }
  if (homeTargets.includes(to.name)) {
    store.dispatch('profile/getProfileData', { root: true });
    store.dispatch('auth/SocketLogin', { root: true });
    store.dispatch('search/search_action', null, { root: true });
  }

  if (to.name == 'Home') {
    if ('notification' in to.params) {
      to.params.notification = JSON.parse(to.params.notification);
    }
  }

  if (to.name == 'Affliate') {
    if ('profile_id' in to.params) {
      store.dispatch('auth/getAffliateUser', to.params.profile_id, { root: true });
      store.dispatch('search/search_action', null, { root: true });
    }
  }

  if (to.name == 'CheckoutSuccess') {
    store.dispatch('modal/showLoginModal', { root: true });
    store.dispatch(
      'modal/showSubmitModal',
      {
        status: 'info',
        time: 5000,
        text: 'Wir verarbeiten deine Zahlung bitte logge dich ein!',
      },
      { root: true }
    );
  }
});

// After

router.beforeEach(async function (to) {
  //   if (homeTargets.includes(to.name)) {

  //   }

  if (to.name == 'Dashboard') {
    var resp = await api.get('/auth/admin');
    if (resp.status === 200) return true;
    return false;
  }

  return true;
});

export default router;
