var dimension = {
  //   small: {
  //     width: {
  //       num: 0.03,
  //       min: 30,
  //       max: 500,
  //     },
  //     height: {
  //       num: 0.03,
  //       min: 30,
  //       max: 500,
  //     },
  //   },
  small: {
    width: {
      num: 0.03,
      min: 30,
      max: 500,
    },
    profileSmall: {
      height: {
        num: 0.03,
        min: 30,
        max: 500,
      },
    },
    profileMedium: {
      height: {
        num: 0.03,
        min: 30,
        max: 500,
      },
    },
    profileLarge: {
      height: {
        num: 0.03,
        min: 30,
        max: 500,
      },
    },
  },
  medium: {
    width: {
      num: 0.06,
      min: 50,
      max: 700,
    },
    profileSmall: {
      height: {
        num: 0.06,
        min: 50,
        max: 700,
      },
    },
    profileMedium: {
      height: {
        num: 0.08,
        min: 66,
        max: 934,
      },
    },
    profileLarge: {
      height: {
        num: 0.09,
        min: 75,
        max: 1050,
      },
    },
  },
  large: {
    width: {
      num: 0.09,
      min: 75,
      max: 1050,
    },
    profileSmall: {
      height: {
        num: 0.09,
        min: 75,
        max: 1050,
      },
    },
    profileMedium: {
      height: {
        num: 0.12,
        min: 99,
        max: 1401,
      },
    },
    profileLarge: {
      height: {
        num: 0.135,
        min: 112,
        max: 1575,
      },
    },
  },
  xlarge: {
    width: {
      num: 0.12,
      min: 100,
      max: 1400,
    },
    profileSmall: {
      height: {
        num: 0.12,
        min: 100,
        max: 1400,
      },
    },
    profileMedium: {
      height: {
        num: 0.16,
        min: 132,
        max: 1868,
      },
    },
    profileLarge: {
      height: {
        num: 0.18,
        min: 150,
        max: 2100,
      },
    },
  },
  test: {
    width: {
      num: 0.12,
      min: 100,
      max: 1400,
    },
    profileSmall: {
      height: {
        num: 0.12,
        min: 100,
        max: 1400,
      },
    },
    profileMedium: {
      height: {
        num: 0.16,
        min: 132,
        max: 1868,
      },
    },
    profileLarge: {
      height: {
        num: 0.24,
        min: 200,
        max: 2800,
      },
    },
  },
};

export default dimension;
