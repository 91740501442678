<template>
  <div @scroll="scroll" class="root">
    <ModalContainer />
    <ProfileWrapper :totalWidth="totalWidth" />
    <Navbar v-if="totalWidth >= 1330" />
    <Navbarsmall v-if="totalWidth <= 1329" />
  </div>
</template>

<script>
import Navbar from '@/components/Navbar/Navbar.vue';
import Navbarsmall from '@/components/Navbar/Navbarsmall.vue';
import ProfileWrapper from '@/components/Profile/ProfileWrapper.vue';
import ModalContainer from '@/components/modals/ModalContainer.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Home',
  components: {
    Navbar,
    Navbarsmall,
    ProfileWrapper,
    ModalContainer,
  },
  data() {
    return {
      totalWidth: 0,
    };
  },
  props: ['notification', 'uploadDone'],
  computed: {
    token: function () {
      return this.$router.params;
    },
  },
  methods: {
    // Mapping
    ...mapActions('profile', ['ProfileLoadAction', 'getProfileData', 'getProfileImage']),
    ...mapActions('auth', ['loginRefresh', 'updateLoadingDone', 'SocketLogin']),
    ...mapGetters('search', ['SEARCHRESPONSE']),
    ...mapGetters('auth', ['PaymentStatus']),
    ...mapActions('modal', [
      'showSubmitModal',
      'hideRegisterModal',
      'showLoginModal',
      'showOptionsModal',
      'hidePasswordChange',
      'showPasswordChange',
      'showPasswordForgot',
      'hidePasswordForgot',
      'explicitOptionsModal',
    ]),
    ...mapActions('search', ['search_action']),

    // initLoading: async function () {
    //   // Start Search without Params
    //   this.search_action();

    //   // Load Personal Profile Data
    //   this.updateLoadingDone(false);

    //   try {
    //     await this.getProfileData();
    //     this.updateLoadingDone(true);
    //   } catch (error) {
    //     this.updateLoadingDone(true);
    //   }

    //   // Try Socket Connection
    //   this.SocketLogin();
    // },
    handleResize() {
      this.totalWidth = window.innerWidth;
    },
  },
  async beforeMount() {
    window.removeEventListener('resize', this.handleResize);
  },
  async mounted() {
    this.totalWidth = window.innerWidth;
    window.addEventListener('resize', this.handleResize);

    if (this.$route.params.notification) {
      this.showSubmitModal(this.$route.params.notification);
      //   this.initLoading();
    }

    // Registration Confirmation token
    if (this.$route.params.token) {
      this.$API
        .get('/register/confirm/' + this.$route.params.token)
        .then(() => {
          this.$router.replace('/');
          //   this.initLoading();
          this.showSubmitModal({
            status: 'success',
            time: 5000,
            text: 'E-Mail erfolgreich bestätigt',
            // callback: this.initLoading,
          });
        })
        .catch(() => {
          this.$router.replace('/');
          this.showSubmitModal({
            status: 'error',
            time: 5000,
            text: 'Üngültiger Link!',
            // callback: this.initLoading,
          });
        });

      return;
    }

    // Password reset token
    if (this.$route.params.pwresettoken) {
      this.$API
        .get('/login/resetpassword/' + this.$route.params.pwresettoken)
        .then(() => {
          this.showPasswordForgot();
          this.showPasswordChange();
        })
        .catch(() => {
          this.$router.replace('/');
          this.showSubmitModal({
            status: 'error',
            time: 5000,
            text: 'Üngültiger Bestätigungslink!',
            // callback: this.initLoading,
          });
        });

      return;
    }

    // E-Mail reset token
    if (this.$route.params.resettoken) {
      this.$API
        .get('/email/reset/confirm/' + this.$route.params.resettoken)
        .then(() => {
          this.$router.replace('/');
          //   this.initLoading();
          this.showSubmitModal({
            status: 'success',
            time: 5000,
            text: 'E-Mail erfolgreich bestätigt',
            // callback: this.initLoading,
          });
        })
        .catch(() => {
          this.$router.replace('/');
          this.showSubmitModal({
            status: 'error',
            time: 5000,
            text: 'Üngültiger Bestätigungslink!',
            // callback: this.initLoading,
          });
        });

      return;
    }

    if (this.$route.params.uploadDone) {
      this.showOptionsModal();
      this.showSubmitModal({
        status: 'info',
        time: 5000,
        text: 'Upload abgeschlossen',
      });
    }

    // if (this.$route.params.Settings) {
    //   this.showOptionsModal();
    //   //   this.showSubmitModal({
    //   //     status: 'info',
    //   //     time: 5000,
    //   //     text: 'Zurück',
    //   //   });
    // }

    // Handle Route Params if we come back
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
