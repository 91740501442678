<template>
  <button type="button" :class="{ disabled: this.disabled }" v-if="this.submitText" class="save">
    {{ submitText }}
  </button>
  <button v-else type="button" :class="{ disabled: this.disabled }" class="save">
    Änderungen speichern
  </button>
</template>

<script>
export default {
  props: {
    submitText: String,
    disabled: {
      type: Boolean,
      // Unlike object or array default, this is not a factory function - this is a function to serve as a default value
      default() {
        return false;
      },
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.save {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12rem;
  height: 2.5rem;
  background-color: var(--settings-input);
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: clamp(12px, 1rem, 50px);
  color: var(--font-color);
}
.save:hover {
  background-color: #ed1c24;
}

.save.disabled {
  opacity: 0.5;
  background-color: var(--settings-input);
  cursor: not-allowed;
}

@media only screen and (max-width: 1000px) {
  .btnfield {
    height: 3.5rem;
  }
}
</style>
