<template>
  <Transition>
    <div class="modal" v-if="show">
      <!-- Controls the background element to close the modal -->
      <div class="modal-background" @click="this.close" />
      <div class="modal-dialog" :draggable="dialogdraggable">
        <!-- header -->
        <div class="modal-header">
          <!-- row -->
          <div class="modal-header-row">
            <div class="modal-header-close">
              <em @click="this.close" class="fas fa-times"></em>
            </div>
            <div class="modal-header-headline">
              <p>{{ title }}</p>
            </div>
          </div>

          <!-- row -->
          <div v-if="withNav" class="modal-header-row">
            <div class="navigation-container">
              <div class="tabs-navigation">
                <input ref="checkmark" class="menu-btn" type="checkbox" id="menu-btn" />
                <label class="menu-icon" for="menu-btn">
                  <span class="navicon"></span>
                </label>
                <ul class="menu">
                  <div v-for="(tab, index) in tabData.tabs" :key="index">
                    <li
                      :class="{
                        'is-active': tabData.currentTab.component === tab.component,
                      }"
                      @click="
                        navigate(JSON.parse(JSON.stringify(tab)));
                        this.closeMenue();
                      "
                      :id="tab.component"
                      class="menupoints"
                    >
                      {{ tab.name }}
                    </li>
                  </div>
                </ul>
              </div>
              <div class="btn-wrapper">
                <Switch />
                <div v-if="withSave" @click="save" class="save">
                  <i class="far fa-save"></i>
                </div>
                <div v-if="withPreview" @click="preview" class="save">
                  <i class="far fa-eye"></i>
                </div>
              </div>
            </div>

            <!-- border row -->
          </div>
          <div class="modal-header-lines" v-if="this.withHeadline">
            <div class="redline"></div>
          </div>
        </div>

        <!-- body -->
        <div class="modal-body">
          <slot></slot>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Switch from '@/components/Ui/switch.vue';

export default {
  props: {
    title: {
      type: String,
      default: () => '',
    },
    width: {
      type: String,
      default: () => '70rem',
    },
    height: {
      type: String,
      default: () => '70vh',
    },
    withHeadline: {
      type: Boolean,
      default: () => true,
    },
    draggable: {
      type: Boolean,
      default: () => false,
    },
    view: Boolean,
    close: {
      type: Function,
      default() {
        return this.hideAllModals();
      },
    },
    withNav: {
      type: Boolean,
      default: () => false,
    },
    tabData: {
      type: Object,
    },
    withSave: {
      type: Boolean,
      default: () => false,
    },
    withPreview: {
      type: Boolean,
      default: () => false,
    },
  },
  components: {
    Switch,
  },
  data() {
    return {
      initialWindowHeight: 0,
    };
  },
  computed: {
    show: function () {
      return this.view;
    },
    dialogwidth: function () {
      return this.width;
    },
    dialogheight: function () {
      return this.height;
    },
    dialogdraggable: function () {
      return this.draggable;
    },
    ...mapGetters('auth', ['GET_PROFILE']),
  },
  methods: {
    ...mapActions('modal', ['showLoginModal', 'hideLoginModal', 'hideAllModals']),
    ...mapActions('search', ['profileClickActive']),
    ...mapActions('profile', ['toggleProfilePublic', 'fetchProfilePublic']),
    closeMenue: function () {
      this.$refs.checkmark.checked = false;
    },
    navigate: function (tab) {
      this.$emit('currentTab', tab);
    },
    save: async function () {
      this.$emit('save');
    },
    preview: function () {
      this.profileClickActive(this.GET_PROFILE);
    },
  },
  mounted() {},
  unmounted() {},
};
</script>

<style scoped>
/* dialog transition */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.redline {
  width: 55rem;
  height: 2.6rem;
  border-bottom: solid 1px #ed1c24;
  z-index: 3;
}

.mainbox {
  width: 58rem;
  margin: 3rem 0 0 0;
  background-color: var(--settings-box);
  border: solid #707070 1px;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
  overflow-x: hidden;
  overflow-y: auto;
}
.modal-background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.4);
  z-index: 1;
}
.modal-dialog {
  position: relative;
  height: 80vh;
  /* max-height: v-bind(dialogheight); */
  width: v-bind(dialogwidth);
  background-color: var(--background-index-color);
  box-shadow: 0 3px 6px black;
  margin: 5rem auto;
  padding: 0 0 1.5rem 0;
  display: flex;
  flex-direction: column;
  z-index: 2;
  overflow: scroll;
}

.modal-body {
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 1;
  padding: 0rem 2.5rem 0 3.5rem;
  background-color: var(--background-index-color);
}

.modal-header {
  display: flex;
  position: sticky;
  top: 0;
  padding-top: 1rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* height: 3rem; */
  z-index: 9999;
  background-color: var(--background-index-color);
  margin-bottom: 1rem;
}

.modal-header-row {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.modal-header-headline {
  display: flex;
  background-color: var(--background-index-color);
  justify-content: center;
  width: 100%;
  font-size: clamp(12px, 1.8rem, 50px);
  font-weight: 900;
  font-family: 'Montserrat';
  color: var(--font-color);
}

.modal-header-close {
  position: absolute;
  display: flex;
  font-size: 1.7rem;
  right: 1rem;
  /* margin-right: 3rem; */
  /* justify-content: flex-end; */
  color: var(--font-color);
  background-color: var(--background-index-color);
  /* margin-top: 1.9rem; */
}

/* modal-header-row */

.navigation-container {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 60% 40%;
  height: 4rem;
  grid-template-areas: 'nav save';
  border-bottom: solid 1px #ed1c24;
}

/* modal-header-row */

.modal-header-lines {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
@media only screen and (max-width: 1122px) {
  html {
    font-size: 13px;
  }

  .modal-body {
    padding: 0rem 0.5rem 0rem 0.5rem;
  }

  .modal-dialog {
    width: 100%;
  }
  .redline,
  .mainbox {
    width: 95%;
  }
}
.btn-wrapper {
  display: flex;
  justify-content: space-evenly;
}
.save {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 1.7rem;
  height: 100%;
  color: var(--font-color);
  background-color: var(--background-index-color);
  grid-area: save;
}

/* Tab-Navigation */
.menupoints.is-active {
  color: #ed1c24;
}
.menupoints:hover {
  color: #ed1c24;
}
.tabs-navigation {
  top: 2rem;
  width: 100%;
  height: 100%;
  height: 2.6rem;
  background-color: var(--background-index-color);
  z-index: 3;
  display: flex;
  grid-area: nav;
}

.menu {
  background-color: var(--background-index-color);
}
.tabs-navigation ul {
  margin: 0;
  width: 100%;
  padding: 0;
  list-style: none;
  overflow: hidden;
  float: left;
}
.tabs-navigation li {
  text-decoration: none;
  list-style: none;
  cursor: pointer;
  float: left;
  margin: 1rem 1rem;
  font-size: clamp(12px, 1.1rem, 50px);
  font-weight: 400;
  color: var(--font-color);
}
/* menu */
.tabs-navigation .menu {
  clear: both;
  max-height: 0;
  transition: max-height 0.2s ease-out;
}
/* menu icon */
.tabs-navigation .menu-icon {
  cursor: pointer;
  display: inline-block;
  padding: 28px 20px;
  position: relative;
  user-select: none;
}
.tabs-navigation .menu-icon .navicon {
  background: #333;
  display: block;
  height: 2px;
  position: relative;
  transition: background 0.2s ease-out;
  width: 18px;
}
.tabs-navigation .menu-icon .navicon:before,
.tabs-navigation .menu-icon .navicon:after {
  background: var(--font-color);
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  transition: all 0.2s ease-out;
  width: 100%;
}
.tabs-navigation .menu-icon .navicon:before {
  top: 5px;
}
.tabs-navigation .menu-icon .navicon:after {
  top: -5px;
}
/* menu btn */
.tabs-navigation .menu-btn {
  display: none;
}
.tabs-navigation .menu-btn:checked ~ .menu {
  max-height: 240px;
}
.tabs-navigation .menu-btn:checked ~ .menu-icon .navicon {
  background: transparent;
}
.tabs-navigation .menu-btn:checked ~ .menu-icon .navicon:before {
  transform: rotate(-45deg);
}
.tabs-navigation .menu-btn:checked ~ .menu-icon .navicon:after {
  transform: rotate(45deg);
}
.tabs-navigation .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.tabs-navigation .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
  top: 0;
}

@media only screen and (max-width: 1000px) {
  .tabs-navigation {
    width: 100%;
    top: 3rem;
  }
  .tabs-wrapper {
    width: 100%;
  }
  .tabs-content {
    width: 100%;
  }
}

@media only screen and (max-width: 1099px) {
  .navigation-container {
    margin: 2rem 0 0 0;
    grid-template-columns: 15% 85%;
  }
  .tabs-navigation {
    width: 50%;
  }
  .tabs-navigation li {
    float: none;
  }
  .tabs-navigation ul {
    background-color: var(--background-index-color);
  }
  .menu {
    margin-top: 1rem;
    position: absolute;
    top: 100%;
    border-bottom: solid 1px #ed1c24;
  }
  /* Need to set the height dynamic because of the keyboards on devices */
  .modal-dialog {
    position: fixed;
    height: 100%;
    margin: 0;
    max-height: 100%;
  }
  .save {
    margin-right: 2rem;
    width: 50%;
  }
  .btn-wrapper {
    width: 100%;
  }
}

@media only screen and (max-width: 500px) {
  .check {
    font-size: 1rem;
  }
}

@media (min-width: 1100px) {
  .save {
    font-size: 2.3rem;
  }

  .save:hover {
    background-color: #ed1c24;
  }

  .tabs-navigation li {
    float: left;
  }

  .navigation-container {
    margin: 2rem 2.5rem 0 3.5rem;
  }

  .tabs-navigation li a {
    padding: 20px 30px;
  }
  .tabs-navigation .menu {
    clear: none;
    float: left;
    max-height: none;
  }
  .tabs-navigation .menu-icon {
    display: none;
  }
}
</style>
