<template>
  <div class="profil-wrapper">
    <div @click="hideMobileModals" ref="container" class="profil-container">
      <TransitionGroup name="list">
        <div
          class="columns"
          :style="{
            width: this.grid.css,
          }"
          v-for="column in columnsArr"
          :key="column"
        >
          <ProfileBox
            ref="profile"
            v-for="profile in column"
            :key="profile.profile_id"
            :width="width"
            :height="height"
            :data="profile"
            :mode="imageObj.name"
          ></ProfileBox>
        </div>
      </TransitionGroup>
    </div>
  </div>
</template>

<script>
// import _ from "lodash";

import ProfileBox from '@/components/Profile/ProfileBox.vue';
import { createLayout } from '@/data/layout.js';
import { mapActions, mapGetters } from 'vuex';
export default {
  components: {
    ProfileBox,
  },
  data() {
    return {
      //Layout
      containerWidth: 0,
      totalWidth: 0,
      imageObj: {},
      width: '1 px',
      height: {},
      grid: {},

      //Data
      columnsArr: [],
    };
  },
  props: [],
  beforeMount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('scroll', this.handleScroll);
    this.emitter.on('init_search_action', this.init);

    // Initialize Size Values
    this.containerWidth = this.$refs.container.clientWidth;
    this.totalWidth = window.innerWidth;
  },
  computed: {
    ...mapGetters('modal', ['MobileStatus']),
    ...mapGetters({
      isLoading: 'search/GET_SEARCHLOADING',
      totalPages: 'search/SEARCHRESPONSEPAGES',
      currentPage: 'search/SEARCHRESPONECURRENTPAGE',
      profileCount: 'search/SEARCHRESPONSELENGTH',
      data: 'search/SEARCHRESPONSE',
    }),
    reachedEnd: function () {
      return this.currentPage === this.totalPages;
    },
    // Data
    // chunkData: function () {
    //   // Splits the Profile Array equaly to the count of the column
    //   return this.chunkArray(this.SEARCHRESPONSE, this.columnCount);
    // },
  },
  methods: {
    ...mapActions('modal', ['hideAllModals']),
    ...mapActions('search', ['search_action', 'search_action_paginate']),
    init() {
      this.initLayout();
      this.initData();
    },
    initLayout() {
      return new Promise((resolve) => {
        var layout = createLayout(this.profileCount, this.containerWidth, 15);
        this.imageObj = layout.imageObj;
        this.width = layout.width;
        this.height = layout.height;
        this.grid = layout.grid;
        resolve();
      });
    },
    initData() {
      return new Promise((resolve) => {
        this.columnsArr = this.chunkArray(this.data, this.grid.columns);
        resolve();
      });
    },
    updateData() {},
    chunkArray(arr, n) {
      var chunkLength = Math.max(arr.length / n, 1);
      var chunks = [];
      for (var i = 0; i < n; i++) {
        if (chunkLength * (i + 1) <= arr.length)
          chunks.push(arr.slice(chunkLength * i, chunkLength * (i + 1)));
      }
      return chunks;
    },
    // splitArray(array, numArrays) {
    //   const arrayLength = array.length;
    //   const arrays = [];

    //   const subArrayLength = Math.ceil(arrayLength / numArrays);
    //   console.log(subArrayLength);
    //   for (let i = 0; i < arrayLength; i += subArrayLength) {
    //     const subArray = array.slice(i, i + subArrayLength);
    //     arrays.push(subArray);
    //   }

    //   return arrays;
    // },
    distributeContent(arrays, content) {
      const numArrays = arrays.length;
      const contentLength = content.length;

      for (let i = 0; i < contentLength; i++) {
        const arrayIndex = i % numArrays;
        const currentArray = arrays[arrayIndex];
        const element = content[i];

        currentArray.push(element);
      }
    },
    handleResize() {
      if (this.$refs.container !== undefined && this.$refs.container !== null) {
        this.containerWidth = this.$refs.container.clientWidth;
      }
      this.totalWidth = window.innerWidth;
      //this.search_action();
    },
    async handleScroll() {
      if (this.GET_SEARCHLOADING || this.reachedEnd) return;

      const scrollOffset =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;

      if (scrollOffset + windowHeight >= documentHeight - 200) {
        const page = this.currentPage + 1;
        var response = await this.search_action_paginate(page);
        if (response === 'END') return;
        if (typeof response === 'undefined') return;
        this.distributeContent(this.columnsArr, response);
      }
    },
    // Mobile
    hideMobileModals: function () {
      if (this.totalWidth < 800 && this.MobileStatus) {
        this.hideAllModals();
      }
    },
  },
};
</script>

<style scoped>
.list-move, /* apply transition to moving elements */
.list-enter-active {
  opacity: 1;
  transition: all 1s ease-in;
}

.list-enter-from {
  /* transform: rotate(-20deg); */
  filter: blur(0.1rem);
  transform: scale(1.3);
  opacity: 0;
}
.list-leave-to {
  /* transform: rotate(-20deg); */
  /* opacity: 0; */
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.list-leave-active {
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none; */
  display: none;
}

.profil-wrapper {
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;
  background-color: var(--background-index-color);
  margin-top: 14px;
  overflow-x: hidden;
}
.profil-container {
  display: flex;
  justify-content: center;
  width: 90vw;
}

/* Make sure to change the margin in the calculation too */

.columns {
  margin: 0 7px 10vw 7px;
  /* width: v-bind(grid.col); */
  height: 100%;
  /* width: 100%; */
}

.profile-small {
  background-color: grey;
  background-size: cover;
  background-position: center;
  height: v-bind(cssHeightSmall);
  /* width: v-bind(cssWidth); */
  width: 100%;
  margin: 0 0 15px 0;
}

@media only screen and (max-width: 1329px) {
  .columns {
    margin: 0 4px 0 4px;
  }

  .profil-container {
    display: flex;
    justify-content: center;
    width: 100vw;
  }
}
</style>
