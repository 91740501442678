<template>
  <div id="bar-chart"></div>
</template>
<script>
import ApexCharts from 'apexcharts';
export default {
  data() {
    return {
      chartData: {},
      timer: '',
    };
  },
  methods: {
    async fetchData() {
      var response = await this.$API.get('/admin/dashboard/data/user/month');
      const data = response.data;
      if (document.getElementById('bar-chart')) {
        const chart = new ApexCharts(
          document.getElementById('bar-chart'),
          this.getMainChartOptions(data)
        );
        chart.render();
      }
    },
    getMainChartOptions(data) {
      //   let mainChartColors = {};
      //   mainChartColors = {
      //     borderColor: '#374151',
      //     labelColor: '#9CA3AF',
      //     opacityFrom: 0,
      //     opacityTo: 0.15,
      //   };
      return {
        colors: ['#1A56DB', '#FDBA8C'],
        series: [
          {
            name: 'User',
            color: '#1A56DB',
            data: data,
          },
          //   {
          //     name: 'Phones',
          //     color: '#FDBA8C',
          //     data: [
          //       { x: '01 Feb', y: 120 },
          //       { x: '02 Feb', y: 294 },
          //       { x: '03 Feb', y: 167 },
          //       { x: '04 Feb', y: 179 },
          //       { x: '05 Feb', y: 245 },
          //       { x: '06 Feb', y: 182 },
          //       { x: '07 Feb', y: 143 },
          //     ],
          //   },
          //   {
          //     name: 'Gaming/Console',
          //     color: '#17B0BD',
          //     data: [
          //       { x: '01 Feb', y: 220 },
          //       { x: '02 Feb', y: 194 },
          //       { x: '03 Feb', y: 217 },
          //       { x: '04 Feb', y: 279 },
          //       { x: '05 Feb', y: 215 },
          //       { x: '06 Feb', y: 263 },
          //       { x: '07 Feb', y: 183 },
          //     ],
          //   },
        ],
        chart: {
          type: 'bar',
          height: '420px',
          fontFamily: 'Inter, sans-serif',
          foreColor: '#4B5563',
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: '90%',
            borderRadius: 3,
          },
        },
        tooltip: {
          shared: true,
          intersect: false,
          style: {
            fontSize: '14px',
            fontFamily: 'Inter, sans-serif',
          },
        },
        states: {
          hover: {
            filter: {
              type: 'darken',
              value: 1,
            },
          },
        },
        stroke: {
          show: true,
          width: 5,
          colors: ['transparent'],
        },
        grid: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        xaxis: {
          floating: false,
          labels: {
            show: true,
            style: {
              fontSize: '14px',
              fontWeight: 600,
            },
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          show: true,
          labels: {
            show: true,
            style: {
              fontSize: '14px',
              fontWeight: 700,
            },
          },
        },
        fill: {
          opacity: 1,
        },
      };
    },
  },
  mounted() {
    this.fetchData();
    this.timer = setInterval(() => {
      this.fetchData();
    }, 10000);
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
};
</script>

<style></style>
