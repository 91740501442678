import { Modal, Drawer, Tabs, Accordion } from 'flowbite';

// Modal

export function openModal(modalElement) {
  const $modalElement = document.querySelector(modalElement);
  const $closeButton = document.querySelector('#closeUserModal');
  const modalOptions = {
    backdropClasses: 'bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40',
  };
  const modal = new Modal($modalElement, modalOptions);
  modal.toggle();
  $closeButton.addEventListener('click', () => modal.hide());
}

export function initDrawer(drawerElement, options) {
  const $targetEl = document.querySelector(drawerElement);
  const drawer = new Drawer($targetEl, options);
  drawer.hide();
  return drawer;
}

export function initTabs(tabElements, options) {
  const tabs = new Tabs(tabElements, options);
  return tabs;
}

export function initAcordion(accordionItems) {
  const options = {
    alwaysOpen: false,
    onOpen: () => {},
    onClose: () => {},
    onToggle: () => {},
  };

  const accordion = new Accordion(accordionItems, options);
  return accordion;
}
