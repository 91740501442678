<template>
  <VLazyImage
    class="profile-box"
    :style="{
      width: width.css,
      height: randomHeight.css,
    }"
    @load="loadDone"
    @mouseover="this.hover()"
    @mouseleave="
      this.profileHoverData({
        active: false,
        name: '',
      })
    "
    @intersect="triggerLoadImage"
    @click="this.profileClickActive(this.data['profile_id'])"
    :src="loadedsrc"
    alt=""
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import VLazyImage from 'v-lazy-image';
export default {
  components: {
    VLazyImage,
  },
  props: ['width', 'height', 'mode', 'data'],
  data() {
    return {
      isload: false,
      intersected: false,
    };
  },
  mounted() {
    this.isload = false;

    // Alle Möglichekeiten für Bilder der
  },
  updated() {
    this.isload = false;
  },
  computed: {
    ...mapGetters('auth', ['PaymentStatus', 'LoginStatus']),
    ...mapGetters('modal', ['MobileStatus']),
    ...mapGetters({
      ModalsOpen: 'modal/ModalStatus',
      loadedImages: 'search/GET_LOADEDQUEUE',
    }),
    placeholder: function () {
      return require('@/assets/logo.png');
    },
    authparam: function () {
      if (this.LoginStatus) return 'auth=true';
      return 'auth=false';
    },
    randomHeight: function () {
      var random = this.getRandomKey(this.height);
      return {
        css: random.obj.css,
        value: random.obj.value,
        key: random.key,
      };
    },
    loadedsrc: function () {
      if (this.loadedImages.includes(this.src)) return this.src;
      return '';
    },
    src: function () {
      const baseUrl = process.env.VUE_APP_BASEURL + '/image/search/';

      if (this.mode == 'small') {
        return (
          baseUrl + this.data['imagesrc_small'] + '?mode=tiny' + '&' + this.authparam
        );
      }

      if (this.randomHeight.key === 'small') {
        return baseUrl + this.data['imagesrc_small'] + '?' + this.authparam;
      }

      if (this.randomHeight.key === 'medium') {
        return baseUrl + this.data['imagesrc_medium'] + '?' + this.authparam;
      }

      if (this.randomHeight.key === 'large') {
        return baseUrl + this.data['imagesrc_large'] + '?' + this.authparam;
      }

      return '';
    },

    imageobj: function () {
      const baseUrl = process.env.VUE_APP_BASEURL + '/image/search/';
      // If the prob customclass is defined the ProfileBox need only one css class exit here.
      if (this.customclass) {
        return {
          class: 'profile-tiny',
          url:
            baseUrl +
            this.data['imagesrc_small'] +
            '?resolution=low' +
            '&' +
            this.authparam,
          placeholder: baseUrl + this.data['imagesrc_small'] + '?resolution=low',
        };
      }

      const number = Math.floor(Math.random() * 3);

      if (number === 0) {
        return {
          class: 'profile-small',
          url: baseUrl + this.data['imagesrc_small'] + '?' + this.authparam,
          placeholder: baseUrl + this.data['imagesrc_small'] + '?resolution=low',
        };
      }

      if (number === 1) {
        return {
          class: 'profile-medium',
          url: baseUrl + this.data['imagesrc_medium'] + '?' + this.authparam,
          placeholder: baseUrl + this.data['imagesrc_medium'] + '?resolution=low',
        };
      }

      if (number === 2) {
        return {
          class: 'profile-large',
          url: baseUrl + this.data['imagesrc_large'] + '?' + this.authparam,
          placeholder: baseUrl + this.data['imagesrc_large'] + '?resolution=low',
        };
      }

      return {
        class: '',
        url: '',
        placeholder: '',
      };
    },
  },
  methods: {
    ...mapActions('search', [
      'profileHoverData',
      'profileClickActive',
      'profileHoverData',
      'addToQueue',
    ]),
    ...mapActions('modal', ['showLoginModal', 'explicitOptionsModal', 'hideAllModals']),
    loadDone() {
      this.isload = true;
    },
    getRandomKey(obj) {
      const keys = Object.keys(obj);
      const randomIndex = Math.floor(Math.random() * keys.length);
      return {
        obj: obj[keys[randomIndex]],
        key: keys[randomIndex],
      };
    },
    hover() {
      this.profileHoverData({
        active: true,
        name: this.data['profile_nickname'],
      });
    },
    click() {
      // DEPRECATED
      //Validate click first

      //Close all other Popups on Mobile
      if (window.innerWidth < 800 && this.MobileStatus) {
        this.hideAllModals();
        return;
      }

      this.profileHoverData({
        active: false,
        name: '',
      });

      //Show Login Form if not logged-in
      if (!this.LoginStatus) {
        this.showLoginModal();
        return;
      }
      // Show hover information is the fallback
      this.profileClickActive(this.data['profile_id']);
    },
    triggerLoadImage() {
      if (this.intersected) return;
      if (this.loadedImages.includes(this.src)) return;
      if (this.loadedImages.includes(this.src)) return;
      this.intersected = true;
      this.addToQueue(this.src);
      //   if (this.loadedImages.includes(this.src)) return;
      //   this.addToQueue(this.src);
    },
  },

  //   watch: {
  //     src(newValue) {
  //       if (this.loadedImages.includes(newValue)) return;
  //       if (this.intersected) return;
  //     },
  //   },
};
</script>

<style scoped>
.v-lazy-image {
  transition: opacity 0.5s, transform 0.5s;
  animation: skeleton-loading 1s linear infinite alternate;
}
.v-lazy-image-loaded {
  background-color: transparent;
}

.profile-box {
  margin: 0 4px 14px 4px;
  border-radius: 10px;
  transition: 0.5s ease-in-out;
  background-size: cover;
  background-position: center;
}

.profile-box:hover {
  transform: scale(1.1);
  cursor: pointer;
}

/* .profile-tiny,
.profile-box {
  background-size: cover;
  background-position: center;
  height: v-bind(cssHeightSmall);
  width: v-bind(cssWidth);
  margin: 0 4px 14px 4px;
  border-radius: 10px;
  transition: 0.5s ease-in-out;
}

.profile-small {
  background-color: transparent;
  background-size: cover;
  background-position: center;
  height: v-bind(cssHeightSmall);
  width: v-bind(cssWidth);
  margin: 0 0 14px 0;
}
.profile-medium {
  background-size: cover;
  background-position: center;
  height: v-bind(cssHeightMedium);
  width: v-bind(cssWidth);
  margin: 0 0 14px 0;
}
.profile-large {
  background-color: transparent;
  background-size: cover;
  background-position: center;
  height: v-bind(cssHeightLarge);
  width: v-bind(cssWidth);
  margin: 0 0 14px 0;
}
.profile-large:hover,
.profile-medium:hover,
.profile-small:hover {
  transform: scale(1.1);
  cursor: pointer;
} */

@keyframes skeleton-loading {
  0% {
    background-color: var(--box-color);
  }
  100% {
    background-color: var(--textbox-color);
  }
}

.profile-tiny:hover {
  transform: scale(1.1);
  cursor: pointer;
}
</style>
