<template>
  <div class="form-field" :class="{ 'with-icon': icon }">
    <!-- title -->
    <div class="input-title-container">
      <label class="title" v-bind:class="[{ error: this.error }]">
        {{ title }}
      </label>
    </div>

    <!-- icon -->
    <div v-if="icon" class="input-icon-container">
      <em :class="icon"></em>
    </div>

    <!-- input -->
    <div class="input-container">
      <!-- textarea -->
      <textarea
        v-if="this.inputType === 'textarea'"
        :value="this.value"
        class="input"
        :id="id"
        v-bind:class="[{ error: this.error }]"
        :type="this.inputType"
        @input="handleInput($event.target.value)"
        @blur="handleInput($event.target.value)"
        @focus="handleInput(this.value)"
      />

      <!-- std input (text, numbers) -->
      <input
        v-if="this.inputType === 'text' || this.inputType === 'password'"
        :value="this.value"
        ref="input"
        class="input"
        :id="id"
        v-bind:class="[{ error: this.error }]"
        :type="this.inputType"
        @input="handleInput($event.target.value)"
        @blur="handleInput($event.target.value)"
        @focus="handleInput(this.value)"
      />

      <slot></slot>
    </div>

    <!-- info -->
    <div v-if="this.error || titletext" class="input-info-container">
      <p v-if="this.error" class="input-info-error">
        {{ this.InputError.msg }}
      </p>
      <p v-else class="input-info">{{ titletext }}</p>
    </div>

    <!-- btn -->
    <div class="input-btn-container">
      <em
        v-if="this.inputType === 'password' && this.type === 'password'"
        @click="togglePassword"
        class="fas fa-eye"
      ></em>
      <em
        v-if="this.inputType === 'text' && this.type === 'password'"
        @click="togglePassword"
        class="fas fa-eye-slash"
      ></em>
      <em @click="clearInput" class="far fa-times-circle"></em>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    id: {
      type: String,
    },
    titletext: String,
    icon: String,
    type: {
      type: String,
      default: 'text',
    },
    value: {
      type: [String, Number],
      default: () => '',
    },
    validations: {
      type: Object,
      default: () => ({
        maxLength: {
          value: 90,
          msg: false,
        },
        minLength: false,
        required: false,
        type: false,
        email: false,
        username: false,
      }),
    },
    error: [String, Boolean],
  },
  data() {
    return {
      InputError: {
        state: false,
        msg: '',
      },
      inputType: '',
      newvalue: '',
    };
  },
  methods: {
    clearInput() {
      // Value need to be cleared in the parent component, too
      this.$emit('update:modelValue', '');
    },
    togglePassword() {
      if (this.inputType == 'password') {
        this.inputType = 'text';
      } else {
        this.inputType = 'password';
      }
    },
    handleInput(newvalue) {
      // todo: Make own file for this ugly code
      this.$emit('update:modelValue', newvalue);
      if (newvalue === null || newvalue.length === 0) {
        if (typeof this.validations.minLength !== 'undefined') {
          if (newvalue.length < this.validations.minLength.value) {
            this.InputError.state = true;
            if (this.validations.minLength.msg !== false) {
              this.InputError.msg = this.validations.minLength.msg;
            } else {
              this.InputError.msg =
                'Die Eingabe muss mindestens ' +
                this.validations.minLength.value +
                ' Zeichen beinhalten';
            }

            return;
          }
        }
      }

      // Validations

      // maxLength
      if (typeof this.validations.maxLength !== 'undefined') {
        if (newvalue.length > this.validations.maxLength.value) {
          this.InputError.state = true;
          if (this.validations.maxLength.msg !== false) {
            this.InputError.msg = this.validations.maxLength.msg;
          } else {
            this.InputError.msg =
              'Die Eingabe darf ' +
              this.validations.maxLength.value +
              ' Zeichen nicht überschreiten';
          }

          return;
        }
      }

      // minLength
      if (typeof this.validations.minLength !== 'undefined') {
        if (newvalue.length < this.validations.minLength.value) {
          this.InputError.state = true;
          if (this.validations.minLength.msg !== false) {
            this.InputError.msg = this.validations.minLength.msg;
          } else {
            this.InputError.msg =
              'Die Eingabe muss mindestens ' +
              this.validations.minLength.value +
              ' Zeichen beinhalten';
          }

          return;
        }
      }
      //type
      if (typeof this.validations.type !== 'undefined') {
        if (this.validations.type !== false) {
          if (this.validations.type.value === 'number') {
            if (!this.stringContainsNumber(newvalue)) {
              this.InputError.state = true;
              this.InputError.msg = this.validations.type.msg;
              return;
            }
          }
          if (this.validations.type.value === 'text') {
            if (this.stringContainsNumber(newvalue)) {
              this.InputError.state = true;
              this.InputError.msg = this.validations.type.msg;
              return;
            }
          }
        }
      }

      //email
      const reg =
        //eslint-disable-next-line no-useless-escape
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
      if (typeof this.validations.email !== 'undefined') {
        if (this.validations.email !== false) {
          if (!reg.test(newvalue)) {
            this.InputError.state = true;
            if (newvalue.length > 3) {
              this.InputError.msg = 'Ungültige E-Mail Adresse!';
            }
            return;
          }
        }
      }

      //username
      // minLength
      if (typeof this.validations.username !== 'undefined') {
        if (this.validations.username !== false) {
          // Überprüfung auf Leerzeichen am Start oder Ende des Benutzernamens
          if (newvalue.trim() !== newvalue) {
            this.InputError.state = true;
            this.InputError.msg = 'Ungültiger Benutzername!';
            return;
          }

          // Überprüfung auf Leerzeichen im Benutzernamen
          if (newvalue.includes(' ')) {
            this.InputError.state = true;
            this.InputError.msg = 'Ungültiger Benutzername!';
            return;
          }

          // Überprüfung auf erlaubte Zeichen (Zahlen, Buchstaben, @, #, -, _)
          const allowedCharacters = /^[0-9A-Za-z@#\-_]+$/;
          if (!allowedCharacters.test(newvalue)) {
            this.InputError.state = true;
            this.InputError.msg = 'Erlaubt sind Zeichen von A-Z, 0-9, @, #, -, _';
            return;
          }
        }
      }

      // Fallback
      //   if (this.validations.required === true) {
      //     if (newvalue.length > 0) {
      //       this.InputError.state = false;
      //       this.InputError.msg = "";
      //     }
      //     return;
      //   }
      this.InputError.state = false;
      this.InputError.msg = '';
      this.$emit('inputError', this.InputError.state);
    },

    // Validation-Utils
    stringContainsNumber(_string) {
      return /\d/.test(_string);
    },
  },
  watch: {
    value: function () {
      this.handleInput(this.value);
      //   if (this.validations.required === true) {
      //     if (this.value.length === 0) {
      //       this.$emit("inputError", true);
      //       this.InputError.state = true;
      //       this.InputError.msg = "";
      //     }
      //   }
    },
    'InputError.state'(newVal) {
      this.$emit('inputError', newVal);
    },
  },
  mounted() {
    this.inputType = this.type;
    this.emitter.on('checkAll', () => {
      //Prevent timing issues
      this.$emit('inputError', true);
      this.handleInput(this.value);
      if (this.validations.required === true && this.value.length === 0) {
        this.$emit('inputError', true);
      }
    });
  },
  beforeUnmount() {
    this.emitter.off('checkAll');
  },
};
</script>

<style scoped>
/* Transitions */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}
.v-enter-from,
.v-leave-to {
  opacity: 0;
}

/* layout */

.form-field {
  display: grid;
  padding: 1.5rem;
  align-items: center;
  grid-template-columns: 25% 55% 20%;
  grid-template-areas:
    'title input button'
    '. info .';

  grid-template-rows: auto;
  border-bottom: solid 1px grey;
  background-color: var(--settings-box);
  transition: width 0.5s ease;
}

.with-icon {
  grid-template-columns: 10% 20% 50% 20%;
  grid-template-areas:
    'icon title input button'
    ' . . info .';
}

/* title */

.input-title-container {
  display: flex;
  justify-content: center;
  font-size: clamp(18px, 1rem, 50px);
  font-weight: bolder;
  align-items: center;
  word-break: keep-all;
  height: 100%;
  grid-area: title;
  color: var(--font-color);
}

.error {
  color: red;
}

/* Icon */

.input-icon-container {
  display: flex;

  color: var(--font-color);
  align-items: center;
  justify-content: center;
  grid-area: icon;
  height: 100%;
}

/* input element */

.input-container {
  height: 100%;
  grid-area: input;
  display: flex;
  align-items: center;
}

.input {
  background-color: var(--settings-input);
  padding: 0 1rem;
  color: var(--font-color);
  font-size: clamp(12px, 1.1rem, 50px);
  border-radius: 5px;
  height: 3rem;
  width: 100%;
  border: none;
  cursor: text;
}
.input[type='textarea'] {
  height: 4rem;
  padding: 1rem 1rem;
  resize: none;
}

.input[type='password'] {
  font-size: clamp(12px, 1.8rem, 50px);
}

/* info text */

.input-info-container {
  padding: 0.5rem;
  grid-area: info;
  color: var(--font-color);
}

.input-info-container > .input-info-error {
  color: #ed1c24;
}

/* buttons */

.input-btn-container {
  height: 100%;
  display: flex;
  font-size: 1.7rem;
  color: var(--font-color);
  justify-content: center;

  align-items: center;
  grid-area: button;
}

.input-btn-container > em {
  cursor: pointer;
  margin-left: 1rem;
}

.input-btn-container,
.input-icon-container > em {
  font-size: 2rem;
}

.input-btn-container > em:hover {
  color: #ed1c24;
}

@media only screen and (max-width: 1000px) {
  .input-btn-container {
    flex-direction: column;
    gap: 1.2rem;
  }

  .input-btn-container > em:hover {
    color: none;
  }

  .input-btn-container > em {
    margin-left: 0;
  }

  .input-btn-container,
  .input-icon-container > em {
    font-size: 1.9rem;
  }

  .input-container,
  .input-title-container,
  .input-info-container {
    margin: 0 1rem 0 1rem;
  }

  .form-field {
    grid-template-columns: 80% 20%;
    grid-template-areas:
      'title .'
      'input button'
      'info .';
    padding: 1rem;
  }

  .with-icon {
    grid-template-columns: 10% 80% 10%;
    grid-template-areas:
      ' . title .'
      'icon input button'
      ' .  info .';
  }

  .input-title-container {
    margin-bottom: 1rem;
    justify-content: start;
  }

  .input-title-container > em {
    font-size: 1.5rem;
  }

  .input-icon-container {
    justify-content: center;
  }
}
</style>
