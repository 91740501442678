<template>
  <Transition>
    <div class="modal" v-if="show">
      <!-- Controls the background element to close the modal -->
      <div @click="this.setSubmitConfirm(false)" class="modal-background">
        <div class="modal-dialog">
          <!-- body -->
          <div class="box">
            <p class="text">{{ this.SubmitConfirm.text }}</p>
            <div class="button-wrapper">
              <FormSubmit :submitText="'JA'" @click="this.setSubmitConfirm(true)" />
              <FormSubmit :submitText="'NEIN'" @click="this.setSubmitConfirm(false)" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FormSubmit from '@/components/Form/FormSubmit.vue';

export default {
  props: {
    title: {
      type: String,
    },
  },
  components: {
    FormSubmit,
  },
  computed: {
    show: function () {
      return this.SubmitConfirmModalStatus();
    },
    ...mapGetters('modal', ['SubmitConfirm']),
  },
  methods: {
    ...mapActions('modal', ['hideAllModals']),
    ...mapActions('modal', ['setSubmitConfirm']),
    ...mapGetters('modal', ['SubmitConfirmModalStatus']),
    close: function () {},
  },
};
</script>

<style scoped>
.text {
  color: var(--font-color);
  margin-bottom: 2rem;
  text-align: center;
}
.box {
  display: flex;
  padding: 4rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 30vw;
  height: 10vw;
  background-color: var(--box-color);
  margin: 0 auto 0;
  border-radius: 10px;
  border: #ed1c24;
  border-style: groove;
}

.button-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

/* dialog transition */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

/* modal */

.modal-body {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  position: fixed;
  overflow-y: hidden;
  overflow-x: hidden;
  width: 100%;
}
.modal-background {
  overflow-y: hidden;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: rgba(255, 255, 255, 0.4); */
  z-index: 1;
}
.modal-dialog {
  max-height: 75vh;
  width: 70rem;
  /* margin: 0 auto; */
  display: flex;
  flex-direction: column;
  z-index: 2;
}

@media only screen and (max-width: 1330px) {
  .box {
    width: 75vw;
    height: 100%;
  }

  .text {
    font-size: 20px;
  }

  .button-wrapper {
    gap: 1rem;
  }
}
</style>
