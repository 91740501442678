<template>
  <div class="footer">
    <div class="logo">
      <img src="@/assets/logo.png" alt="logofooter" class="logofooter" />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="4051.183"
        height="498.308"
        viewBox="0 0 4051.183 498.308"
        class="textfooter"
      >
        <g id="layer1" transform="translate(-5.854 -13.535)">
          <g id="g844" transform="translate(-8.553)">
            <g id="text836">
              <path
                id="path833"
                d="M14.407,17.2H353.329l-188.7,390.829h188.7v97.707H14.407L204.936,114.907H14.407Z"
                fill="var(--font-color)"
              />
              <path
                id="path835"
                d="M420.5,101.472a86.853,86.853,0,0,1,6.717-34.2,85.533,85.533,0,0,1,18.32-27.48A87.686,87.686,0,0,1,473.63,20.864a84.757,84.757,0,0,1,34.808-7.328H679.427a80.611,80.611,0,0,1,34.2,7.328A80.964,80.964,0,0,1,741.1,39.794a80.964,80.964,0,0,1,18.931,27.48,80.61,80.61,0,0,1,7.328,34.2V423.906a84.757,84.757,0,0,1-7.328,34.808A87.685,87.685,0,0,1,741.1,486.8a85.531,85.531,0,0,1-27.48,18.32,86.854,86.854,0,0,1-34.2,6.717H508.438a91.369,91.369,0,0,1-34.808-6.717,81.4,81.4,0,0,1-46.411-46.411,91.369,91.369,0,0,1-6.717-34.808Zm97.707,303.5q0,9.16,9.16,9.16h131.9q10.381,0,10.381-9.16V120.4q0-9.16-10.381-9.16h-131.9q-9.16,0-9.16,9.16Z"
                fill="var(--font-color)"
              />
              <path
                id="path837"
                d="M858.962,101.472a86.854,86.854,0,0,1,6.717-34.2A85.534,85.534,0,0,1,884,39.794,87.686,87.686,0,0,1,912.09,20.864,84.757,84.757,0,0,1,946.9,13.535h170.988a80.611,80.611,0,0,1,34.2,7.328,80.963,80.963,0,0,1,27.48,18.931,80.963,80.963,0,0,1,18.931,27.48,80.612,80.612,0,0,1,7.328,34.2V423.906a84.759,84.759,0,0,1-7.328,34.808,87.684,87.684,0,0,1-18.931,28.091,85.529,85.529,0,0,1-27.48,18.32,86.854,86.854,0,0,1-34.2,6.717H946.9a91.369,91.369,0,0,1-34.808-6.717,81.4,81.4,0,0,1-46.411-46.411,91.369,91.369,0,0,1-6.717-34.808Zm97.707,303.5q0,9.16,9.16,9.16h131.905q10.381,0,10.381-9.16V120.4q0-9.16-10.381-9.16H965.829q-9.16,0-9.16,9.16Z"
                fill="var(--font-color)"
              />
              <path
                id="path839"
                d="M1541.08,319.481,1588.1,17.2h176.484l18.32,488.536H1685.2l-13.435-390.829h-5.5l-87.936,384.722h-74.5l-87.937-384.722h-4.885l-14.045,390.829h-97.707L1318.186,17.2h166.1Z"
                fill="var(--font-color)"
              />
              <path
                id="path841"
                d="M2122.44,339.634H1873.28v-97.1h249.16Z"
                fill="var(--font-color)"
              />
              <path
                id="path843"
                d="M2477.24,408.029h4.275V17.2h97.707V505.736H2414.341l-93.433-390.829h-3.053V505.736h-97.707V17.2H2385.64Z"
                fill="var(--font-color)"
              />
              <path
                id="path845"
                d="M2999.36,114.907H2786.847V205.9h196.025V303.6H2786.847V408.029H2999.36v97.707H2689.14V17.2h310.22Z"
                fill="var(--font-color)"
              />
              <path
                id="path847"
                d="M3151.41,408.639l-14.656,97.1h-97.707l74.5-467.773q1.221-7.328,8.549-14.045a26.741,26.741,0,0,1,17.709-6.717h207.017a24.509,24.509,0,0,1,17.1,6.717q7.939,6.107,9.16,14.045L3448.2,505.736h-97.707l-14.656-97.1ZM3293.7,128.952q-1.221-7.328-4.885-10.381a9.847,9.847,0,0,0-7.939-3.664H3206.98q-11.6,0-13.435,14.045l-27.48,181.98h155.11Z"
                fill="var(--font-color)"
              />
              <path
                id="path849"
                d="M3793.84,17.2a64.491,64.491,0,0,1,28.091,6.107,65.554,65.554,0,0,1,23.206,15.877,65.554,65.554,0,0,1,15.877,23.205,64.493,64.493,0,0,1,6.107,28.091v87.936a74.835,74.835,0,0,1-13.435,43.358,76.412,76.412,0,0,1-33.587,28.7,78.278,78.278,0,0,1,36.64,28.091q14.656,19.541,14.656,45.19v181.98h-97.707V323.756a58.123,58.123,0,0,0-1.832-15.267q-1.221-6.717-4.275-9.16H3619.188V505.735h-97.707V17.2Zm-35.42,184.422a44.954,44.954,0,0,0,7.328-9.16q3.053-4.275,3.053-12.824V122.846q0-9.771-7.939-9.771H3619.187v88.547Z"
                fill="var(--font-color)"
              />
              <path
                id="path851"
                d="M4065.59,379.938h-97.707V19.642h97.707Zm0,125.8h-97.707V441.005h97.707Z"
                fill="var(--font-color)"
              />
            </g>
          </g>
        </g>
      </svg>
    </div>

    <!-- search options -->

    <div class="list">
      <div v-if="this.default !== true" class="box">
        <TransitionGroup name="list">
          <button
            v-for="option in filteredSel"
            :key="option.id"
            :value="option.name"
            @click="this.addSearchParam(option)"
            class="category"
          >
            <p>{{ option.name }}</p>
          </button>
        </TransitionGroup>
      </div>

      <div v-if="this.default === true" class="box">
        <button class="category">
          Wähle eine Kategorie um deine Suche zu verfeinern
        </button>
      </div>
    </div>

    <!-- search ui -->

    <div
      class="crosshair"
      :class="{
        'crosshair--load-animation': this.GET_SEARCHLOADING,
      }"
    >
      <div class="crossbox">
        <!-- style -->

        <div class="cross1"></div>
        <div class="cross2"></div>
        <div class="cross3"></div>
        <div class="cross4"></div>

        <!-- buttons -->

        <div
          @click="toggleSearch('X1')"
          :class="{
            active: currentSearchRef === 'X1',
          }"
          class="top"
        >
          <span class="favicon">1</span>
        </div>
        <div
          :class="{
            active: currentSearchRef === 'X3',
          }"
          @click="toggleSearch('X3')"
          class="bot"
        >
          <span class="favicon fas fa-star"></span>
        </div>
        <div
          :class="{
            active: currentSearchRef === 'X2',
          }"
          @click="toggleSearch('X2')"
          class="left"
        >
          <span class="favicon">2</span>
        </div>
        <div
          :class="{
            active: currentSearchRef === 'X4',
          }"
          @click="toggleSearch('X4')"
          class="right"
        >
          <span class="favicon">3</span>
        </div>
      </div>
    </div>

    <!-- search parameter -->

    <div class="search">
      <div class="searchbox">
        <button
          v-for="param in GET_SEARCHPARAMS"
          :key="param.id"
          @click="this.removeSearchParam(param)"
          class="searchbtn"
        >
          <!-- icon depents on ref condition -->
          <!-- <span v-if="param.ref == 'X1'" class="material-icons-outlined searchimg"> </span>
          <span v-if="param.ref == 'X2'" class="material-icons-outlined searchimg"> </span>
          <span v-if="param.ref == 'X3'" class="favicon fas fa-star"> </span>
          <span v-if="param.ref == 'X4'" class="material-icons-outlined searchimg"> </span> -->

          <!-- param name -->
          <p>{{ param.name }}</p>

          <!-- remove icon -->
          <span class="material-icons-outlined close">close</span>
        </button>
        <span class="material-icons-outlined searchicon">search</span>
      </div>
    </div>

    <!-- Options Component -->
    <Options />
  </div>
</template>

<script>
import Options from '@/components/Navbar/Options.vue';
import { mapActions, mapGetters } from 'vuex';
export default {
  components: {
    Options,
  },
  data() {
    return {
      default: true,
      currentSearchRef: false,
      selOptions: [],
    };
  },
  computed: {
    ...mapGetters('search', ['GET_SELOPTIONS']),
    ...mapGetters('search', ['GET_SEARCHPARAMS']),
    ...mapGetters('search', ['GET_SEARCHLOADING']),
    filteredSel: function () {
      return this.GET_SELOPTIONS.filter(
        (option) => !this.GET_SEARCHPARAMS.includes(option)
      );
    },
  },
  methods: {
    ...mapActions('search', [
      'fetchOptions',
      'addSearchParam',
      'removeSearchParam',
      'removeobj',
    ]),
    ...mapActions('modal', ['toggleChatInbox']),
    toggleSearch: function (ref) {
      this.default = false;
      this.currentSearchRef = ref;
      this.fetchOptions(ref);
    },
  },
  mounted() {
    const animateButton = document.querySelector('.crosshair');

    animateButton.addEventListener('click', function () {
      animateButton.classList.add('crosshair--animation');
    });

    animateButton.addEventListener('animationend', function () {
      animateButton.classList.remove('crosshair--animation');
    });
  },
  beforeUnmount() {
    const animateButton = document.querySelectorAll('.crosshair');
    window.removeEventListener('click', function () {
      animateButton.classList.add('crosshair--animation');
    });
    window.removeEventListener('animationend', function () {
      animateButton.classList.remove('crosshair--animation');
    });
  },
};
</script>

<style scoped>
.list-move, /* apply transition to moving elements */
.list-enter-active,
.list-leave-active {
  transition: all 0.2s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(10px);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
/* .list-leave-active {
  position: absolute;
} */

.footer {
  position: fixed;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: clamp(10rem, 10vw, 15rem);
  /* height: 0vw;
  border-top: 20px solid red; */
  background-color: var(--nav-bg);
  overflow: hidden;
}
/* .footer:hover {
  height: clamp(10rem, 10vw, 15rem);
  border: none;
} */
.logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 26vw;
  height: clamp(10rem, 10vw, 15rem);
}
.logofooter {
  height: 3vw;
}
.textfooter {
  width: 15vw;
  padding: 0 0 0 2rem;
  height: 10vw;
}

.list {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15vw;
  height: clamp(10rem, 10vw, 14rem);
}
.box {
  display: flex;
  overflow: scroll;
  overflow-x: hidden;
  align-items: center;
  flex-direction: column;
  height: clamp(9rem, 9vw, 14rem);
  width: 14vw;
  background-color: var(--box-color);
  border-radius: 10px;
  border: solid #707070 1px;
}
.category {
  width: 95%;
  padding: 10px 5px;
  margin: 3px 0;
  border-radius: 10px;
  z-index: 1;
  text-decoration: none;
  border: none;
  background-color: var(--textbox-color);
  color: var(--font-color);
  cursor: pointer;
  font-size: clamp(12px, 0.9vw, 50px);
  word-wrap: break-word;
  -moz-hyphens: auto;
  -o-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.category:hover {
  background-color: #ed1c24;
}
.category:active {
  background-color: #740b0e;
}
.categoryimg {
  position: absolute;
  font-size: clamp(1px, 7vw, 15rem);
  color: var(--img-color);
  margin: 1vw 0 0 0;
  -ms-user-select: None;
  -moz-user-select: None;
  -webkit-user-select: None;
  user-select: None;
}

.crosshair {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 10rem;
  height: 10rem;
  -ms-user-select: None;
  -moz-user-select: None;
  -webkit-user-select: None;
  user-select: None;
}

.crosshair--load-animation {
  border-width: 5px;
  border-style: solid;
  border-radius: 100%;
  border-color: transparent;
  animation: glow 6s infinite;
  /* animation: glow 3s infinite; */
  /* animation-iteration-count: 1; */
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes glow {
  0% {
    border: solid red 5px;
  }

  25% {
    border: solid transparent 5px;
    /* border-top: solid red 1px; */
    /* border-right: solid red 1px;
    border-bottom: solid red 1px; */
  }

  50% {
    border: solid red 5px;
    /* border-left: solid red 2px;
    /* border-right: solid red 2px; */
    /* border-bottom: solid red 2px; */
  }

  75% {
    /* border-left: solid red 5px; */
    /* border-top: solid red 5px; */
    border: solid transparent 5px;
    /* border-bottom: solid red 5px; */
  }

  100% {
    border: solid red 5px;
  }
}

@keyframes picked {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.crossbox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  height: 6rem;
  width: 6rem;
  /* transform: rotate(45deg); */
  border: solid black 1px;
}
.top,
.left,
.bot,
.right {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 3rem;
  height: 3rem;
  background-color: #ed1c24;
  cursor: pointer;
}

.active {
  background-color: #ed1c2344;
}

.top:hover {
  transform: scale(1.2);
  border-top: solid 1px black;
  border-left: solid 1px black;
}
.left:hover {
  transform: scale(1.2);
  border-left: solid 1px black;
  border-bottom: solid 1px black;
}
.bot:hover {
  transform: scale(1.2);
  border-bottom: solid 1px black;
  border-right: solid 1px black;
}
.right:hover {
  transform: scale(1.2);
  border-right: solid 1px black;
  border-top: solid 1px black;
}

.top:active,
.left:active,
.bot:active,
.right:active {
  background-color: #740b0e;
}
.crossimg {
  font-size: 1.5rem;
  transform: rotate(-45deg);
  color: white;
}
.bot {
  top: 3rem;
  left: 3rem;
}
.left {
  top: 3rem;
}
.right {
  right: 3rem;
  left: 3rem;
}
.cross1,
.cross2,
.cross3,
.cross4 {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 0.01rem solid transparent;
  border-right: 0.01rem solid transparent;
  border-bottom: 4rem solid black;
  z-index: 2;
}
.cross1 {
  top: 3rem;
  right: 3rem;
}
.cross2 {
  top: 1rem;
  left: 1rem;
  transform: rotate(90deg);
}
.cross3 {
  right: 3rem;
  bottom: 3rem;
  transform: rotate(180deg);
}
.cross4 {
  right: 1rem;
  bottom: 1rem;
  transform: rotate(270deg);
}

.favicon {
  font-size: 1vw;
  padding: 0 0.2rem;
  color: white;
  -ms-user-select: None;
  -moz-user-select: None;
  -webkit-user-select: None;
  user-select: None;
  transform: rotate(-45deg);
}
.search {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 19vw;
  height: clamp(10rem, 10vw, 14rem);
}
.searchbox {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  height: clamp(9rem, 9vw, 14rem);
  width: 17vw;
  background-color: var(--box-color);
  border-radius: 10px;
  border: solid #707070 1px;
  overflow: scroll;
}
.searchbtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  padding: 10px 0.5rem;
  margin: 3px;
  border-radius: 10px;
  z-index: 1;
  text-decoration: none;
  border: none;
  background-color: var(--textbox-color);
  color: var(--font-color);
  cursor: pointer;
  font-size: clamp(12px, 0.9vw, 40px);
  word-break: break-all;
}
.searchbtn p {
  padding: 0 0 1px 0;
}
.searchbtn:active {
  background-color: #740b0e;
}
.searchbtn:hover {
  background-color: #ed1c24;
}
.searchicon {
  position: absolute;
  font-size: clamp(1rem, 8vw, 15rem);
  color: var(--img-color);
  margin: 0.5vw 0 0 4.5vw;
  -ms-user-select: None;
  -moz-user-select: None;
  -webkit-user-select: None;
  user-select: None;
}
.close {
  padding: 0 0.15rem;
  font-size: clamp(12px, 0.9vw, 50px);
  color: var(--font-color);
}
.info {
  padding: 0rem 0 0 0;
}
.info p {
  font-size: clamp(12px, 0.8vw, 20px);
  cursor: pointer;
  color: var(--font-color);
  word-break: break-all;
}
.info p:hover,
.chatpng:hover {
  transform: scale(1.1);
  color: #ed1c24;
}

@media only screen and (max-width: 1550px) {
  .textfooter {
    display: none;
  }
  .logofooter {
    height: 6vw;
    padding: 0 0 0 5rem;
    cursor: pointer;
  }
}
</style>
