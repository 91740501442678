<template>
  <div class="image-upload">
    <div class="image-upload-input">
      <div
        v-if="!imageprocessing"
        class="upload-button"
        :style="{ backgroundImage: `url(${profileImage})` }"
      >
        <span class="material-icons-outlined">file_upload</span>
        <input
          ref="file"
          @change="handleImage"
          type="file"
          accept="image/png, image/jpeg, image/jpg"
          class="input-image"
        />
      </div>
      <div v-if="imageprocessing" class="loading">
        <div class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
    <div class="image-upload-btn">
      <div class="upload-btn-group">
        <FormSubmit
          v-if="profileImage"
          id="delete"
          @click="this.deleteProfileImage()"
          :submitText="'Foto löschen'"
        />
        <FormSubmit id="upload" @click="$refs.file.click()" :submitText="'Foto hochladen'" />
      </div>
      <div class="upload-btn-info">
        <p class="info">Maximal 5MB. JPEG, PNG</p>
      </div>
    </div>
  </div>
</template>

<script>
import FormSubmit from "@/components/Form/FormSubmit.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    FormSubmit,
  },
  props: {},
  data() {
    return {
      image: "",
    };
  },
  computed: {
    //Current Profile Image
    profileImage: function () {
      return this.GET_PROFILEIMAGE();
    },
    imageprocessing: function () {
      return this.GET_UPLOAD_PROCESSING();
    },
  },
  methods: {
    // Helper functions from VUEX
    ...mapActions("modal", ["showLoadingModal", "hideLoadingModal"]),
    ...mapGetters("profile", ["GET_PROFILEIMAGE", "ImagecropSrc", "GET_UPLOAD_PROCESSING"]),
    ...mapActions("modal", ["showSubmitModal", "hideOptionsModal"]),
    ...mapActions("profile", ["deleteProfileImage", "setImagecropSource", "SET_IMAGECROPRES"]),
    ...mapActions("search", ["search_action"]),

    // Image File Upload
    handleImage(e) {
      console.log(e.target.files[0]);
      var imageSize = e.target.files[0].size / 1024 ** 2;
      if (imageSize > 5.1) {
        this.showSubmitModal({
          status: "error",
          time: 3000,
          text: "Das Bild darf 5MB nicht überschreiten!",
        });
        return;
      }
      if (e.target.files[0].type !== "image/jpeg" && e.target.files[0].type !== "image/png") {
        this.showSubmitModal({
          status: "error",
          time: 3000,
          text: "Falscher Datei-Typ!",
        });
        return;
      }
      const selectedImage = e.target.files[0];
      this.createBase64Image(selectedImage);
    },

    createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onloadstart = () => {};

      reader.onload = (e) => {
        this.setImagecropSource(e.target.result);
        this.hideOptionsModal();
        this.$router.push({ name: "ImageUpload-01" });
      };
    },
  },
};
</script>

<style scoped>
.image-upload {
  display: flex;
  border-bottom: solid 1px grey;
}

.image-upload-input {
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
}

.upload-button {
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 26 / 17;
  height: 20rem;
  /* width: 8rem;
  height: 8rem; */
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: var(--settings-input);
  cursor: pointer;
  position: relative;
}
.upload-button:hover {
  background-color: #ed1c24;
}

.upload-button span {
  font-size: 8rem;
  color: white;
}
.input-image {
  opacity: 0;
  position: absolute; /* invisible but it's there! */
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.image-upload-btn {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
#delete {
  background-color: #ed1c24;
}

.upload-btn-group {
  padding: 1.5rem;
  display: flex;
  height: 50%;
  justify-content: space-between;
  flex-direction: column;
}
.info {
  font-weight: 300;
  padding: 1.5rem;
  font-size: clamp(12px, 1rem, 50px);
  margin: 0 0 0 1rem;
  color: var(--font-color);
}

@media only screen and (max-width: 800px) {
  .upload-button {
    height: 13rem;
  }

  .upload-button span {
    font-size: 5rem;
  }
  .image-upload-input,
  .image-upload-btn {
    width: 80%;
  }
  .image-upload {
    flex-direction: column;
    align-items: center;
  }
  .upload-btn-group {
    gap: 2rem;
  }
  .info {
    width: 80%;
  }
}

@media only screen and (max-width: 500px) {
  .upload-button {
    height: 9rem;
  }
}
</style>
