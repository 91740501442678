<template>
  <div
    class="min-h-screen flex-col items-center justify-between rounded-lg border border-gray-700 bg-gray-800 p-4 shadow-sm sm:p-6"
  >
    <!-- Header -->
    <div class="mb-1 w-full">
      <div class="mb-4">
        <h1 class="text-xl font-semibold text-white sm:text-2xl">X1</h1>
      </div>
    </div>
    <!-- Header -->

    <div class="dark w-full">
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
        <!-- Search -->
        <div class="flex items-center justify-start p-4 pb-4">
          <label for="table-search" class="sr-only">Search</label>
          <div class="relative">
            <div
              class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
            >
              <svg
                class="h-5 w-5 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div>
            <input
              v-model="current_search.value"
              @input="handleSearch"
              type="text"
              id="table-search-users"
              class="block w-80 rounded-lg border border-gray-300 bg-gray-50 p-2 pl-10 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
              placeholder="Search for users"
            />
          </div>
          <SearchDropdown
            @changeSearch="this.current_search = $event"
            :avaliable_options="search_options"
            :current_search="current_search"
          />
        </div>
        <!-- Search -->
        <div class="w-full p-10">
          <div class="grid grid-cols-10 gap-4">
            <div class="col-span-10 md:col-span-5">
              <div class="flex items-center justify-start p-2 pb-4">
                <button
                  @click="handleSave"
                  type="button"
                  class="focus:outline-none mb-2 mr-2 rounded-lg bg-blue-700 px-5 py-2.5 text-sm font-medium text-white hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Speichern
                </button>
              </div>
            </div>
            <div class="col-span-10 md:col-span-5">
              <div class="flex items-center justify-start p-2 pb-4">
                <Pagination
                  @paginate="this.getData($event)"
                  :total_pages="total_pages"
                  :current_page="current_page"
                />
              </div>
            </div>
          </div>
          <!-- Table -->
          <table class="w-fulltext-sm w-full text-left text-gray-500 dark:text-gray-400">
            <thead
              class="whitespace-nowrap bg-gray-50 p-4 text-base font-medium text-gray-900 dark:bg-gray-700 dark:text-white"
            >
              <tr>
                <th scope="col" class="p-4"></th>
                <th scope="col" class="px-6 py-3">Name</th>
                <th scope="col" class="px-6 py-3">Anzahl Unterkategorien</th>
                <th scope="col" class="px-6 py-3">Anzahl Verknüpfte Profile</th>
                <th scope="col" class="px-2 py-3"></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="category in form"
                :key="category.category_id"
                class="border-b bg-white hover:bg-gray-50 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-600"
              >
                <!-- Category Name -->
                <td class="w-4 p-4"></td>
                <td class="px-6 py-3">
                  <input
                    type="text"
                    v-model="category.value"
                    @change="
                      handleInput(
                        category.value,
                        category.category_name,
                        category.category_id
                      )
                    "
                    id="category"
                    class="block w-full rounded-lg border bg-gray-50 p-2.5 text-sm text-gray-900 shadow-sm dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:shadow-sm-light"
                    :class="{
                      'border-red-700': category.value.length === 0,
                      'border-yellow-400': category.value !== category.category_name,
                      'focus:border-red-700': category.value.length === 0,
                      'focus:ring-red-700': category.value.length === 0,
                      'focus:border-blue-500': category.value.length > 0,
                      'focus:ring-blue-500': category.value.length > 0,
                      'border-gray-700': category.value.length > 0,
                    }"
                    required
                  />
                </td>

                <!-- Child Category Count -->
                <td class="px-6 py-3 text-base font-semibold">
                  {{ category.category_child_count }}
                </td>

                <!-- Profile Count -->
                <td class="px-6 py-4 text-base font-semibold">
                  {{ category.category_profile_count }}
                </td>
                <td class="w-2 py-3"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/Dashboard/Ui/Pagination.vue';
import SearchDropdown from '@/components/Dashboard/Ui/SearchDropdown.vue';
import { mapActions } from 'vuex';
export default {
  components: {
    Pagination,
    SearchDropdown,
  },

  data() {
    return {
      data: '',
      total_pages: 0,
      current_page: 1,
      per_page: 20,
      total_count: 0,
      search_options: [
        {
          column: 'category_name',
          label: 'Kategorie',
        },
      ],
      current_search: {
        value: '',
        column: 'category_name',
        label: 'Kategorie',
      },
      operations: {
        insert: [],
        update: [],
        delete: [],
        invalid: [],
      },
    };
  },
  computed: {
    form: function () {
      var form = [];
      for (let i = 0; i < this.data.length; i++) {
        var obj = this.data[i];
        obj['value'] = obj.category_name;
        obj['error'] = false;
        form.push(obj);
      }
      return form;
    },
    operationsLength: function () {
      return this.operations.update.length;
    },
  },
  methods: {
    ...mapActions('dashboard', ['openReportDrawer']),
    ...mapActions('modal', ['showSubmitModal']),
    async getData(page = 1) {
      this.current_page = page;
      var response = await this.$API.post('/admin/x1/all', {
        page: this.current_page,
        per_page: this.per_page,
        search_column: this.current_search.column,
        search_value: this.current_search.value,
      });
      if (response.status === 200) {
        this.data = response.data.results;
        (this.total_pages = response.data.pages),
          (this.total_count = response.data.total);
      }
    },
    async handleSearch() {
      if (
        this.current_search.value.length > 3 ||
        this.current_search.value.length === 0
      ) {
        await this.getData();
        return;
      }
    },
    handleInput(newVal, oldVal, id) {
      // Insert or update Object in operations.update
      if (newVal !== oldVal && newVal.length > 0) {
        const index = this.operations.update.findIndex((element) => element.id === id);
        if (index !== -1) {
          const target = this.operations.update[index];
          target.value = newVal;
        } else this.operations.update.push({ id: id, value: newVal });
      }
    },
    async handleSave() {
      if (this.operationsLength === 0) {
        this.showSubmitModal({
          status: 'error',
          time: 2000,
          text: 'keine Änderungen!',
        });
        return;
      }
      var response = await this.$API.post('/admin/x1/edit', {
        update: this.operations.update,
      });
      if (response.status === 200) {
        this.getData();
        this.showSubmitModal({
          status: 'success',
          time: 2000,
          text: '',
        });
      }
    },
  },
  async mounted() {
    this.getData();
  },
  beforeUnmount() {},
};
</script>

<style></style>
