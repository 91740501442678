<template>
  <div>
    <!-- Basic Form -->
    <div class="form">
      <div @keyup.enter="send">
        <FormField
          @inputError="form.profile_firstname.error = $event"
          title="Vorname"
          v-model="this.form.profile_firstname.value"
          :validations="validations.profile_firstname"
          :value="form.profile_firstname.value"
          :error="form.profile_firstname.error"
        />

        <FormField
          @inputError="form.profile_lastname.error = $event"
          title="Nachname"
          v-model="this.form.profile_lastname.value"
          :validations="validations.profile_lastname"
          :value="form.profile_lastname.value"
          :error="form.profile_lastname.error"
        />

        <FormField
          @inputError="form.profile_nickname.error = $event"
          title="Benutzername"
          v-model="this.form.profile_nickname.value"
          :validations="validations.profile_nickname"
          :value="form.profile_nickname.value"
          :error="form.profile_nickname.error"
        />

        <FormField
          title="Bundesland"
          :value="form.profile_provinz_id.value"
          type="select"
        >
          <VSelect
            v-model="this.form.profile_provinz_id.value"
            :selected="form.profile_provinz_id.value"
            :selviewUrl="'/provinz/provinz-sel'"
          />
        </FormField>

        <FormField
          @inputError="form.profile_city.error = $event"
          title="Stadt"
          v-model="this.form.profile_city.value"
          :validations="validations.profile_city"
          :value="form.profile_city.value"
          :error="form.profile_city.error"
        />

        <FormField
          @inputError="form.profile_aboutme.error = $event"
          title="Über mich"
          type="textarea"
          v-model="this.form.profile_aboutme.value"
          :validations="validations.profile_aboutme"
          :value="form.profile_aboutme.value"
          :error="form.profile_aboutme.error"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FormField from '@/components/Form/FormField.vue';
import VSelect from '@/components/Form/VSelect.vue';

import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    FormField,
    VSelect,
  },
  props: ['formobj'],
  data() {
    return {
      unsaved: false,
      validations: {
        profile_firstname: {
          maxLength: {
            value: 20,
            msg: false,
          },
        },
        profile_lastname: {
          maxLength: {
            value: 20,
            msg: false,
          },
        },
        profile_nickname: {
          maxLength: {
            value: 20,
            msg: false,
          },
          minLength: {
            value: 3,
            msg: false,
          },
          required: true,
          username: true,
        },
        profile_city: {
          maxLength: {
            value: 20,
            msg: false,
          },
          type: {
            value: 'text',
            msg: 'Ungültige Stadt',
          },
        },
        profile_aboutme: {
          maxLength: {
            value: 200,
            msg: false,
          },
        },
      },
    };
  },
  computed: {
    form: function () {
      return this.formobj;
    },
    formHasErrors: function () {
      const formFields = [
        this.form.profile_firstname.error,
        this.form.profile_lastname.error,
        this.form.profile_nickname.error,
        this.form.profile_city.error,
        this.form.profile_aboutme.error,
      ];
      return formFields.some((element) => element === true);
    },
  },

  methods: {
    // Helper functions from VUEX
    ...mapActions('modal', ['showLoadingModal', 'hideLoadingModal']),
    ...mapGetters('profile', [
      'GET_PROFILEIMAGE',
      'ImagecropSrc',
      'GET_UPLOAD_PROCESSING',
    ]),
    ...mapActions('modal', ['showSubmitModal', 'hideOptionsModal']),
    ...mapActions('profile', [
      'deleteProfileImage',
      'setImagecropSource',
      'SET_IMAGECROPRES',
    ]),
    ...mapActions('search', ['search_action']),

    async submit() {
      // Check if form has validation errors
      this.emitter.emit('checkAll');
      if (this.formHasErrors === true) {
        this.showSubmitModal({
          status: 'error',
          time: 3000,
          text: 'Prüfe deine Eingabe!',
        });
        return;
      }

      try {
        await this.$API.post('/profile/basic', {
          profile_firstname: this.form.profile_firstname.value,
          profile_lastname: this.form.profile_lastname.value,
          profile_nickname: this.form.profile_nickname.value,
          profile_city: this.form.profile_city.value,
          profile_provinz_id: parseInt(this.form.profile_provinz_id.value),
          profile_aboutme: this.form.profile_aboutme.value,
        });
        this.showSubmitModal({
          status: 'success',
          time: 3000,
          text: '',
        });
      } catch (error) {
        if (error.response.data.message === 'NICKNAME_ALREADY_EXISTS') {
          this.form.profile_nickname.error = true;
          this.showSubmitModal({
            status: 'error',
            time: 5000,
            text: 'Benutzername wird bereits verwendet',
          });
        } else {
          this.showSubmitModal({
            status: 'error',
            time: 5000,
            text: 'Ein unerwarteter Fehler ist aufgetreten, versuche es später erneut',
          });
        }
      }
    },
  },
  mounted() {
    this.emitter.on('saveForm', (currentTab) => {
      if (currentTab === 'Basic') {
        this.submit();
      }
    });
  },
  beforeUnmount() {
    this.emitter.off('saveForm');
  },
};
</script>

<style scoped></style>
