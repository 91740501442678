import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/store';
import axios from './APIConfig';
import Toast from 'vue-toastification';
// import socket from "./socket";
import VueCookies from 'vue3-cookies';
import mitt from 'mitt';
import LoadScript from 'vue-plugin-load-script';
import 'vue-toastification/dist/index.css';

// import "flowbite";

const emitter = mitt();
const app = createApp(App);
// config
app.config.globalProperties.emitter = emitter;
app.config.globalProperties.$API = axios.devInstance;

// Need to assign again because store doesn´t use the same app context
store.$API = axios.devInstance;

// Extensions
app.use(LoadScript);
app.use(store);
app.use(router);
app.use(VueCookies);
app.use(Toast);
app.mount('#app');

export const appInstance = app;
// config
// app.config.globalProperties.$API = axios.devInstance
