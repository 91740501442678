<template>
  <!-- Modals -->
  <div class="px-4 pt-6">
    <div class="dark my-4 grid grid-cols-1 xl:gap-4 2xl:grid-cols-2">
      <div
        class="mb-4 rounded-lg bg-white p-4 shadow dark:bg-gray-800 sm:grid-cols-2 sm:p-6 xl:mb-0 xl:p-8 2xl:grid-cols-1"
      >
        <h3 class="mb-3 text-xl font-bold leading-none text-white">Anzahl Benutzer</h3>
        <div class="mt-6 rounded-lg bg-gray-50 p-4 dark:bg-gray-800 sm:p-12 lg:mt-2">
          <div class="grid grid-cols-1 gap-8 sm:gap-12 lg:grid-cols-2">
            <div class="flex flex-col items-start gap-4 sm:flex-row sm:gap-5">
              <div
                class="flex h-16 w-16 shrink-0 items-center justify-center rounded-full bg-gray-100 dark:bg-gray-700 lg:h-24 lg:w-24"
              >
                <svg
                  aria-hidden="true"
                  fill="none"
                  class="dark:text-primary-500 h-10 w-10 text-white lg:h-12 lg:w-12"
                  stroke="currentColor"
                  stroke-width="1.5"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>

              <div class="flex h-full w-full items-center">
                <h3 class="text-xl font-bold text-gray-900 dark:text-white sm:text-2xl">
                  {{ this.userTotal }}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="mb-4 rounded-lg bg-white p-4 shadow dark:bg-gray-800 sm:grid-cols-2 sm:p-6 xl:mb-0 xl:p-8 2xl:grid-cols-1"
      >
        <div class="mb-4 flex items-center justify-between">
          <h3 class="text-xl font-bold leading-none text-white">Neue Benutzer</h3>
        </div>
        <div class="flow-root">
          <ul class="divide-y divide-gray-200 dark:divide-gray-700">
            <li v-for="user in latestUser" :key="user.appuser_id" class="py-3 sm:py-4">
              <!-- Image -->
              <div class="flex items-center space-x-4">
                <div class="shrink-0">
                  <VLazyImage
                    v-if="user.imagesrc_small"
                    class="h-8 w-8 rounded-full"
                    :src="
                      user.imagesrc_small
                        ? 'https://devel.kremer-marketing.de' +
                          '/image/search/' +
                          user.imagesrc_small +
                          '?mode=tiny'
                        : 'https://uxwing.com/wp-content/themes/uxwing/download/peoples-avatars/no-profile-picture-icon.png'
                    "
                    alt=""
                  />
                  <img v-else class="h-8 w-8 rounded-full" src="@/assets/logo.png" />
                </div>
                <!-- Text -->
                <div class="min-w-0 flex-1">
                  <p class="truncate text-sm font-medium text-white">
                    {{ user.profile_nickname }}
                  </p>
                  <div class="mb-4 flex items-center justify-between">
                    <p class="truncate text-sm text-gray-400">{{ user.appuser_email }}</p>
                    <p class="truncate text-sm text-green-600">
                      {{ user.payment_modtime }}
                    </p>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div
        class="mb-4 rounded-lg bg-white p-4 shadow dark:bg-gray-800 sm:grid-cols-2 sm:p-6 xl:mb-0 xl:p-8 2xl:grid-cols-1"
      >
        <h3 class="mb-3 text-xl font-bold leading-none text-white">Woche</h3>
        <LineChart />
      </div>
      <div
        class="mb-4 rounded-lg bg-white p-4 shadow dark:bg-gray-800 sm:grid-cols-2 sm:p-6 xl:mb-0 xl:p-8 2xl:grid-cols-1"
      >
        <h3 class="mb-3 text-xl font-bold leading-none text-white">Monat</h3>
        <BarChart />
      </div>
    </div>
    <!-- Content here -->
    <!-- <div class="mt-4 grid w-full grid-cols-1 gap-4 xl:grid-cols-2">
      <div class="my-4 grid grid-cols-1 xl:gap-4 2xl:grid-cols-3">Test</div>

      <div
        class="rounded-lg border border-gray-700 bg-gray-800 p-4 shadow-sm sm:p-6"
      ></div>
      <div
        class="rounded-lg border border-gray-700 bg-gray-800 p-4 shadow-sm sm:p-6"
      ></div>
    </div> -->
  </div>
</template>
<script>
import LineChart from '@/components/Dashboard/Ui/LineChart.vue';
import BarChart from '@/components/Dashboard/Ui/BarChart.vue';
import VLazyImage from 'v-lazy-image';
export default {
  components: {
    LineChart,
    BarChart,
    VLazyImage,
  },
  data() {
    return {
      userTotal: 0,
      latestUser: [],
      timer: '',
    };
  },
  methods: {
    async fetchData() {
      var response_totalUser = await this.$API.get('/admin/dashboard/data/user/total');
      if (response_totalUser.status === 200) {
        this.userTotal = response_totalUser.data[0]['count'];
      }

      var response_latestUser = await this.$API.get('/admin/dashboard/data/user/latest');
      if (response_latestUser.status === 200) {
        this.latestUser = response_latestUser.data;
      }
      //   const response = await fetch('https://example.com/api/new-users/week');
    },
  },
  mounted() {
    this.fetchData();
    this.timer = setInterval(() => {
      this.fetchData();
    }, 10000);
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
};
</script>

<style></style>
