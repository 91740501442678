<template>
  <div class="external-link">
    <div class="container--content">
      <h2>Sie verlassen Zoom-Near und werden weitergeleitet. Sind sie sicher?</h2>
      <a :href="this.link">{{ this.link }}</a>
      <div class="btnfield">
        <button @click="back" class="denie">Zurück</button>
        <button @click="redirect" class="denie">Weiter</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'ExternalLink',
  components: {},
  data() {
    return {
      link: '',
      timeout: '',
      from: '',
    };
  },

  mounted() {
    if (this.$route.params.redirectTarget) {
      this.link = this.$route.params.redirectTarget;
      this.from = this.$route.params.fromTarget;
    }
  },
  computed: {},
  methods: {
    // Mapping
    ...mapActions('profile', ['ProfileLoadAction', 'getProfileData', 'getProfileImage']),
    ...mapActions('auth', ['loginRefresh', 'updateLoadingDone', 'SocketLogin']),
    ...mapGetters('search', ['SEARCHRESPONSE']),
    ...mapGetters('auth', ['PaymentStatus']),
    ...mapActions('modal', [
      'showSubmitModal',
      'hideRegisterModal',
      'showLoginModal',
      'showOptionsModal',
      'hidePasswordChange',
      'showPasswordChange',
      'showPasswordForgot',
      'hidePasswordForgot',
    ]),
    ...mapActions('search', ['search_action']),
    redirect() {
      window.open(this.link);
    },
    back() {
      //   window.location.replace(process.env.VUE_APP_BASEURL_FRONTEND);
      this.$router.push({
        name: 'Home',
        params: {
          Settings: true,
        },
      });
    },
    initLoading: async function () {
      // Start Search without Params
      this.search_action();

      // Load Personal Profile Data
      this.updateLoadingDone(false);

      try {
        await this.getProfileData();
        this.updateLoadingDone(true);
      } catch (error) {
        this.updateLoadingDone(true);
      }

      // Try Socket Connection
      this.SocketLogin();
    },
  },
};
</script>

<style scoped>
.logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 26vw;
  height: clamp(10rem, 10vw, 15rem);
}

a {
  font-size: clamp(5px, 1.4rem, 50px);
  font-weight: 600;
  text-decoration: none;
  color: #ed1c24;
  text-align: center;
  word-wrap: break-word;
}

h2 {
  font-size: clamp(12px, 1.8rem, 50px);
  font-weight: 600;
  color: var(--font-color);
  text-align: center;
}

.btnfield {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 1%;
}

.container--content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5rem;
  height: 100%;
  gap: 5vw;
}
.denie {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12rem;
  height: 2.5rem;
  background-color: var(--settings-input);
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: clamp(12px, 1rem, 50px);
  color: var(--font-color);
}
.denie:hover {
  background-color: #ed1c24;
}

@media only screen and (max-width: 500px) {
  .btnfield {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }
}
</style>
