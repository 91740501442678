import { useToast } from 'vue-toastification';
export default {
  namespaced: true,
  state() {
    return {
      login: false,
      register: false,
      report: {
        active: false,
        profile_nickname: '',
        profile_id: '',
      },
      submitConfirm: {
        active: false,
        text: 'Confirm',
        resolvePromise: undefined,
        rejectPromise: undefined,
      },
      options: {
        active: false,
        tabActive: 'Profile',
      },
      mobile: {
        search: false,
        navigation: false,
      },
      loading: false,
      chatInbox: false,
      chatMessages: false,
      passwordForgot: {
        status: false,
        changePassword: false,
      },
      payment: false,
      impressum: false,
      datenschutz: false,
      agb: false,
      affliate: false,
    };
  },
  mutations: {
    SET_LOGIN(state, status) {
      state.login = status;
    },
    SET_REGISTER(state, status) {
      state.register = status;
    },

    // Confirm Message Modals
    SET_SUBMITCONFIRMSTATUS(state, status) {
      state.submitConfirm.active = status;
    },
    SET_SUBMITCONFIRMTEXT(state, text) {
      state.submitConfirm.text = text;
    },
    SET_CONFIRMRESOLVE(state, resolvePromise) {
      state.submitConfirm.resolvePromise = resolvePromise;
    },
    SET_CONFIRMREJECT(state, rejectPromise) {
      state.submitConfirm.rejectPromise = rejectPromise;
    },
    SET_SUBMITCONFIRMCONDITION(state, status) {
      var promise = '';
      if (status) {
        promise = state.submitConfirm.resolvePromise;
        promise(status);
      } else {
        promise = state.submitConfirm.rejectPromise;
        promise(status);
      }
    },

    // Profile Settings
    SET_OPTIONS(state, status) {
      state.options.active = status.active;
      state.options.tabActive = status.tabActive;
    },

    // Profile Settings -> Set Image
    // Chat
    SET_CHATINBOX(state, status) {
      state.chatInbox = status;
    },
    SET_CHATMESSAGES(state, status) {
      state.chatMessages = status;
    },

    SET_LOADING(state, status) {
      state.loading = status;
    },

    SET_PASSWORDFORGOT(state, status) {
      state.passwordForgot.status = status;
    },
    SET_CHANGEPWSTATUS(state, status) {
      state.passwordForgot.changePassword = status;
    },

    // Mobile
    SET_MOBILESEARCH(state, status) {
      state.mobile.search = status;
    },
    SET_MOBILENAVIGATION(state, status) {
      state.mobile.navigation = status;
    },

    //Payment
    SET_PAYMENT(state, status) {
      state.payment = status;
    },

    //Report
    SET_REPORT(state, status) {
      state.report.active = status;
    },
    SET_REPORT_PROFILE_ID(state, profile_id) {
      state.report.profile_id = profile_id;
    },
    SET_REPORT_PROFILE_NICKNAME(state, profile_nickname) {
      state.report.profile_nickname = profile_nickname;
    },
    //Impressum
    SET_IMPRESSUM(state, status) {
      state.impressum = status;
    },
    SET_DATENSCHUTZ(state, status) {
      state.datenschutz = status;
    },
    SET_AGB(state, status) {
      state.agb = status;
    },
    SET_AFFLIATE(state, status) {
      state.affliate = status;
    },
  },
  getters: {
    LoginModalStatus: (state) => {
      return state.login;
    },
    RegisterModalStatus: (state) => {
      return state.register;
    },
    // Confirm Message Modal
    SubmitConfirmModalStatus: (state) => {
      return state.submitConfirm.active;
    },
    SubmitConfirm: (state) => {
      return state.submitConfirm;
    },

    // Profile Settings
    OptionsModalStatus: (state) => {
      return state.options.active;
    },
    OptionsActiveTab: (state) => {
      return state.options.tabActive;
    },
    LoadingStatus: (state) => {
      return state.loading;
    },

    //Chat
    ChatInboxStatus: (state) => {
      return state.chatInbox;
    },
    ChatMessagesStatus: (state) => {
      return state.chatMessages;
    },

    PasswordForgotStatus: (state) => {
      return state.passwordForgot;
    },

    PaymentModalStatus: (state) => {
      return state.payment;
    },
    ReportModalStatus: (state) => {
      return state.report.active;
    },
    ReportModalData: (state) => {
      return {
        profile_id: state.report.profile_id,
        profile_nickname: state.report.profile_nickname,
      };
    },

    ImpressumModalStatus: (state) => {
      return state.impressum;
    },
    DatenschutzModalStatus: (state) => {
      return state.datenschutz;
    },
    AgbModalStatus: (state) => {
      return state.agb;
    },
    AffliateModalStatus: (state) => {
      return state.affliate;
    },

    //Mobile
    isMobile: () => {
      const screenWidth = window.innerWidth;
      return screenWidth < 800;
    },

    MobileSearchStatus: (state) => {
      return state.mobile.search;
    },
    MobileNavStatus: (state) => {
      return state.mobile.navigation;
    },
    MobileStatus: (state) => {
      var modals = [
        state.mobile.search,
        state.mobile.navigation,
        state.chatInbox,
        state.chatMessages,
      ];
      // Check if one modal is still open
      const open = (element) => element === true;
      return modals.some(open);
    },

    ModalStatus: (state) => {
      var status = [
        state.loading,
        state.login,
        state.register,
        state.options.active,
        state.submitConfirm.active,
        state.chatInbox,
        state.chatMessages,
        state.passwordForgot.status,
      ];
      var closed = (currentValue) => currentValue === false;
      return status.every(closed);
    },
  },
  actions: {
    hideAllModals(context) {
      context.dispatch('hideLoginModal');
      context.dispatch('hideRegisterModal');
      context.dispatch('showSubmitConfirmModal', false);
      context.dispatch('hideOptionsModal');
      context.dispatch('hideChatInbox');
      context.dispatch('hideChatMessages');
      context.dispatch('hidePaymentModal');
      context.dispatch('hideMobileSearch');
      context.dispatch('hideMobileNavigation');
      context.dispatch('hideImpressumModal');
      context.dispatch('hideDatenschutzModal');
      context.dispatch('hideAgbModal');
      context.dispatch('hideAffliateModal');
      context.dispatch('search/profileClickHide', null, { root: true });
      document.querySelector('body').classList.remove('overflow-hidden');
    },
    // Login
    showLoginModal(context) {
      context.commit('SET_LOGIN', true);
      document.querySelector('body').classList.add('overflow-hidden');
    },
    hideLoginModal(context) {
      context.commit('SET_LOGIN', false);
      document.querySelector('body').classList.remove('overflow-hidden');
    },

    // Register
    showRegisterModal(context) {
      context.commit('SET_REGISTER', true);
      document.querySelector('body').classList.add('overflow-hidden');
    },
    hideRegisterModal(context) {
      context.commit('SET_REGISTER', false);
      document.querySelector('body').classList.remove('overflow-hidden');
    },

    // Submit Error Message
    showSubmitModal(context, param) {
      // input arguments:
      // {
      // status: success or error -- Green Tig/Red Cross
      // text: "" -- Message
      // timer: 3000 -- Timer to hide the modal automatically
      // }
      // Set defaults
      const toast = useToast();
      let msg = 'Gespeichert!';
      var timer = 3000;

      if (typeof param.text !== 'undefined') {
        if (param.text !== '') msg = param.text;
      }
      if (typeof param.time !== 'undefined') {
        timer = param.time;
      }
      const options = {
        position: 'top-right',
        timeout: timer,
        icon: true,
        pauseOnFocusLoss: false,
        pauseOnHover: false,
        draggable: false,
        hideProgressBar: true,
      };

      if ('callback' in param) options.onClose = param.callback;
      if ('icon' in param) options.icon = param.icon;
      if (context.getters.isMobile) options.position = 'bottom-center';
      if (param.status === 'success') toast.success(msg, options);
      if (param.status === 'error') toast.error(msg, options);
      if (param.status === 'info') toast.info(msg, options);
    },
    //Submit Confirm
    initSubmitConfirmModal(context, param) {
      context.commit('SET_SUBMITCONFIRMSTATUS', param.active);
      context.commit('SET_SUBMITCONFIRMTEXT', param.text);

      return new Promise((resolve, reject) => {
        context.commit('SET_CONFIRMRESOLVE', resolve);
        context.commit('SET_CONFIRMREJECT', reject);
      });
    },
    showSubmitConfirmModal(context, status) {
      context.commit('SET_SUBMITCONFIRMSTATUS', status);
    },
    setSubmitConfirm(context, status) {
      context.commit('SET_SUBMITCONFIRMCONDITION', status);
    },

    //Options
    showOptionsModal(context) {
      context.commit('SET_OPTIONS', {
        active: true,
        tabActive: 'Profile',
      });
      context.dispatch('profile/getProfileImage', null, { root: true });

      document.querySelector('body').classList.add('overflow-hidden');
    },
    explicitOptionsModal(context, tabname) {
      context.commit('SET_OPTIONS', {
        active: true,
        tabActive: tabname,
      });

      context.dispatch('profile/getProfileImage', null, { root: true });
      document.querySelector('body').classList.add('overflow-hidden');
    },
    hideOptionsModal(context) {
      //   context.dispatch("search/search_action", null, { root: true });
      context.commit('SET_OPTIONS', {
        active: false,
        tabActive: 'Profile',
      });
      document.querySelector('body').classList.remove('overflow-hidden');
    },
    //CHAT
    showChatInbox(context) {
      context.commit('SET_CHATINBOX', true);
      context.dispatch(
        'search/profileHoverData',
        {
          active: false,
          name: '',
        },
        { root: true }
      );

      var screenWidth = window.innerWidth;
      if (screenWidth < 1330) {
        document.querySelector('body').classList.add('overflow-hidden');
      }
    },
    hideChatInbox(context) {
      context.commit('SET_CHATINBOX', false);
      var screenWidth = window.innerWidth;
      if (screenWidth < 1330) {
        document.querySelector('body').classList.remove('overflow-hidden');
      }
    },
    toggleChatInbox(context) {
      var screenWidth = window.innerWidth;
      var ShowInbox = context.getters.ChatInboxStatus;
      var showMessages = context.getters.ChatMessagesStatus;

      if (screenWidth < 1330) {
        if (showMessages) {
          context.dispatch('hideChatInbox');
          context.dispatch('hideChatMessages');
          document.querySelector('body').classList.remove('overflow-hidden');
          return;
        }
        context.commit('SET_CHATINBOX', !ShowInbox);
        context.dispatch(
          'search/profileHoverData',
          {
            active: false,
            name: '',
          },
          { root: true }
        );
        document.querySelector('body').classList.add('overflow-hidden');
        return;
      }
      context.dispatch('chat/fetchInboxes', null, { root: true });
      ShowInbox = context.getters.ChatInboxStatus;
      context.commit('SET_CHATINBOX', !ShowInbox);
      if (ShowInbox) context.dispatch('hideChatMessages');
    },
    showChatMessages(context) {
      var screenWidth = window.innerWidth;
      if (screenWidth < 1330) {
        context.dispatch('hideChatInbox');
        document.querySelector('body').classList.remove('overflow-hidden');
      }
      context.commit('SET_CHATMESSAGES', true);
    },
    hideChatMessages(context) {
      var screenWidth = window.innerWidth;
      if (screenWidth < 1330) {
        document.querySelector('body').classList.remove('overflow-hidden');
      }
      context.commit('SET_CHATMESSAGES', false);
    },

    MobileBackChat(context) {
      context.dispatch('hideChatMessages');
      context.dispatch('showChatInbox');
    },

    //Loading
    showLoadingModal(context) {
      context.commit('SET_LOADING', true);
      document.querySelector('body').classList.add('overflow-hidden');
    },
    hideLoadingModal(context) {
      context.commit('SET_LOADING', false);
      document.querySelector('body').classList.remove('overflow-hidden');
    },

    //Payment
    showPaymentModal(context) {
      context.commit('SET_PAYMENT', true);
      document.querySelector('body').classList.add('overflow-hidden');
    },
    hidePaymentModal(context) {
      context.commit('SET_PAYMENT', false);
      document.querySelector('body').classList.remove('overflow-hidden');
    },

    // Report
    showReportModal(context, profile) {
      context.commit('SET_REPORT', true);
      context.commit('SET_REPORT_PROFILE_NICKNAME', profile.profile_nickname);
      context.commit('SET_REPORT_PROFILE_ID', profile.profile_id);
      document.querySelector('body').classList.add('overflow-hidden');
    },
    hideReportModal(context) {
      context.commit('SET_REPORT', false);
      context.commit('SET_REPORT_PROFILE_NICKNAME', '');
      context.commit('SET_REPORT_PROFILE_ID', '');
      document.querySelector('body').classList.remove('overflow-hidden');
    },

    // Impressum
    showImpressumModal(context) {
      context.commit('SET_IMPRESSUM', true);
      document.querySelector('body').classList.add('overflow-hidden');
    },
    hideImpressumModal(context) {
      context.commit('SET_IMPRESSUM', false);
      document.querySelector('body').classList.remove('overflow-hidden');
    },

    // Datenschutz
    showDatenschutzModal(context) {
      context.commit('SET_DATENSCHUTZ', true);
      document.querySelector('body').classList.add('overflow-hidden');
    },
    hideDatenschutzModal(context) {
      context.commit('SET_DATENSCHUTZ', false);
      document.querySelector('body').classList.remove('overflow-hidden');
    },

    // AGB
    showAgbModal(context) {
      context.commit('SET_AGB', true);
      document.querySelector('body').classList.add('overflow-hidden');
    },
    hideAgbModal(context) {
      context.commit('SET_AGB', false);
      document.querySelector('body').classList.remove('overflow-hidden');
    },

    // Affliate
    showAffliateModal(context) {
      context.commit('SET_AFFLIATE', true);
      document.querySelector('body').classList.add('overflow-hidden');
    },
    hideAffliateModal(context) {
      context.commit('SET_AFFLIATE', false);
      document.querySelector('body').classList.remove('overflow-hidden');
    },

    //Mobile
    showMobileSearch(context) {
      context.commit('SET_MOBILESEARCH', true);
      document.querySelector('body').classList.add('overflow-hidden');
    },
    hideMobileSearch(context) {
      context.commit('SET_MOBILESEARCH', false);
      document.querySelector('body').classList.remove('overflow-hidden');
    },
    toggleMobileSearch(context) {
      var status = context.getters.MobileSearchStatus;
      if (status) {
        context.dispatch('hideMobileSearch');
        document.querySelector('body').classList.remove('overflow-hidden');
        return;
      }

      context.dispatch('showMobileSearch');
      document.querySelector('body').classList.add('overflow-hidden');
    },

    showMobileNavigation(context) {
      context.commit('SET_MOBILENAVIGATION', true);
      document.querySelector('body').classList.add('overflow-hidden');
    },
    hideMobileNavigation(context) {
      context.commit('SET_MOBILENAVIGATION', false);
      document.querySelector('body').classList.remove('overflow-hidden');
    },
    toggleMobileNavigation(context) {
      var status = context.getters.MobileNavStatus;
      if (status) {
        context.dispatch('hideMobileNavigation');
        document.querySelector('body').classList.remove('overflow-hidden');
        return;
      }
      context.dispatch('showMobileNavigation');
      document.querySelector('body').classList.add('overflow-hidden');
    },
    showPasswordForgot(context) {
      context.commit('SET_PASSWORDFORGOT', true);
      document.querySelector('body').classList.add('overflow-hidden');
    },
    showPasswordChange(context) {
      context.commit('SET_CHANGEPWSTATUS', true);
      document.querySelector('body').classList.remove('overflow-hidden');
    },
    hidePasswordChange(context) {
      context.commit('SET_CHANGEPWSTATUS', false);
      document.querySelector('body').classList.remove('overflow-hidden');
    },
    hidePasswordForgot(context) {
      context.commit('SET_PASSWORDFORGOT', false);
      document.querySelector('body').classList.remove('overflow-hidden');
    },
  },
};
