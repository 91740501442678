<template>
  <!-- Cookie Banner -->

  <CookieBanner />

  <!-- Wrapper for all Modals -->
  <div class="modal-container">
    <PasswordForgot title="Passwort vergessen" :close="hidePasswordForgot" />

    <!-- Chat -->
    <Chat />
    <ChatMessages />

    <!-- loading -->
    <LoadingModal :view="LoadingStatus" />

    <!-- Settings -->
    <ProfileSettingsModal :withHeadline="false" />

    <!-- Login -->
    <LoginModal />
    <RegisterModal />
    <PaymentModal />
    <ImpressumModal />
    <AgbModal />
    <DatenschutzModal />
    <AffliateModal />
    <ReportModal />
    <!-- <LoginContainer title="Einloggen" :view="LoginModalStatus">
      <Login />
    </LoginContainer> -->

    <!-- Registration -->
    <!-- <LoginContainer title="Registrieren" :view="RegisterModalStatus">
      <Register />
    </LoginContainer> -->

    <!-- Toast Notification -->
    <SubmitConfirm />
  </div>
</template>

<script>
//Components
import ProfileSettingsModal from '@/components/modals/ProfileSettingsModal.vue';
import CookieBanner from '@/components/modals/CookieBanner.vue';
import ImpressumModal from '@/components/modals/ImpressumModal.vue';
import AgbModal from '@/components/modals/AgbModal.vue';
import DatenschutzModal from '@/components/modals/DatenschutzModal.vue';
import AffliateModal from '@/components/modals/AffliateModal.vue';
import LoginModal from '@/components/modals/Login.vue';
import RegisterModal from '@/components/modals/Register.vue';
import LoadingModal from '@/components/modals/LoadingModal.vue';
import SubmitConfirm from '@/components/modals/SubmitConfirm.vue';
import Chat from '@/components/Chat/Chat.vue';
import ChatMessages from '@/components/Chat/ChatMessages.vue';
import PasswordForgot from '@/components/modals/PasswordForgot.vue';
import PaymentModal from '@/components/modals/PaymentModal.vue';
import ReportModal from '@/components/modals/Report.vue';

//Helper Functions
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {
    ImpressumModal,
    AgbModal,
    DatenschutzModal,
    AffliateModal,
    LoginModal,
    RegisterModal,
    LoadingModal,
    ProfileSettingsModal,
    Chat,
    ChatMessages,
    SubmitConfirm,
    PasswordForgot,
    PaymentModal,
    ReportModal,
    CookieBanner,
  },
  computed: {
    ...mapGetters('modal', [
      'LoginModalStatus',
      'RegisterModalStatus',
      'LoadingStatus',
      'PasswordForgot',
    ]),
  },
  methods: {
    ...mapActions('modal', ['hidePasswordForgot']),
  },
};
</script>

<style scoped></style>
