<template>
  <div>
    <div class="form">
      <div @keyup.enter="send">
        <FormField
          @inputError="form.profile_instagram.error = $event"
          title="Instagram"
          :titletext="links.instagram + form.profile_instagram.value"
          :icon="'fab fa-instagram'"
          :validations="validations"
          v-model="form.profile_instagram.value"
          :value="form.profile_instagram.value"
          :error="form.profile_instagram.error"
        />

        <FormField
          @inputError="form.profile_facebook.error = $event"
          title="Facebook"
          :titletext="links.facebook + form.profile_facebook.value"
          :icon="'fab fa-facebook-f'"
          :validations="validations"
          v-model="form.profile_facebook.value"
          :value="form.profile_facebook.value"
          :error="form.profile_facebook.error"
        />

        <FormField
          @inputError="form.profile_twitch.error = $event"
          title="Twitch"
          :titletext="links.twitch + form.profile_twitch.value"
          :icon="'fab fa-twitch'"
          :validations="validations"
          v-model="form.profile_twitch.value"
          :value="form.profile_twitch.value"
          :error="form.profile_twitch.error"
        />

        <FormField
          @inputError="form.profile_twitter.error = $event"
          title="Twitter"
          :titletext="links.twitter + form.profile_twitter.value"
          :icon="'fab fa-twitter'"
          :validations="validations"
          v-model="form.profile_twitter.value"
          :value="form.profile_twitter.value"
          :error="form.profile_twitter.error"
        />

        <FormField
          @inputError="form.profile_youtube.error = $event"
          title="YouTube"
          :titletext="links.youtube + form.profile_youtube.value"
          :icon="'fab fa-youtube'"
          :validations="validations"
          v-model="form.profile_youtube.value"
          :value="form.profile_youtube.value"
          :error="form.profile_youtube.error"
        />

        <FormField
          @inputError="form.profile_tiktok.error = $event"
          title="TikTok"
          :titletext="links.tiktok + form.profile_tiktok.value"
          :icon="'fab fa-tiktok'"
          :validations="validations"
          v-model="form.profile_tiktok.value"
          :value="form.profile_tiktok.value"
          :error="form.profile_tiktok.error"
        />

        <FormField
          @inputError="form.profile_linkedin.error = $event"
          title="LinkedIn"
          :titletext="links.linkedin + form.profile_linkedin.value"
          :icon="'fab fa-linkedin-in'"
          :validations="validations"
          v-model="form.profile_linkedin.value"
          :value="form.profile_linkedin.value"
          :error="form.profile_linkedin.error"
        />

        <FormField
          @inputError="form.profile_xing.error = $event"
          title="Xing"
          :titletext="links.xing + form.profile_xing.value"
          :icon="'fab fa-xing'"
          :validations="validations"
          v-model="form.profile_xing.value"
          :value="form.profile_xing.value"
          :error="form.profile_xing.error"
        />

        <FormField
          @inputError="form.profile_pinterest.error = $event"
          title="Pinterest"
          :titletext="links.pinterest + form.profile_pinterest.value"
          :icon="'fab fa-pinterest'"
          :validations="validations"
          v-model="form.profile_pinterest.value"
          :value="form.profile_pinterest.value"
          :error="form.profile_pinterest.error"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FormField from "@/components/Form/FormField.vue";
import { mapGetters, mapActions } from "vuex";
import links from "@/data/socialLinks.js";
export default {
  components: {
    FormField,
  },

  data() {
    return {
      links: links,
      validations: {
        maxLength: {
          value: 40,
          msg: "Maximale Länge erreicht!",
        },
      },
    };
  },
  props: ["formobj"],
  computed: {
    form: function () {
      return this.formobj;
    },
    formHasErrors: function () {
      const formFields = [
        this.form.profile_instagram.error,
        this.form.profile_facebook.error,
        this.form.profile_twitch.error,
        this.form.profile_twitter.error,
        this.form.profile_youtube.error,
        this.form.profile_tiktok.error,
        this.form.profile_linkedin.error,
        this.form.profile_xing.error,
        this.form.profile_pinterest.error,
        this.form.profile_website.error,
      ];
      return formFields.some((element) => element === true);
    },
  },
  methods: {
    // Helper Functions
    ...mapGetters("profile", ["GET_PROFILESOCIALMEDIA"]),
    ...mapActions("modal", ["showSubmitModal"]),
    ...mapActions("search", ["search_action"]),

    async submit() {
      // Check if form has validation errors
      this.emitter.emit("checkAll");
      if (this.formHasErrors === true) {
        this.showSubmitModal({
          status: "error",
          time: 3000,
          text: "Prüfe deine Eingabe!",
        });
        return;
      }
      try {
        await this.$API.post("/profile/socialmedia", {
          profile_instagram: this.form.profile_instagram.value,
          profile_facebook: this.form.profile_facebook.value,
          profile_twitch: this.form.profile_twitch.value,
          profile_twitter: this.form.profile_twitter.value,
          profile_youtube: this.form.profile_youtube.value,
          profile_tiktok: this.form.profile_tiktok.value,
          profile_linkedin: this.form.profile_linkedin.value,
          profile_xing: this.form.profile_xing.value,
          profile_pinterest: this.form.profile_pinterest.value,
          profile_additionalone: this.form.profile_website.value,
        });
        this.showSubmitModal({
          status: "success",
          time: 3000,
          text: "",
        });
      } catch (error) {
        this.showSubmitModal({
          status: "error",
          time: 3000,
          text: "Der Dienst ist aktuell nicht verfügbar. Bitte versuche es später nochmal",
        });
        return Promise.reject(error);
      }
    },
  },
  mounted() {
    this.emitter.on("saveForm", (currentTab) => {
      if (currentTab === "Socialmedia") {
        this.submit();
      }
    });
  },
  beforeUnmount() {
    this.emitter.off("saveForm");
  },
};
</script>
