<template>
  <Transition>
    <div v-if="GET_HOVERSTATE_ACTIVE === true" class="wrapper">
      <p>{{ this.hover.name }}</p>
    </div>
  </Transition>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("search", ["GET_HOVERSTATE_ACTIVE"]),
    ...mapGetters({
      hover: "search/GET_HOVERSTATE",
    }),
  },
};
</script>

<style scoped>
/* dialog transition */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.1s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.wrapper {
  display: flex;
  position: fixed;
  bottom: 0;
  justify-content: center;
  align-items: center;
  font-size: clamp(12px, 10vw, 200px);
  font-weight: 900;
  color: var(--font-color);
  width: 99vw;
  height: 100vh;
  overflow: hidden;
  pointer-events: none;
}

@media only screen and (max-width: 1320px) {
  .wrapper {
    margin-bottom: 30%;
    z-index: 9999;
  }
}

@media only screen and (max-width: 1200px) {
  .wrapper {
    margin-bottom: 30%;
  }
}

@media only screen and (max-width: 600px) {
  .wrapper {
    margin-bottom: 50%;
  }
}
</style>
